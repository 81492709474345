import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSnapshot } from 'valtio'

import { authStore, defaultAuthStore } from 'stores/auth-store'
import reset from 'stores/helpers/reset'
import { clear as clearSession } from 'utils/session-storage'
import { clear as clearLocal } from 'utils/storage'

export default function useLogout() {
  const navigate = useNavigate()

  const {
    computed: { isAuthenticated },
  } = useSnapshot(authStore)

  const navigateToLogin = () => {
    navigate('/account/login', { replace: true })
  }

  useEffect(() => {
    if (isAuthenticated) {
      reset(authStore.state, defaultAuthStore)
      clearSession()
      clearLocal()
      return
    }

    navigateToLogin()
  }, [])

  return { isAuthenticated }
}
