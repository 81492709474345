import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { logoLight } from 'assets/images'
import { CONTACT_SUPPORT_URL } from 'config/env'
import { useAccountLayout } from 'hooks'

const ServerError = () => {
  useAccountLayout()
  const { t } = useTranslation()

  return (
    <div className='account-pages pt-2 pt-sm-5 pb-4 pb-sm-5'>
      <div className='container'>
        <Row className='justify-content-center'>
          <Col md={8} lg={6} xl={5} xxl={4}>
            <Card>
              <Card.Header className='text-center bg-primary'>
                <Link to='/'>
                  <img
                    src='/logo-light.svg'
                    onError={(event) => {
                      event.currentTarget.src = logoLight
                    }}
                    alt='logo'
                    height='24'
                  />
                </Link>
              </Card.Header>

              <Card.Body className='p-4'>
                <div className='text-center'>
                  <img src='/startman.svg' height='120' alt='' />

                  <h1 className='text-error mt-3'>500</h1>
                  <h4 className='text-uppercase text-danger mt-3'>{t('Internal Server Error')}</h4>
                  <p className='text-muted mt-3'>
                    {t('Why not try refreshing your page? or you can contact')}
                    <a
                      href={CONTACT_SUPPORT_URL}
                      target='_blank'
                      className='text-muted'
                      rel='noreferrer'
                    >
                      <b>{t('Support')}</b>
                    </a>
                  </p>

                  <Link className='btn btn-primary mt-3 button-action rounded-4' to='/'>
                    <i className='icon icon-arrow-l me-1 icon-20px' />
                    {t('Return Home')}
                  </Link>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default ServerError
