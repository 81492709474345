import { ApiCore } from './api-core'

export class AsyncSelectApi extends ApiCore {
  protected disablePathPrefix = true

  async getOptions(path: string, payload = {}) {
    return await this.get({
      path: `/autocomplete/${path}`,
      payload,
    })
  }
}

export const asyncSelectApi = new AsyncSelectApi()
