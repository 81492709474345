import { ApiCore } from './api-core'
import { Payload } from './api-core.types'

export class RouteApi extends ApiCore {
  protected multipart = false
  protected pathPrefix = 'admin'

  async addRoute(payload: Payload = {}) {
    return await this.post({
      path: 'routes',
      payload,
    })
  }

  async editRoute(id: string | number, payload: Payload = {}) {
    return await this.put({
      path: `routes/${id}`,
      payload,
    })
  }

  async submit(payload: Payload = {}, id?: string | number) {
    const { ok } = id ? await this.editRoute(id, payload) : await this.addRoute(payload)

    return ok
  }

  async deleteRoute(id: string | number) {
    return await this.delete({
      path: `routes/${id}`,
    })
  }
}

export const routeApi = new RouteApi()
