import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { ControlledPasswordInput, FormProvider, SubmitButton } from 'components'

import { useResetPassword } from './reset-password.hook'

const ResetPassword: FunctionComponent = () => {
  const { t } = useTranslation('auth')
  const { methods, onSubmit } = useResetPassword()

  return (
    <>
      <div className='m-auto text-center'>
        <h4 className='text-dark-50 mt-0 fw-bold'>{t('Reset Password')}</h4>
        <p className='text-muted mb-4 text-balance'>
          {t('Create new password for your Factum admin account by filling out the form below.')}
        </p>
      </div>

      <FormProvider methods={methods} onSubmit={onSubmit} name='user'>
        <ControlledPasswordInput
          label={t('New Password')}
          name='newPassword'
          containerClass='mb-3'
          placeholder={t('Your New Password')}
        />

        <ControlledPasswordInput
          label={t('Confirm New Password')}
          name='confirmPassword'
          containerClass='mb-3'
          placeholder={t('Confirm New Password')}
        />

        <div className='m-auto text-center'>
          <SubmitButton loading={methods.formState.isSubmitting}>
            {t('Change Password')}
          </SubmitButton>
        </div>
      </FormProvider>
    </>
  )
}

export default ResetPassword
