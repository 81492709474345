import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { logoLight } from 'assets/images'
import { useAccountLayout } from 'hooks'

type PageNotFoundProps = {
  isAppSubdomain?: boolean
}

const PageNotFound = (props: PageNotFoundProps) => {
  useAccountLayout()
  const { t } = useTranslation('auth')

  const { isAppSubdomain = false } = props

  return (
    <div className='account-pages pt-2 pt-sm-5 pb-4 pb-sm-5'>
      <div className='container'>
        <Row className='justify-content-center'>
          <Col md={8} lg={6} xl={5} xxl={4}>
            <Card>
              <Card.Header className='text-center bg-primary'>
                <Link to='/'>
                  {isAppSubdomain ? (
                    <div className='logo-factum-background' />
                  ) : (
                    <img
                      src='/logo-light.svg'
                      onError={(event) => {
                        event.currentTarget.src = logoLight
                      }}
                      alt='logo'
                      height='24'
                    />
                  )}
                </Link>
              </Card.Header>

              <Card.Body className='p-4'>
                <div className='text-center'>
                  <h1 className='text-error'>
                    4<i className='mdi mdi-emoticon-sad'></i>4
                  </h1>
                  <h4 className='text-uppercase text-danger mt-3'>{t('Page Not Found')}</h4>
                  <p className='text-muted mt-3'>{t('Page Not Found Message')}</p>

                  <Link className='btn btn-primary mt-3 button-action rounded-4' to='/'>
                    <i className='icon icon-arrow-l me-1 icon-20px' />
                    {t('Return Home')}
                  </Link>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default PageNotFound
