import React from 'react'
import { useTranslation } from 'react-i18next'

import { universalLinkImage } from 'assets/images'

export const ForgotPasswordSuccess = () => {
  const { t } = useTranslation('auth')

  return (
    <div className='m-auto text-center'>
      <img src={universalLinkImage} alt='mail sent' />

      <h4 className='text-dark-50 text-center mt-4 fw-bold'>
        {t('We’ve sent the link to your email')}
      </h4>

      <p className='text-muted mb-4'>
        {t('Open your email to check the link we’ve sent to change your password.')}
      </p>
    </div>
  )
}
