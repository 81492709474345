import snakeCase from 'lodash/snakeCase'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { addMethod, mixed, object, setLocale, string } from 'yup'
import { LocaleObject } from 'yup/lib/locale'

export const useYup = () => {
  const { t } = useTranslation('validation')

  const [hydrated, setHydrated] = useState<boolean>(false)

  const yupSetLocale: LocaleObject = {
    mixed: {
      notType: ({ type }) => t('not_type_of', { type }),
      required: ({ path }) => t(`required_${snakeCase(path)}`),
      oneOf: ({ path }) => t(`validity_${snakeCase(path)}`),
    },
    string: {
      email: ({ path }) => t(`validity_${snakeCase(path)}`),
      matches: ({ path }) => t(`validity_${snakeCase(path)}`),
      min: ({ min }) => t('minimum_character', { min }),
    },
    number: {
      moreThan: ({ more }) => t('more_than_number', { more }),
      max: ({ max }) => t('maximum_number', { max }),
      min: ({ min }) => t('minimum_number', { min }),
    },
    array: {
      min: ({ min, path }) => t(`minimum_array_${snakeCase(path)}`, { min }),
    },
  }

  const yupAddMethod = () => {
    addMethod(string, 'phoneNumber', function () {
      return this.matches(/^[0-9]{4,15}$/)
    })

    addMethod(string, 'password', function (min, condition = true) {
      const regexPattern = new RegExp(`^.{${min},}$`)

      if (!condition) return this

      return this.matches(regexPattern, t('password_min_length', { min }))
        .matches(/^(?=.*[a-z])(?=.*[A-Z])/, t('password_lowercase_and_uppercase'))
        .matches(/^(?=.*[0-9])/, t('password_number'))
    })

    addMethod(string, 'requiredWhen', function (condition) {
      return this.when({
        is: () => condition,
        then: (shape) => shape.required(),
      })
    })

    addMethod(string, 'minIfFilled', function (min) {
      return this.when({
        is: (value: string) => value && value.length > 0,
        then: (shape) => shape.min(min),
      })
    })

    addMethod(object, 'requiredWhen', function (condition) {
      return this.when({
        is: () => condition,
        then: (shape) => shape.required(),
      })
    })

    addMethod(mixed, 'maxSize', function (maxSize: number) {
      return this.test('fileSize', t('image_size_validation', { max: maxSize / 1024 }), (value) => {
        if (!value) return true
        if (!value[0]?.size) return true

        return value && Number(value[0]?.size) <= maxSize * 1024
      })
    })
  }

  useEffect(() => {
    try {
      setLocale(yupSetLocale)
      yupAddMethod()
    } finally {
      setHydrated(true)
    }
  }, [])

  return { hydrated }
}
