import classNames from 'classnames'
import kebabCase from 'lodash/kebabCase'
import snakeCase from 'lodash/snakeCase'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useSearchParams } from 'react-router-dom'

import { leaderboardBackground, logoColorSm } from 'assets/images'
import { usePublicLeaderboard } from 'services/swr/use-public-leaderboard'
import { remoteImage } from 'utils/remote-image'

import { Avatar } from './components/avatar'
import './styles.scss'

const LeaderboardCard = () => {
  const params = useParams()
  const [searchParams] = useSearchParams()
  const { t } = useTranslation('auth')

  const [loadedImages, setLoadedImages] = useState<number[]>([])
  const [imagesLoaded, setImagesLoaded] = useState({ background: false, logo: false })

  const leaderboardParams = useMemo(
    () => ({
      type: searchParams.get('type') || 'distance',
      weekDate: searchParams.get('date') || new Date().toISOString(),
      dateType: searchParams.get('dateType') || 'weekly',
    }),
    [searchParams]
  )

  const { leaderboard } = usePublicLeaderboard({ id: params?.id, params: leaderboardParams })

  const valueKey = useMemo(() => {
    switch (kebabCase(leaderboardParams?.type)) {
      case 'distance':
        return 'totalDistance'
      case 'elevation':
        return 'totalElevation'
      case 'watt-per-kilo':
        return 'wattPerKilo'
      default:
        return 'totalDistance'
    }
  }, [leaderboardParams?.type])

  const context = useMemo(() => {
    return `${snakeCase(leaderboardParams?.dateType)}_${snakeCase(leaderboardParams?.type)}`
  }, [leaderboardParams?.dateType, leaderboardParams?.type])

  const leaderboards = useMemo(() => {
    if (!leaderboard?.leaderboards) return []

    const sortedLeaderboards = leaderboard?.leaderboards
      .map((leaderboard) => ({ ...leaderboard }))
      .sort((a, b) => b[valueKey] - a[valueKey])
    let rank = 1

    for (let i = 0; i < sortedLeaderboards.length; i++) {
      if (i > 0 && sortedLeaderboards[i][valueKey] === sortedLeaderboards[i - 1][valueKey]) {
        sortedLeaderboards[i].rank = sortedLeaderboards[i - 1].rank
      } else {
        sortedLeaderboards[i].rank = rank
        rank++
      }
    }

    return sortedLeaderboards
  }, [leaderboard?.leaderboards, valueKey])

  const handleImageLoad = (id: number) => {
    setLoadedImages((prev) => [...prev, id])
  }

  useEffect(() => {
    const loadImages = (src: string, key: keyof typeof imagesLoaded) => {
      const img = new Image()
      img.src = src
      img.onload = () => setImagesLoaded((prev) => ({ ...prev, [key]: true }))
    }

    loadImages(leaderboardBackground, 'background')
    loadImages(logoColorSm, 'logo')

    return () => {
      setImagesLoaded({ background: false, logo: false })
    }
  }, [])

  const topRanks = useMemo(() => leaderboards.filter(({ rank }) => rank <= 3), [leaderboards])

  const allImagesLoaded = useMemo(() => {
    if (!topRanks.length || !loadedImages.length) return false

    return (
      topRanks.every(({ rank }) => loadedImages.includes(rank)) &&
      imagesLoaded.background &&
      imagesLoaded.logo
    )
  }, [loadedImages, topRanks, imagesLoaded])

  const renderRankComponent = (rank: number, avatarClass: string) => {
    const userRanks = topRanks.filter(({ rank: r }) => r === rank).slice(0, 2)

    return (
      <div className={`${avatarClass}-wrapper`}>
        {userRanks.map((userRank, index) => (
          <div
            key={index}
            className={classNames('avatar-wrapper', {
              left: index === 0 && userRanks.length === 2,
              right: index === 1 && userRanks.length === 2,
            })}
          >
            <Avatar
              src={remoteImage(userRank.user.photoProfile)}
              fallbackText={`${userRank.user.firstName} ${userRank.user.lastName}`}
              containerClassName={classNames('avatar', {
                big: userRanks.length === 1,
                small: userRanks.length === 2,
              })}
              textClassName={classNames('avatar-text', {
                small: userRanks.length === 2,
                big: userRanks.length === 1,
              })}
              onRender={() => handleImageLoad(userRank.rank)}
            />
            <div
              className={classNames('medal', {
                small: userRanks.length === 2,
                big: userRanks.length === 1,
              })}
            >
              <h1
                className={classNames('medal-text', {
                  small: userRanks.length === 2,
                  big: userRanks.length === 1,
                })}
              >
                {rank}
              </h1>
            </div>
          </div>
        ))}
      </div>
    )
  }

  const renderTopRanks = useMemo(() => {
    if (!topRanks.length) return null

    return (
      <>
        {renderRankComponent(2, 'silver')}
        {renderRankComponent(1, 'gold')}
        {renderRankComponent(3, 'bronze')}
      </>
    )
  }, [topRanks])

  return (
    <div
      className={classNames('leaderboard-card', leaderboardParams.dateType)}
      id={allImagesLoaded ? 'leaderboard-share-ready' : 'leaderboard-share'}
    >
      <div className='podium'>{renderTopRanks}</div>
      <div className='title-wrapper'>
        <div className='logo-image' />
        <h5 className='event-details-text'>{t('leaderboard_title', { context })}</h5>
      </div>
    </div>
  )
}

export default LeaderboardCard
