import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

import { adminEditPath, adminNewPath, adminPath } from 'config/paths'
import { LoadComponent } from 'routes/load-component'

const adminList = lazy(() => import('./admin-list/admin-list'))
const adminDetails = lazy(() => import('./admin-details/admin-details'))
const adminForm = lazy(() => import('./admin-form/admin-form'))

export const adminRoutes = (): RouteObject[] => [
  {
    index: true,
    path: adminPath(),
    element: <LoadComponent component={adminList} />,
  },
  {
    path: adminPath(':id'),
    element: <LoadComponent component={adminDetails} />,
  },
  {
    path: adminNewPath(),
    element: <LoadComponent component={adminForm} />,
  },
  {
    path: adminEditPath(':id'),
    element: <LoadComponent component={adminForm} />,
  },
]
