import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import SimpleBar from 'simplebar-react'

import { logoColor, logoLightSm } from 'assets/images'

import { MENU_ITEMS } from './constant/menu-constant'
import Menu from './menu/menu'

type LeftSidebarProps = {
  hideLogo?: boolean
  hideUserProfile?: boolean
  isLight: boolean
  isCondensed?: boolean
  paddingTop?: number | string
}

const LeftSidebar = ({ hideLogo, isCondensed, paddingTop }: LeftSidebarProps) => {
  const menuNodeRef = useRef<HTMLDivElement>(null)

  /**
   * Handle the click anywhere in doc
   */
  const handleOtherClick = (e: MouseEvent) => {
    if (menuNodeRef && menuNodeRef.current && menuNodeRef.current.contains(e.target as Node)) return
    // else hide the menubar
    if (document.body) {
      document.body.classList.remove('sidebar-enable')
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOtherClick, false)

    return () => {
      document.removeEventListener('mousedown', handleOtherClick, false)
    }
  }, [])

  return (
    <div className='leftside-menu' ref={menuNodeRef} style={{ paddingTop }}>
      {!hideLogo && (
        <>
          <Link to='/' className={`logo ${isCondensed && 'text-center'}`}>
            <span className='logo-lg'>
              <img src={logoColor} alt='logo' height='25' />
            </span>
            <span className='logo-sm'>
              <img src={logoLightSm} alt='logo' height='25' />
            </span>
          </Link>
        </>
      )}

      {!isCondensed && (
        <SimpleBar style={{ maxHeight: '100%' }} timeout={500} scrollbarMaxSize={320}>
          <Menu menuItems={MENU_ITEMS} />
          <div className='clearfix' />
        </SimpleBar>
      )}

      {isCondensed && (
        <>
          <Menu menuItems={MENU_ITEMS} />
          <div className='clearfix' />
        </>
      )}
    </div>
  )
}

export default LeftSidebar
