import classNames from 'classnames'
import React from 'react'
import { Badge as RBBadge } from 'react-bootstrap'

import { BadgeProps } from './types'

export const Badge = ({
  label,
  icon,
  outline,
  textColor,
  className,
  ...otherProps
}: BadgeProps) => (
  <RBBadge
    className={classNames(
      'rounded-pill d-inline-flex align-items-center justify-content-center custom-badge',
      {
        border: outline,
        [`text-${textColor}`]: textColor,
        [`badge-custom-outline-${outline}`]: outline,
      },
      className
    )}
    {...otherProps}
  >
    {icon && <i className={`${icon} icon align-middle`} />}
    {label && <span>{label}</span>}
  </RBBadge>
)
