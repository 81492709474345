import classNames from 'classnames'
import React, { FunctionComponent } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useToggle } from 'hooks'

import { PROFILE_MENUS } from './profile-menus'

type ProfileDropdownProps = {
  userImage?: string
  username: string
  userTitle?: string
}

const ProfileDropdown: FunctionComponent<ProfileDropdownProps> = (props) => {
  const { t } = useTranslation()

  const [isOpen, toggleDropdown] = useToggle()

  const { userImage, username, userTitle } = props

  return (
    <li className='dropdown notification-list'>
      <Dropdown show={isOpen} onToggle={toggleDropdown}>
        <Dropdown.Toggle
          variant='link'
          id='dropdown-profile'
          as='div'
          onClick={toggleDropdown}
          className='nav-link dropdown-toggle nav-user arrow-none me-0'
          role='button'
          aria-label={t('Show Profile')}
        >
          {userImage && (
            <span className='account-user-avatar'>
              <img src={userImage} className='rounded-circle' alt='user' />
            </span>
          )}
          <span>
            <span className='account-user-name text-raisin-black'>{username}</span>
            <span className='account-position text-secondary fw-semibold text-capitalize'>
              {userTitle}
            </span>
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu
          align={'end'}
          className='dropdown-menu-animated topbar-dropdown-menu profile-dropdown'
        >
          <div onClick={toggleDropdown}>
            <div className='dropdown-header noti-title'>
              <h6 className='text-overflow m-0'>{t('Welcome')}</h6>
            </div>
            {PROFILE_MENUS.map((item, index) => (
              <Link
                to={item.redirectTo}
                className='dropdown-item notify-item'
                key={index + '-profile-menu'}
              >
                <i className={classNames(item.icon, 'me-1')}></i>
                <span>{item.label}</span>
              </Link>
            ))}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </li>
  )
}

export default ProfileDropdown
