import { ApiCore } from './api-core'

export class GlobalSearchApi extends ApiCore {
  async search(keyword: string) {
    return await this.get({
      path: 'searchs',
      payload: { keyword },
    })
  }
}

export const globalSearchApi = new GlobalSearchApi()
