import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

import { betaTesterEditPath, betaTesterNewPath, betaTesterPath } from 'config/paths'
import { LoadComponent } from 'routes/load-component'

const betaTesterList = lazy(() => import('./beta-tester-list/beta-tester-list'))
const betaTesterDetails = lazy(() => import('./beta-tester-details/beta-tester-details'))
const betaTesterForm = lazy(() => import('./beta-tester-form/beta-tester-form'))

export const betaTesterRoutes = (): RouteObject[] => [
  {
    index: true,
    path: betaTesterPath(),
    element: <LoadComponent component={betaTesterList} />,
  },
  {
    path: betaTesterPath(':id'),
    element: <LoadComponent component={betaTesterDetails} />,
  },
  {
    path: betaTesterNewPath(),
    element: <LoadComponent component={betaTesterForm} />,
  },
  {
    path: betaTesterEditPath(':id'),
    element: <LoadComponent component={betaTesterForm} />,
  },
]
