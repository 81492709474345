import React from 'react'
import { Form } from 'react-bootstrap'
import { Controller, useFormContext } from 'react-hook-form'

import { FileUploader } from './file-uploader'
import { ControlledFileUploaderProps } from './file-uploader.types'

export const ControlledFileUploader = (props: ControlledFileUploaderProps) => {
  const { label, name, containerClass, ...otherProps } = props

  const { control } = useFormContext()

  const formatValue = (value: any) => {
    if (typeof value === 'string') return [{ preview: value }]

    if (Array.isArray(value)) {
      return value.map((item) => {
        if (item?.url) return { preview: item.url }

        return item
      })
    }

    return value
  }

  return (
    <Form.Group className={containerClass}>
      {label && <Form.Label>{label}</Form.Label>}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
          <>
            <FileUploader
              onFileUpload={onChange}
              isInvalid={invalid}
              value={formatValue(value)}
              name={name}
              {...otherProps}
            />

            {error && (
              <Form.Control.Feedback type='invalid' className='d-block'>
                {error.message}
              </Form.Control.Feedback>
            )}
          </>
        )}
      />
    </Form.Group>
  )
}
