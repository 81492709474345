import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { FormProvider, SubmitButton } from 'components'
import { ControlledOTPInput } from 'components/forms/otp-input/controlled-otp-input'
import { loadString } from 'utils/session-storage'

import useVerifyOtpCode from './verify-otp-code.hook'

const VerifyOtpCode = () => {
  const { t } = useTranslation('auth')
  const email = loadString('email')

  const { onSubmit, methods, resendOTPCode, countdown, countdownFormatted } = useVerifyOtpCode()

  return (
    <>
      <div className='m-auto text-center d-flex flex-column align-items-center verify-otp text-balance'>
        <h4 className='text-black mt-0 fw-bold display-6'>{t('OTP Code')}</h4>
        <p className='text-muted mb-3'>
          {t('We’ve sent a verification code to', {
            email: email?.replace(email?.substring(3, email.indexOf('@')), '...'),
          })}
          <Link to={'/account/login'}>{t('Change Email')}</Link>
        </p>
      </div>

      <FormProvider methods={methods} onSubmit={onSubmit} name='verifyOtpCode'>
        <ControlledOTPInput name='code' numInputs={6} className='otp-input-container' />

        <div className='d-flex justify-content-center my-4'>
          <SubmitButton
            variant='primary'
            type='submit'
            disabled={!methods.formState.isDirty}
            loading={methods.formState.isSubmitting}
          >
            {t('Verify')}
          </SubmitButton>
        </div>

        <p className='m-auto text-center text-balance resend-otp'>
          {t('Didn’t receive the code?')}
          <Link to={'#'} onClick={resendOTPCode} className={`${!!countdown && 'disabled'}`}>
            {t('resend_code_countdown', { count: countdown, countdownFormatted })}
          </Link>
        </p>
      </FormProvider>
    </>
  )
}

export default VerifyOtpCode
