import startCase from 'lodash/startCase'
import React from 'react'
import { Placeholder } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { TableLoaderProps } from './table.types'

export const TableLoader = ({ table }: TableLoaderProps) => {
  const { t } = useTranslation()

  return (
    <tbody>
      {Array(10)
        .fill('row')
        .map((_, row) => (
          <tr key={row}>
            {table.getAllColumns().map((column) => (
              <td key={`${column.id}-${row}`} data-label={t(startCase(column.id))}>
                <Placeholder as='div' animation='wave'>
                  <Placeholder size='lg' bg='secondary' className='w-100' />
                </Placeholder>
              </td>
            ))}
          </tr>
        ))}
    </tbody>
  )
}
