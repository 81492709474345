import { flexRender } from '@tanstack/react-table'
import classNames from 'classnames'
import startCase from 'lodash/startCase'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { TableEmpty, TableError } from './table-error'
import { TableLoader } from './table-loader'
import { TableBodyProps } from './table.types'

export const TableBody = (props: TableBodyProps) => {
  const { table, isLoading, isError, ...tableEmptyProps } = props

  const [, setSearchParams] = useSearchParams()

  const { t } = useTranslation('table')

  if (isLoading) return <TableLoader table={table} />

  if (isError) return <TableError table={table} onRetry={() => setSearchParams({})} />

  if (!table.getRowModel().rows.length) {
    return <TableEmpty {...tableEmptyProps} table={table} />
  }

  return (
    <tbody>
      {table.getRowModel().rows.map((row) => (
        <tr key={row.id}>
          {row.getVisibleCells().map((cell) => (
            <td
              className={classNames('align-middle', {
                'text-center': cell.column.columnDef.meta?.centered ?? true,
              })}
              key={cell.id}
              data-label={t(startCase(cell.column.id))}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  )
}
