import classNames from 'classnames'
import React from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import { deleteConfirmationImage, documentImage } from 'assets/images'
import { ControlledSelect } from 'components/forms'
import { showConfirmationModal } from 'stores/app-state-store.actions'
import { authStore } from 'stores/auth-store'

import { TableHeaderProps } from './table.types'

type BulkActionProps = {
  hasSelectedRows: boolean
  activeRowsSelectionCount: number
} & Partial<TableHeaderProps>

export const BulkAction = (props: BulkActionProps) => {
  const { id } = useSnapshot(authStore.state.user)

  const { t } = useTranslation()

  const {
    activeRowsSelectionCount,
    bulkActions = ['delete', 'status'],
    hasSelectedRows,
    onConfirmDelete,
    onUpdateStatus,
    table,
    statusOptions,
    deleteIcon = 'icon-trash',
  } = props

  const getFirstObject = table.getSelectedRowModel().rows.map((row) => row.original)[0]

  const idKey = Object.keys(getFirstObject || {})[0]

  const member = location.pathname.split('/').slice(-1)[0] === 'members'

  const ids = table
    .getSelectedRowModel()
    .rows.map((row) => row.original[member ? 'accountId' : idKey])

  const loggedUser = ids.length === 1 && ids[0] === id

  if (!hasSelectedRows) return null

  const featureType = location.pathname.split('/')[1]

  const deleteTitle = member ? 'modal_confirm_remove_member_title' : 'modal_confirm_delete_title'
  const deleteBody = member ? 'modal_confirm_remove_member_body' : 'modal_confirm_delete_body'
  const deleteConfirmLabel = member ? 'Remove member type' : 'Delete type'

  return (
    <div
      className={classNames('table-row-selection', {
        'w-100': bulkActions?.includes('status'),
      })}
    >
      <div className='selection-count'>
        <i className='icon icon-check icon-24px' />
        <span>{t('Items Selected', { count: activeRowsSelectionCount })}</span>
      </div>

      <div
        className={classNames('selection-action', {
          'w-100': bulkActions?.includes('status'),
        })}
      >
        {bulkActions?.includes('status') && (
          <ControlledSelect
            name='status'
            options={statusOptions}
            containerClass='w-100 select-action'
            onChange={(e: any) =>
              showConfirmationModal({
                title: t('modal_confirm_status_title', {
                  type: featureType,
                }),
                body: t('modal_confirm_status_body', {
                  status: e.label,
                  type: featureType,
                }),
                onConfirm: () =>
                  onUpdateStatus({
                    ids,
                    status: e.value,
                  }),
                variant: 'primary',
                action: e.value,
                avatarImage: documentImage,
                confirmLabel: t('Update status to', { status: e.label }),
              })
            }
          />
        )}

        {bulkActions?.includes('delete') && !loggedUser && (
          <Button
            variant='outline-danger'
            className='bg-white m-0'
            onClick={() =>
              showConfirmationModal({
                title: t(deleteTitle, {
                  type: featureType,
                }),
                body: t(deleteBody, {
                  type: featureType,
                }),
                avatarIcon: deleteIcon,
                avatarImage: deleteConfirmationImage,
                onConfirm: async () => {
                  const success: any = await onConfirmDelete(ids)

                  if (success?.ok) table.resetRowSelection()
                },
                confirmLabel: t(deleteConfirmLabel, { type: featureType }),
              })
            }
          >
            <i className={`${deleteIcon} icon`} />
          </Button>
        )}
      </div>
    </div>
  )
}
