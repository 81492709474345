import { ApiResponse, ApisauceInstance, create } from 'apisauce'
import dayjs from 'dayjs'
import { snapshot } from 'valtio'

import { APPLE_MAP_API_BASE_URL } from 'config/env'
import { ApiErrorKind, ApiParams, RequestMethod, authApi } from 'services/api'
import { appStateStore } from 'stores/app-state-store'
import { authStore } from 'stores/auth-store'

import { getGeneralApiProblem } from '../api/helpers/api-problem'

/**
 * Manages all requests to the API.
 */
export class AppleMapsApi {
  /**
   * The underlying apisauce instance which performs the requests.
   */
  protected baseURL = APPLE_MAP_API_BASE_URL || ''
  protected api: ApisauceInstance
  protected tokenApi: ApisauceInstance

  /**
   * Creates the api.
   *
   * @param type The JSON:Api query types.
   * @param attributes The JSON:Api query attributes.
   */
  constructor() {
    // construct the apisauce instance
    this.api = create({
      baseURL: this.getBaseURL(),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })

    this.tokenApi = create({
      baseURL: this.getBaseURL(),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })

    this.addHeaderTransformer()
    this.addAsyncResponseTransform()
  }

  private getBaseURL() {
    return this.baseURL.replace(/\/$/, '')
  }

  private async getAccessToken() {
    try {
      const result: ApiResponse<any> = await this.processResult(await this.tokenApi.get('/token'))

      if (result.ok) {
        authStore.state.mapsAccessToken = result.data.accessToken
        authStore.state.mapsAccessTokenExpiry = dayjs()
          .add(result.data.expiresInSeconds, 'second')
          .toISOString()
      }
    } catch (error: any) {
      if (error.kind === ApiErrorKind.UNAUTHORIZED) {
        await authApi.getRefreshToken()
      }
    }
  }

  private addHeaderTransformer() {
    this.api.addRequestTransform(async (request) => {
      const { mapsAccessToken } = snapshot(authStore.state)

      if (mapsAccessToken) {
        request.headers.Authorization = `Bearer ${mapsAccessToken}`
      }
    })

    this.tokenApi.addRequestTransform(async (request) => {
      const { mapsAuthToken } = snapshot(authStore.state)

      if (mapsAuthToken) {
        request.headers.Authorization = `Bearer ${mapsAuthToken}`
      }
    })
  }

  private addAsyncResponseTransform() {
    this.api.addAsyncResponseTransform(async (response) => {
      const accessToken = response.config.url.split('/').includes('token')

      if (response.status === 401 && !accessToken) {
        await this.getAccessToken()
      }
    })
  }

  private async processResult(response: ApiResponse<any>) {
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    return Promise.resolve(response)
  }

  protected async callApi(method: RequestMethod, { path, payload }: ApiParams) {
    const { isMapTokenValid } = snapshot(authStore.state)

    if (!isMapTokenValid) await this.getAccessToken()

    return await this.api[method](path, payload)
  }

  async autocomplete(keywords: string, queries = {}) {
    const { locale } = snapshot(appStateStore.state)

    const response: ApiResponse<any> = await this.callApi('get', {
      path: '/searchAutocomplete',
      payload: {
        q: keywords,
        language: locale,
        ...queries,
      },
    })

    return await this.processResult(response)
  }

  async search(keywords: string, queries = {}) {
    const { locale } = snapshot(appStateStore.state)

    const response: ApiResponse<any> = await this.callApi('get', {
      path: '/search',
      payload: {
        q: keywords,
        lang: locale,
        ...queries,
      },
    })

    return await this.processResult(response)
  }
}

export const appleMapsApi = new AppleMapsApi()
