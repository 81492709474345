import classNames from 'classnames'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { useSnapshot } from 'valtio'

import { authStore } from 'stores/auth-store'

import MenuItemLink from './menu-item-link'
import { SubMenus } from './types'

const MenuItem: FunctionComponent<SubMenus> = (props) => {
  const { isSuperAdmin } = useSnapshot(authStore.computed)

  const { item, linkClassName } = props

  const [isActive, setIsActive] = useState<boolean>(false)

  useEffect(() => {
    setIsActive(location.pathname.startsWith(item.url))
  }, [location.pathname])

  if (item.superAdmin && !isSuperAdmin) return null

  return (
    <li className={classNames('side-nav-item', { 'menuitem-active': isActive })}>
      <MenuItemLink item={item} className={linkClassName} />
    </li>
  )
}

export default MenuItem
