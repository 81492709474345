import classNames from 'classnames'
import React from 'react'
import { Button, ButtonProps } from 'react-bootstrap'

type ButtonItemProps = {
  isMulti: boolean
  isSelected: boolean
  label: string
  onClick: () => void
} & ButtonProps

export const ButtonItem = (props: ButtonItemProps) => {
  const { isMulti, isSelected, onClick, label, ...otherProps } = props

  if (!isMulti) {
    return (
      <input
        aria-label='selected-items'
        type='radio'
        onChange={onClick}
        checked={isSelected}
        className={classNames('selected-items')}
      />
    )
  }

  return (
    <Button
      variant={isSelected ? 'primary' : 'outline-gray-light'}
      className={classNames(
        'rounded-2 py-1 px-2 fw-medium font-14 d-flex align-items-center gap-1 rounded-3',
        { 'text-raisin-black': !isSelected }
      )}
      data-test-id='button-selection-item'
      {...otherProps}
      onClick={onClick}
    >
      {label}
      <i
        className={classNames('icon', {
          'icon-check icon-20px': isSelected,
          'icon-plus icon-18px': !isSelected,
        })}
      />
    </Button>
  )
}
