import classNames from 'classnames'
import React from 'react'

import { BaseDetailItem } from './base-detail-item'
import { DetailItemProps } from './detail-item.types'

export const DetailItem = (props: DetailItemProps) => {
  const { value, semibold, medium, ...otherProps } = props

  return (
    <BaseDetailItem {...otherProps}>
      {value && (
        <span className={classNames({ 'fw-semibold': semibold, 'fw-medium': medium })}>
          {value}
        </span>
      )}
    </BaseDetailItem>
  )
}
