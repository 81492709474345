import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useSnapshot } from 'valtio'

import { authStore } from 'stores/auth-store'

type PrivateRouteProps = {
  component: React.ComponentType
}

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ component: RouteComponent }: PrivateRouteProps) => {
  const location = useLocation()

  const {
    computed: { isAuthenticated },
  } = useSnapshot(authStore)

  /**
   * not logged in so redirect to login page with the return url
   */
  if (!isAuthenticated) {
    return <Navigate to={'/account/login'} state={{ from: location }} replace />
  }

  // add if route is restricted by role below here

  return <RouteComponent />
}

export default PrivateRoute
