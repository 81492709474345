import classNames from 'classnames'
import React, { useRef, useState } from 'react'
import { InputGroup } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { ActionButton } from 'components/button/action-button'
import { useClickOutside } from 'hooks/use-click-outside'

type TableFilterProps = {
  onClearFilter: () => void
  filters: React.ReactNode
  activeFilterCount?: number
}

export const TableFilter = (props: TableFilterProps) => {
  const { onClearFilter, filters, activeFilterCount } = props

  const [filterModalVisible, setFilterModalVisible] = useState<boolean>(false)

  const { t } = useTranslation()
  const filterModalRef = useRef<HTMLDivElement>(null)

  useClickOutside({
    action: () => setFilterModalVisible(false),
    targetRef: filterModalRef,
  })

  return (
    <>
      <InputGroup
        className={classNames('table-filter-toggle btn-outline-gray-light', {
          'active-filter': !!activeFilterCount,
        })}
        onClick={(e) => {
          e.stopPropagation()
          setFilterModalVisible((prev) => !prev)
        }}
        aria-label='toggle-filter'
      >
        <ActionButton
          variant='outline-gray-light'
          icon='icon-filter'
          className='text-raisin-black'
          label={t('Filter')}
        />
        {!!activeFilterCount && <span className='active-count'>{activeFilterCount}</span>}
      </InputGroup>

      <div
        ref={filterModalRef}
        className={classNames('table-filter-section fade', {
          show: filterModalVisible,
          'd-none': !filterModalVisible,
        })}
      >
        <div className='d-flex align-items-center gap-1 mb-3'>
          <div className='bg-primary rounded-circle text-white background-icon-title d-flex align-items-center justify-content-center'>
            <i className='icon icon-filter' />
          </div>

          <h5 className='fw-600'>{t('Apply Filter')}</h5>
        </div>

        <div onClick={(e) => e.stopPropagation()}>{filters}</div>

        <div className='mt-3 d-flex justify-content-between gap-2'>
          <ActionButton
            variant='outline-gray-light'
            className='text-raisin-black'
            onClick={() => {
              onClearFilter()
              setFilterModalVisible(false)
            }}
            label={t('Reset All')}
          />
          <ActionButton
            variant='primary'
            onClick={() => setFilterModalVisible(false)}
            label={t('Apply')}
            type='submit'
          />
        </div>
      </div>
    </>
  )
}
