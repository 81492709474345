import { createPath } from './path-utils'
import { PathGenerator, PathGeneratorWithId, PathGeneratorWithParams } from './path.types'

const GROUP_PATH = '/groups'

export const groupEditPath: PathGeneratorWithParams = (params) => {
  return createPath(`${GROUP_PATH}/edit`, params)
}

export const groupPath: PathGeneratorWithId = (id) => {
  return createPath(GROUP_PATH, id)
}

export const groupMemberPath: PathGeneratorWithId = (id) => {
  return createPath(GROUP_PATH, { id, suffix: 'members' })
}

export const groupNewPath: PathGenerator = () => {
  return createPath(GROUP_PATH, { suffix: 'new' })
}

export const groupAddMemberPath: PathGeneratorWithId = (id) => {
  return createPath(GROUP_PATH, { id, suffix: 'members/add' })
}
