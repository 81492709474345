import { ApiResponse, ApisauceInstance, create } from 'apisauce'
import isArray from 'lodash/isArray'

import { API_BASE_URL, GOOGLE_MAP_API_KEY } from 'config/env'
import { convertKeyToCamelCase } from 'utils/object'

import { getGeneralApiProblem } from '../api/helpers/api-problem'

/**
 * Manages all requests to the API.
 */
export class GoogleMapsApi {
  /**
   * The underlying apisauce instance which performs the requests.
   */
  apisauce: ApisauceInstance

  /**
   * Creates the api.
   *
   * @param type The JSON:Api query types.
   * @param attributes The JSON:Api query attributes.
   */
  constructor() {
    // construct the apisauce instance
    this.apisauce = create({
      baseURL: `${API_BASE_URL?.replace(/\/$/, '')}/maps`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
  }

  processResult(response: ApiResponse<any>, fieldToConvert = '') {
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    if (fieldToConvert && isArray(response.data[fieldToConvert])) {
      response.data[fieldToConvert] = response.data[fieldToConvert].map((data: any) =>
        convertKeyToCamelCase(data)
      )
    } else {
      response.data[fieldToConvert] = convertKeyToCamelCase(response.data[fieldToConvert])
    }

    return Promise.resolve(response)
  }

  async geocode(latitude: number, longitude: number) {
    const response: ApiResponse<any> = await this.apisauce.get('/geocode/json', {
      latlng: `${latitude},${longitude}`,
      sensor: false,
      language: 'en',
      result_type: 'street_address|locality',
      key: GOOGLE_MAP_API_KEY,
    })

    return this.processResult(response)
  }

  async autocomplete(keywords: string, queries = {}) {
    const response: ApiResponse<any> = await this.apisauce.get('/place/autocomplete/json', {
      input: keywords,
      language: 'en',
      key: GOOGLE_MAP_API_KEY,
      ...queries,
    })

    return this.processResult(response, 'predictions')
  }

  async getDetails(placeId: string) {
    const response: ApiResponse<any> = await this.apisauce.get('/place/details/json', {
      place_id: placeId,
      fields: 'geometry,utc_offset,address_components',
      language: 'en',
      key: GOOGLE_MAP_API_KEY,
    })

    return this.processResult(response)
  }

  async getTimezone(location: string) {
    const response: ApiResponse<any> = await this.apisauce.get('/timezone/json', {
      location,
      timestamp: Math.floor(new Date().getTime() / 1000),
      language: 'en',
      key: GOOGLE_MAP_API_KEY,
    })

    return this.processResult(response)
  }
}

export const googleMapApi = new GoogleMapsApi()
