import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

import { COUNTDOWN_TIME } from 'config/env'
import { loadString, saveString } from 'utils/storage'

export const useCountdownOTP = () => {
  const countdownKey = 'otp-countdown'
  const initialTime = Number(loadString(countdownKey) || 0)

  const [countdown, setCountdown] = useState<number>(initialTime)

  const start = () => {
    setCountdown((prevCountdown) => {
      if (prevCountdown > 0) return countdown

      saveString(countdownKey, String(COUNTDOWN_TIME))
      return Number(COUNTDOWN_TIME)
    })

    return true
  }

  const reset = () => {
    setCountdown(() => {
      saveString(countdownKey, String(0))
      return Number(0)
    })
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevTime) => {
        if (prevTime === 0) {
          return 0
        }

        saveString(countdownKey, String(prevTime - 1))
        return prevTime - 1
      })
    }, 1000)

    return () => clearInterval(timer)
  }, [])

  const countdownFormatted = dayjs(countdown * 1000).format('mm:ss')

  return {
    countdown,
    countdownFormatted,
    start,
    reset,
  }
}
