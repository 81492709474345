export const IS_PROD = process.env.NODE_ENV === 'production'
export const IS_DEV = process.env.NODE_ENV === 'development'
export const ENVIRONMENT = process.env.ENVIRONMENT
export const SENTRY_DSN = process.env.SENTRY_DSN
export const API_BASE_URL = process.env.API_BASE_URL
export const GOOGLE_MAP_API_KEY = process.env.GOOGLE_MAP_API_KEY
export const API_TIMEOUT = 0
export const LANGUAGE_STORAGE_KEY = 'LOCALE'
export const ACCESS_TOKEN_STORAGE_KEY = 'ACCESS_TOKEN'
export const COUNTDOWN_TIME = Number(process.env.COUNTDOWN_TIME) || 60 // in seconds
export const ASSETS_BASE_URL = process.env.ASSETS_BASE_URL
export const APP_STORE_LINK =
  process.env.APP_STORE_LINK || 'https://apps.apple.com/us/app/factum/id6469985548'
export const GOOGLE_STORE_LINK =
  process.env.GOOGLE_STORE_LINK || 'https://play.google.com/store/apps/details?id=com.factum.app'
export const DEEP_LINK_BASE_URL = process.env.DEEP_LINK_BASE_URL || 'factumapp://'
export const PUBLIC_WEBSITE_LINK = process.env.PUBLIC_WEBSITE_LINK || 'https://factumlabs.com/'
export const APPLE_MAP_API_BASE_URL = process.env.APPLE_MAP_API_BASE_URL
export const CONTACT_SUPPORT_URL =
  process.env.CONTACT_SUPPORT_URL || 'https://www.factumlabs.com/contact'
export const IS_APP_SUBDOMAIN = process.env.SUBDOMAIN === 'app'
