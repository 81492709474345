import { translate as t } from 'locales/i18n'

export function generatePageTitleIcon(path: string[], defaultIcon: string) {
  switch (true) {
    case path.includes('new'):
      return 'icon-plus'
    case path.includes('edit'):
      return 'icon-edit'
    case path.includes('members'):
      return 'icon-people'
    case path.includes('me'):
      return 'icon-setting'
    default:
      return defaultIcon
  }
}

export function generatePageTitle(
  path: string[],
  activePage?: Record<string, any>,
  defaultTitle?: string,
  id?: string
) {
  switch (true) {
    case path.includes('new'):
      return t('layout_title_create', { title: activePage.label })
    case path.includes('edit'):
      return t('layout_title_edit', { title: activePage.label })
    case path.includes('add') && path.includes('members'):
      return t('layout_title_add_members', { title: activePage.label })
    case path.includes('members'):
      return t('layout_title_members', { title: activePage.label })
    case !!id:
      return t('layout_title_detail', { title: activePage.label })
    case path.includes('groups'):
    case path.includes('events'):
    case path.includes('stories'):
      return t(`${defaultTitle} Management`)
    case path.includes('me'):
      return t('layout_title_profile')
    default:
      return defaultTitle
  }
}
