import React from 'react'
import { useParams } from 'react-router-dom'

import { usePublicEvent } from 'services/swr/use-public-event'

import './styles.scss'
import { MultiGrid } from './components/multiple-grid'
import { SingleGrid } from './components/single-grid'

const EventCard = () => {
  const { id } = useParams()
  const { event } = usePublicEvent(id)

  if (!event) return null

  if (event.routes.length === 1) return <SingleGrid id={id} />

  return <MultiGrid id={id} />
}

export default EventCard
