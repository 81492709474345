import * as i18next from 'i18next'
import detector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import { IS_DEV } from 'config/env'
import { setLocale } from 'stores/app-state-store.actions'

import en from './en'

i18next
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: { en },
    defaultNS: 'common',
    fallbackLng: 'en', // use en if detected lng is not available
    ns: ['common'],
    fallbackNS: 'common',
    parseMissingKeyHandler: (key) => (IS_DEV ? `missing_translation: ${key}` : key),

    keySeparator: '.', // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })
  .then(() => {
    setLocale(i18next.default.language)
  })

export function translate(key: string, options?: i18next.TOptions) {
  return key ? i18next.t(key, options) : null
}

export default i18next
