import classNames from 'classnames'
import React from 'react'

import { BadgeSelectedItem } from './badge-selected-item'

type SelectedItemsProps<ModelData> = {
  selectedItems: ModelData | ModelData[]
  avatarImage?: keyof ModelData
  avatarIcon?: string
  avatarName?: keyof ModelData
  removeFn: (selected: ModelData) => void
  className?: string
}

export const SelectedItems = <ModelData extends Record<string, any>>(
  props: SelectedItemsProps<ModelData>
) => {
  const { selectedItems, avatarImage, avatarName, removeFn, avatarIcon, className } = props

  if (!Object.entries(selectedItems || {}).length && !selectedItems?.length) return null

  if (!Array.isArray(selectedItems)) {
    return (
      <div className={classNames('member-added w-100', className)}>
        <BadgeSelectedItem
          avatarImage={selectedItems[avatarImage]}
          name={selectedItems[avatarName]}
          avatarIcon={avatarIcon}
          onDelete={() => removeFn(Object.assign({}))}
        />
      </div>
    )
  }

  return (
    <div className={classNames('member-added w-100', className)}>
      {selectedItems.map((item, index) => {
        return (
          <BadgeSelectedItem
            key={index}
            avatarImage={item[avatarImage]}
            avatarIcon={avatarIcon}
            name={item[avatarName]}
            onDelete={() => removeFn(item)}
          />
        )
      })}
    </div>
  )
}
