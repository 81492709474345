import classNames from 'classnames'
import debounce from 'debounce-promise'
import React from 'react'
import { Form } from 'react-bootstrap'
import { useController, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import ReactSelect from 'react-select/async'

import { appleMapsApi } from 'services/apple-maps-api'
import { showStackedToast } from 'stores/toast-store.actions'

import { LocationInputProps } from './location-input.types'

export const ControlledLocationInput = (props: LocationInputProps) => {
  const { label, name, containerClass, placeholder } = props

  const { t } = useTranslation()

  const { control } = useFormContext()

  const {
    field: { onChange, value },
    fieldState: { error, invalid },
  } = useController({ control, name })

  const getAsyncOptions = async (inputValue: string) => {
    try {
      const { data } = await appleMapsApi.autocomplete(inputValue)

      const searchResult: any[] = []

      data.results.forEach(async (item: Record<string, any>) => {
        if (item.location && item.location?.latitude && item.location?.longitude) {
          searchResult.push({
            label: item.displayLines.join(', '),
            value: item.completionUrl,
            name: item.displayLines.join(', '),
            latitude: item.location.latitude,
            longitude: item.location.longitude,
            data: item,
          })
        } else {
          const result = await appleMapsApi.search(item.completionUrl.replace('/v1/search?q=', ''))

          result.data.results.forEach((search: Record<string, any>) => {
            searchResult?.push({
              label: search.name,
              name: search.name,
              value: `${search.coordinate.latitude}, ${search.coordinate.longitude}`,
              latitude: search.coordinate.latitude,
              longitude: search.coordinate.longitude,
              data: search,
            })
          })
        }
      })

      return new Promise((resolve) => resolve(searchResult))
    } catch (error) {
      showStackedToast({
        body: t('Something went wrong, please try again!'),
        variant: 'danger',
        displayTitle: t('Error'),
      })
      return new Promise((resolve) => resolve([]))
    }
  }

  const onOptionSelected = (value: any) => {
    if (value) onChange(value)
    else alert(t('Failed to select address'))
  }

  return (
    <Form.Group className={containerClass}>
      <Form.Label>{label}</Form.Label>
      <ReactSelect
        placeholder={placeholder}
        value={value}
        onChange={onOptionSelected}
        loadOptions={debounce(getAsyncOptions, 500)}
        cacheOptions
        className={classNames('react-select', {
          'is-invalid': invalid,
        })}
        classNamePrefix='react-select'
        id={`${name}-select`}
      />
      {error && (
        <Form.Control.Feedback type='invalid' className='d-block'>
          {error?.message}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  )
}
