import { createPath } from './path-utils'
import { PathGenerator } from './path.types'

const ANALYTIC_PATH = '/analytics'

export const userAnalyticPath: PathGenerator = () => {
  return createPath(`${ANALYTIC_PATH}/users`)
}

export const groupAnalyticPath: PathGenerator = () => {
  return createPath(`${ANALYTIC_PATH}/groups`)
}
