import React, { useEffect } from 'react'
import { useSnapshot } from 'valtio'

import { Loader } from 'components'
import { useYup } from 'hooks/use-yup.hook'
import { useAppConfig } from 'services/swr/use-app-config'
import { appStateStore } from 'stores/app-state-store'
import { setSubdomain } from 'stores/app-state-store.actions'

import AdminRoutes from './admin-routes'
import AppRoutes from './app-routes'

const Routes = () => {
  useAppConfig()

  const { hydrated } = useYup()

  const {
    computed: { isAppSubdomain },
    state: { subdomain },
  } = useSnapshot(appStateStore)

  useEffect(() => {
    if (!window) return

    const { hostname } = window.location
    const [subdomain] = hostname.split('.')

    setSubdomain(subdomain)
  }, [window])

  if (!subdomain) return null

  if (isAppSubdomain) return <AppRoutes />

  if (!hydrated) return <Loader preset='cover-page' />

  return <AdminRoutes />
}

export default Routes
