import { ApiCore } from './api-core'

export class StoryApi extends ApiCore {
  protected multipart = false
  protected pathPrefix = 'stories/group'

  async deleteStoryItem(payload: { groupId: string; storyItemId: string }) {
    return this.delete({
      path: `${payload.groupId}/story-items/${payload.storyItemId}`,
    })
  }

  async deleteStory(payload: { groupId: string; storyId: string }) {
    return this.delete({
      path: `${payload.groupId}/story/${payload.storyId}`,
    })
  }
}

export const storyApi = new StoryApi()
