import snakeCase from 'lodash/snakeCase'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import ReactSelect, { type MultiValue } from 'react-select'
import { useSnapshot } from 'valtio'

import { ActionButton } from 'components/button/action-button'
import { RenderArrowIcon } from 'components/forms/select/render-arrow-icon'
import { authStore } from 'stores/auth-store'

export type DetailCardHeaderProps = {
  goBackPath: any
  statusOptions: MultiValue<Record<string, any>>
  optionValue: string
  editPath: any
  showDeleteModal: (ids?: string | number) => void
  showUpdateModal: (value?: any) => void
}

export const DetailCardHeader = (props: DetailCardHeaderProps) => {
  const { goBackPath, statusOptions, editPath, optionValue, showDeleteModal, showUpdateModal } =
    props

  const { t } = useTranslation()

  const navigate = useNavigate()

  const { id } = useParams()

  const { id: authId } = useSnapshot(authStore.state.user)

  return (
    <Row className='justify-content-between'>
      <Col sm={5} md={6} lg={7}>
        <ActionButton
          variant='outline-gray-light'
          icon='icon-chevron-l'
          className='text-raisin-black d-flex flex-row-reverse'
          label={t('Go Back')}
          onClick={() => navigate(goBackPath)}
        />
      </Col>

      <Col lg={5} md={6} sm={7} className='d-flex gap-2'>
        <ReactSelect
          name='status'
          className='react-select w-100'
          styles={{ control: (props) => ({ ...props, height: '100%' }) }}
          classNamePrefix='react-select'
          value={
            statusOptions.filter((item) => snakeCase(item.value) === snakeCase(optionValue))[0]
          }
          id='details-update-status-action'
          components={{ DropdownIndicator: RenderArrowIcon }}
          options={statusOptions}
          onChange={(e: any) => showUpdateModal(e)}
        />
        {authId !== id && (
          <ActionButton
            variant='outline-gray-light'
            icon='icon-trash'
            className='text-danger button-details'
            onClick={() => showDeleteModal()}
            data-test-id='details-delete-action'
          />
        )}
        <ActionButton
          variant='outline-gray-light'
          icon='icon-edit'
          className='text-raisin-black button-details'
          onClick={() => navigate(editPath)}
          data-test-id='details-edit-action'
        />
      </Col>
    </Row>
  )
}
