import { proxy } from 'valtio'

import { AppConfigStore } from './app-config-store.types'
import { proxyWithPersist } from './helpers/proxy-persist'

export const defaultAppConfigStore: AppConfigStore = {
  config: {
    betaTester: false,
    cloudfrontUrl: '',
    mvpGroupId: '',
    partnerGroupId: '',
    testGroupId: '',
    smsOTP: false,
  },
  supportedVersion: {
    versions: [],
    versionsFormatted: [],
  },
}

const initialState = proxyWithPersist(defaultAppConfigStore, 'app-config-store')

export const appConfigStore = proxy({
  state: initialState,
})
