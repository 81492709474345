import classNames from 'classnames'
import React from 'react'
import { FieldError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type PasswordValidationProps = {
  visible: boolean
  error: FieldError
  rules: Array<{ message: string }>
  invalid: boolean
}

export const PasswordValidation = ({ visible, error, rules, invalid }: PasswordValidationProps) => {
  const { t } = useTranslation()

  if (!rules.length) return null

  return (
    <div
      data-test-id={'password-validation'}
      className={classNames('password-validation border-gray-light border rounded-4 fade', {
        show: visible || error,
        'd-none': !visible && !error,
      })}
    >
      <div className='d-flex flex-column gap-2 p-2'>
        <label className='text-secondary'>{t('Requirements')}</label>

        <ul
          className='list-unstyled d-flex flex-column gap-2'
          data-test-id='password-validation-wrapper'
        >
          {rules.map(({ message }, index) => {
            const errors = error?.types?.matches as Array<string>

            return (
              <li
                key={index}
                data-test-id={`validation-${index}`}
                className='d-flex gap-1 align-items-center justify-content-start'
              >
                <span
                  className={classNames('icon-info-wrapper', {
                    'is-valid': (errors && !errors?.includes(message)) || !invalid,
                  })}
                >
                  <i
                    className={classNames('text-white font-16', {
                      'icon-check': (errors && !errors?.includes(message)) || !invalid,
                      'icon-close': errors && errors?.includes(message),
                    })}
                  />
                </span>
                <span className='font-14'>{message}</span>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}
