import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

import {
  groupAddMemberPath,
  groupEditPath,
  groupMemberPath,
  groupNewPath,
  groupPath,
} from 'config/paths'
import { LoadComponent } from 'routes/load-component'

const groupList = lazy(() => import('./group-list/group-list'))
const groupDetails = lazy(() => import('./group-details/group-details'))
const groupMemberList = lazy(() => import('./group-member-list/group-member-list'))
const groupAddMember = lazy(() => import('./group-add-member/group-add-member'))
const groupForm = lazy(() => import('./group-form/group-form'))

export const groupRoutes = (): RouteObject[] => [
  {
    index: true,
    path: groupPath(),
    element: <LoadComponent component={groupList} />,
  },
  {
    path: groupPath(':id'),
    element: <LoadComponent component={groupDetails} />,
  },
  {
    path: groupNewPath(),
    element: <LoadComponent component={groupForm} />,
  },
  {
    path: groupEditPath(':id'),
    element: <LoadComponent component={groupForm} />,
  },
  {
    path: groupMemberPath(':id'),
    element: <LoadComponent component={groupMemberList} />,
  },
  {
    path: groupAddMemberPath(':id'),
    element: <LoadComponent component={groupAddMember} />,
  },
]
