import React, { ForwardRefRenderFunction, forwardRef, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useSnapshot } from 'valtio'

import { MENU_ITEMS } from 'components/side-bar/constant/menu-constant'
import { useProfile } from 'services/swr/use-profile'
import { authStore } from 'stores/auth-store'

import { generatePageTitle, generatePageTitleIcon } from './generate-page-title'
import ProfileDropdown from './partials/profile-dropdown'
import { SidebarToggle } from './partials/sidebar-toggle'

const TopBar: ForwardRefRenderFunction<HTMLDivElement> = (_props, ref) => {
  const {
    computed: { displayName },
    state: {
      user: { accountType },
    },
  } = useSnapshot(authStore)

  const { id } = useParams()

  const { profile } = useProfile()

  const isKeyIncludedInPath = (key: string) => {
    const pathElements = location.pathname.split('/').filter(Boolean)
    return pathElements.includes(key)
  }

  const activePage = MENU_ITEMS.filter((item) => {
    return isKeyIncludedInPath(item.key)
  })[0]

  const activePageTitle = useMemo(() => {
    if (!activePage) return generatePageTitle(location.pathname.split('/'))

    const menuActive = activePage?.children?.filter((item) => isKeyIncludedInPath(item.key))

    const defaultTitle = !activePage?.children
      ? activePage.label
      : `${menuActive[0].label} ${activePage.label}`

    return generatePageTitle(location.pathname.split('/'), activePage, defaultTitle, id)
  }, [activePage, location.pathname])

  const activeIcon = useMemo(
    () => generatePageTitleIcon(location.pathname.split('/'), activePage?.icon),
    [location.pathname]
  )

  return (
    <div className='navbar-custom' ref={ref}>
      <div className='container-fluid'>
        <ul className='list-unstyled topbar-menu float-end mb-0'>
          <ProfileDropdown
            username={profile?.fullName || displayName}
            userTitle={profile?.accountType || accountType}
          />
        </ul>

        <ul className='list-unstyled topbar-menu float-start mb-0'>
          <li className='dropdown notification-list'>
            <SidebarToggle />
          </li>

          <li className='dropdown notification-list'>
            <div className='d-flex align-items-center justify-content-center h-100'>
              <div className='bg-primary rounded-circle text-white background-icon-title d-flex align-items-center justify-content-center'>
                <i className={activeIcon} />
              </div>

              <h4 className='text-raisin-black fw-semibold ms-2'>{activePageTitle}</h4>
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default forwardRef(TopBar)

TopBar.displayName = 'TopBar'
