import React, { FunctionComponent } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { logoutIcon } from 'assets/images'

import useLogout from './logout.hook'

const Logout: FunctionComponent = () => {
  const { t } = useTranslation('auth')
  const { isAuthenticated } = useLogout()

  if (isAuthenticated) return null

  return (
    <>
      <div className='text-center w-75 m-auto'>
        <h4 className='text-dark-50 text-center mt-0 fw-bold'>{t('See You Again!')}</h4>
        <p className='text-muted mb-4'>{t('You are now successfully sign out.')}</p>

        <div className='logout-icon m-auto'>
          <img src={logoutIcon} alt='' />
        </div>
      </div>

      <Row className='mt-4'>
        <Col className='text-center'>
          <p className='text-muted'>
            {`${t('Back to')} `}
            <Link to={'/account/login'} className='text-muted ms-1'>
              <b>{t('Login')}</b>
            </Link>
          </p>
        </Col>
      </Row>
    </>
  )
}

export default Logout
