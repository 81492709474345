import React, { useEffect, useMemo } from 'react'
import { Card, Col, Form, InputGroup, Row } from 'react-bootstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link, useSearchParams } from 'react-router-dom'

import { BulkAction } from './bulk-action'
import { TableFilter } from './table-filter'
import { getFilterFromSearchParams } from './table.helper'
import { TableHeaderProps } from './table.types'

export const TableHeader = (props: TableHeaderProps) => {
  const { t } = useTranslation('table')

  const {
    newPath,
    newLabel,
    newIcon = 'icon-plus icon-20px icon',
    filters,
    onFilter,
    onAddNew,
    defaultFilters,
    table,
    customHeader,
    searchPlaceholder = t('Search Name, Email, etc'),
    withoutSearch,
    ...otherProps
  } = props

  const hasSelectedRows = table.getIsSomePageRowsSelected() || table.getIsAllPageRowsSelected()
  const activeRowsSelectionCount = table.getSelectedRowModel().rows.length

  const [searchParams] = useSearchParams()

  const methods = useForm({ defaultValues: defaultFilters || {} })
  const { handleSubmit, reset, setValue } = methods

  const onClearFilter = () => {
    reset()
    onFilter({})
  }

  const activeFilterCount = useMemo(
    () =>
      Object.keys(getFilterFromSearchParams(searchParams)).filter((item) => item !== 'search')
        .length,
    [searchParams]
  )

  useEffect(() => {
    const filters = getFilterFromSearchParams(searchParams)

    Object.entries(filters).forEach(([key, value]) => {
      setValue(key, value)
    })
  }, [searchParams])

  return (
    <Card.Header className='p-0 bg-white table-card-header'>
      {customHeader && <Row className='table-custom-header gap-2 gap-lg-0'>{customHeader}</Row>}

      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onFilter)}>
          <Row className='align-items-stretch justify-content-between gap-2 gap-md-0'>
            <Col md={5} lg={6} xl={7} xxl={8} className='table-filter order-2 order-md-1'>
              <Form.Group className='order-1 order-sm-2 d-flex position-relative gap-2'>
                {!withoutSearch && (
                  <InputGroup className='group-float flex-nowrap'>
                    <Form.Control
                      name='search'
                      className='text-text-secondary d-inline-block text-truncate'
                      placeholder={searchPlaceholder}
                      {...methods.register('search')}
                    />

                    <span
                      role='button'
                      onClick={handleSubmit(onFilter)}
                      className='d-flex align-items-center justify-content-center pe-2'
                    >
                      <i className='icon-search' />
                    </span>
                  </InputGroup>
                )}

                {filters && (
                  <TableFilter
                    filters={filters}
                    onClearFilter={onClearFilter}
                    activeFilterCount={activeFilterCount}
                  />
                )}
              </Form.Group>
            </Col>

            <Col md={7} lg={6} xl={5} xxl={4} className='table-new order-1 order-md-2'>
              {!hasSelectedRows && newLabel && (
                <Link
                  to={newPath || '#'}
                  className='btn btn-primary button-action rounded-4 fw-semibold '
                  onClick={onAddNew && handleSubmit(onAddNew)}
                >
                  <span>{newLabel}</span>
                  <i className={`${newIcon} align-middle ms-2`} />
                </Link>
              )}

              <BulkAction
                table={table}
                hasSelectedRows={hasSelectedRows}
                activeRowsSelectionCount={activeRowsSelectionCount}
                {...otherProps}
              />
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </Card.Header>
  )
}
