import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { Card } from 'react-bootstrap'
import { useController, useFormContext } from 'react-hook-form'

import { documentImage } from 'assets/images'
import { ActionButton, FormCardHeader } from 'components'

import { SelectedItems } from './components/selected-items'
import { ModalListItem } from './modal-list-item'
import { useSelectItemCard } from './select-item-card.hook'
import { SelectItemCardProps } from './select-item-card.types'

export const SelectItemCard = <ModelData extends Record<string, any>>(
  props: SelectItemCardProps<ModelData>
) => {
  const {
    name,
    cardDescription,
    cardTitle,
    icon,
    label,
    isMulti = false,
    customDetailCard = null,
    nextFn,
    backFn,
    nextLabel,
    backLabel,
    changeButtonLabel,
    bannerImage = documentImage,
    customFormConfirm,
    mutate,
    withCountLabel,
    ...otherProps
  } = props

  const defaultValue = useMemo(() => (isMulti ? [] : null), [isMulti])

  const [initialSelectedData, setInitialSelectedData] = useState<ModelData | ModelData[]>(
    defaultValue
  )

  const [showAlert, setShowAlert] = useState<boolean>(false)

  const [isCustomFormVisible, setIsCustomFormVisible] = useState<boolean>(false)

  const { control } = useFormContext()

  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({ name, control, defaultValue })

  const { modalVisible, setModalVisible, removeSelectedItem } = useSelectItemCard(
    isMulti,
    initialSelectedData,
    setInitialSelectedData
  )

  const onCancel = () => {
    try {
      setInitialSelectedData(value)
      props.methods?.reset()
    } finally {
      setModalVisible(false)
      setIsCustomFormVisible(false)
    }
  }

  const onConfirm = useCallback(async () => {
    try {
      if (isCustomFormVisible) await customFormConfirm()

      onChange(initialSelectedData)
    } finally {
      !isCustomFormVisible && setModalVisible(false)
    }
  }, [isCustomFormVisible, initialSelectedData])

  useEffect(() => {
    if (!props.methods) return

    const {
      reset,
      formState: { isSubmitSuccessful, isSubmitted },
    } = props.methods

    if (isCustomFormVisible && isSubmitted && isSubmitSuccessful) {
      mutate?.()
      setIsCustomFormVisible(false)
      reset()
    }
  }, [props.methods?.formState, isCustomFormVisible])

  const openModal = () => {
    setInitialSelectedData(value)
    setModalVisible(true)
  }

  const haveValue = useMemo(() => !!value?.length || !!Object.entries(value || {}).length, [value])

  const renderCardBody = () => {
    if (haveValue && customDetailCard) {
      if (!Array.isArray(value)) return customDetailCard.cell(value)

      return value.map((item, index) => (
        <Fragment key={index}>{customDetailCard.cell(item, {})}</Fragment>
      ))
    }

    if (haveValue && !customDetailCard) {
      return (
        <SelectedItems
          selectedItems={value}
          className='mb-0'
          removeFn={(selected) => removeSelectedItem(selected, onChange)}
          {...otherProps}
        />
      )
    }

    return (
      <div className='d-flex align-items-center justify-content-center flex-column gap-3'>
        <img src={bannerImage} alt={label} />

        <div className='d-flex flex-column gap-1 justify-content-center align-items-center'>
          <h5 className='text-raisin-black m-0 text-center text-balance'>{cardTitle}</h5>
          <span className='text-raisin-black text-center text-balance'>{cardDescription}</span>
        </div>

        <ActionButton
          variant='outline-gray-light'
          className='px-2 py-1 rounded-2 text-raisin-black'
          onClick={openModal}
          icon='icon-plus'
          label={label}
          data-test-id={`button-add-${name}-selections`}
        />
      </div>
    )
  }

  return (
    <>
      <Card className='card-group-members' data-test-id={`select-item-card-${name}`}>
        <FormCardHeader
          icon={icon}
          label={label}
          error={error?.message}
          countLabel={withCountLabel && value.length}
          rightAddon={
            haveValue && (
              <ActionButton
                variant='outline-gray-light'
                className='px-2 py-0 rounded-2 text-raisin-black'
                onClick={openModal}
                label={changeButtonLabel}
                data-test-id={`button-change-${name}-selections`}
              />
            )
          }
        />

        <Card.Body className='pb-3 pt-0'>{renderCardBody()}</Card.Body>

        {nextFn && backFn && (
          <Card.Footer className='d-flex justify-content-between border-0 pb-2 rounded-inherit'>
            <ActionButton
              variant='outline-gray-light'
              className='text-raisin-black'
              label={backLabel}
              onClick={backFn}
              data-test-id={`button-back-${name}-selections`}
            />

            <ActionButton
              variant='primary'
              type='button'
              label={nextLabel}
              onClick={nextFn}
              disabled={!haveValue}
              data-test-id={`button-next-${name}-selections`}
            />
          </Card.Footer>
        )}
      </Card>

      <ModalListItem
        icon={icon}
        label={label}
        onCancel={onCancel}
        visible={modalVisible}
        initialData={initialSelectedData}
        setInitialData={setInitialSelectedData}
        onConfirm={onConfirm}
        isMulti={isMulti}
        submitLabel={label}
        isCustomFormVisible={isCustomFormVisible}
        setIsCustomFormVisible={setIsCustomFormVisible}
        showAlert={showAlert}
        setShowAlert={setShowAlert}
        mutate={mutate}
        {...otherProps}
      />
    </>
  )
}
