import classNames from 'classnames'
import React, { InputHTMLAttributes } from 'react'
import { Form } from 'react-bootstrap'
import { Controller, useFormContext } from 'react-hook-form'
import OTPInput, { InputProps, OTPInputProps } from 'react-otp-input'

type ControlledOTPInputProps = InputHTMLAttributes<HTMLInputElement> & Partial<OTPInputProps>

export const ControlledOTPInput = (props: ControlledOTPInputProps) => {
  const { name, onChange, className, ...otherProps } = props

  const { control } = useFormContext()

  const renderInput = (props: InputProps & { isInvalid?: boolean }, index: number) => {
    const { isInvalid, className, ...otherProps } = props as any

    return (
      <Form.Control
        isInvalid={isInvalid}
        name={`${name}.${index}`}
        className={classNames('otp-input', className)}
        {...otherProps}
      />
    )
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { value, onChange: onFormChange },
        fieldState: { error, invalid: isInvalid },
      }) => {
        return (
          <>
            <OTPInput
              inputType='tel'
              value={value}
              renderInput={(props, index) => renderInput({ ...props, isInvalid }, index)}
              onChange={(props) => {
                onFormChange(props)
                onChange?.(props)
              }}
              containerStyle={classNames('mb-3', className)}
              {...otherProps}
            />

            {error && (
              <Form.Control.Feedback type='invalid' className='d-block text-center'>
                {error.message}
              </Form.Control.Feedback>
            )}
          </>
        )
      }}
    />
  )
}
