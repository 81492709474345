import React, { useEffect } from 'react'
import { Navigate, Outlet, useMatch } from 'react-router-dom'

interface RedirectRouteProps {
  route: string
  redirectTo: string
  external?: boolean
}

export const RedirectExternalRoute = ({
  redirectTo,
}: Pick<RedirectRouteProps, 'redirectTo'>): null => {
  useEffect(() => {
    window.location.replace(redirectTo)
  }, [redirectTo])

  return null
}

const RedirectRoute = ({ route, redirectTo, external = false }: RedirectRouteProps) => {
  const match = useMatch(route)

  if (match && external) return <RedirectExternalRoute redirectTo={redirectTo} />

  if (match) return <Navigate to={{ pathname: redirectTo }} replace />

  return <Outlet />
}

export default RedirectRoute
