import useSWR from 'swr'

import { modelAdaptor } from './middleware/model-adaptor'
import { computeEvent } from './models/event.computed'
import { EventModel } from './models/event.types'

export const usePublicEvent = (id: string) => {
  const { isLoading, error, mutate, data } = useSWR<EventModel>(
    id && { path: 'public/events/details', id },
    {
      suspense: false,
      fetcher: async (args) => {
        const path = [args.path, args.id].filter(Boolean).join('/')

        const data = await fetch(new URL(path, process.env.API_BASE_URL).toString())

        return data.json()
      },
      use: [modelAdaptor(computeEvent)],
    }
  )

  return { event: data, isLoading, isError: !!error, mutate, error }
}
