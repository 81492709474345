import {
  PaginationState,
  type RowSelectionState,
  SortingState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import classNames from 'classnames'
import React, { useMemo, useState } from 'react'
import { Card } from 'react-bootstrap'
import { useSearchParams } from 'react-router-dom'

import { findFirstValue } from 'utils/string'

import { CustomTable } from './custom-table'
import { Pagination } from './pagination'
import { TableBody } from './table-body'
import { TableHeader } from './table-header'
import {
  getFilterParams,
  getPaginationFromSearchParams,
  getSortFromSearchParams,
  getSortParams,
} from './table.helper'
import { FilterState, TableProps } from './table.types'

export const Table = <TableValues extends Record<string, any>>(props: TableProps<TableValues>) => {
  const {
    columns,
    data,
    totalPage = 1,
    isLoading,
    isError,
    totalItems,
    defaultLimit,
    customColumns,
    ...headerProps
  } = props

  const [searchParams, setSearchParams] = useSearchParams()
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  const sorting = useMemo(() => getSortFromSearchParams(searchParams), [searchParams])
  const pagination = useMemo(
    () => getPaginationFromSearchParams(searchParams, defaultLimit),
    [searchParams]
  )

  const setFiltering = (filters: FilterState) => {
    const params = getFilterParams(searchParams)

    Object.entries(filters).forEach(([key, value]) => {
      if (key !== 'search' && value) {
        params[`filter[${key}]`] = value
      }
    })

    if (filters.search) params.search = filters.search

    params.page = '1'

    setSearchParams(params)
  }

  const setSorting: any = (updater: (old: SortingState) => SortingState) => {
    const params = getSortParams(searchParams)

    updater(sorting).forEach(({ id, desc }) => {
      params.sort = id
      params.direction = desc ? 'desc' : 'asc'
    })

    setSearchParams(params)
  }

  const setPagination: any = (updater: (old: PaginationState) => PaginationState) => {
    const params = Object.fromEntries(searchParams)
    const { pageIndex, pageSize } = updater(pagination)

    params.page = `${pageIndex + 1}`
    params.perPage = `${pageSize}`

    setSearchParams(params)
  }

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      pagination,
      rowSelection,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row: any) => findFirstValue(row),
    manualSorting: true,
    manualPagination: true,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    pageCount: totalPage,
  })

  const tableBody = useMemo(() => {
    if (customColumns) {
      return (
        <CustomTable
          isError={isError}
          isLoading={isLoading}
          customColumns={customColumns}
          table={table}
          defaultLimit={defaultLimit}
        />
      )
    }

    return (
      <table className='table advanced-table table-bordered mb-0'>
        <thead className='table-light'>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const centered = header.column.columnDef.meta?.centered ?? true
                const hasRowSpan = header.colSpan === 1 && header.depth === 1
                const isPlaceholder = !header.column.parent && header.depth === 2
                const hasColSpan = header.colSpan > 1

                if (isPlaceholder) return null

                return (
                  <th
                    key={header.id}
                    className={classNames('p-0', {
                      'align-middle': hasRowSpan,
                      'text-center': hasColSpan,
                    })}
                    colSpan={header.colSpan}
                    rowSpan={hasRowSpan ? 2 : 1}
                  >
                    <div
                      className={classNames('table-th', {
                        sortable: header.column.getCanSort(),
                        'justify-content-center text-center': centered,
                      })}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}

                      {header.column.getCanSort() && (
                        <i
                          className={classNames('icon icon-24px', {
                            'icon-chevron-vertical': !header.column.getIsSorted(),
                            'icon-double-chevron-u': header.column.getIsSorted() === 'asc',
                            'icon-double-chevron-b': header.column.getIsSorted() === 'desc',
                          })}
                        />
                      )}
                    </div>
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>

        <TableBody
          table={table}
          isLoading={isLoading}
          isError={isError}
          newLabel={headerProps.newLabel}
          newPath={headerProps.newPath}
        />
      </table>
    )
  }, [
    table,
    customColumns,
    isLoading,
    isError,
    headerProps.newLabel,
    headerProps.newPath,
    data,
    rowSelection,
  ])

  return (
    <Card className='shadow rounded-4 card-table'>
      <TableHeader {...headerProps} onFilter={setFiltering} table={table} />
      <Card.Body
        className={classNames('p-0 table-responsive', { 'overflow-x-hidden': !!customColumns })}
      >
        {tableBody}
        <Pagination
          totalItems={totalItems}
          table={table}
          currentPage={pagination.pageIndex + 1}
          perPage={pagination.pageSize}
          totalPage={totalPage}
        />
      </Card.Body>
    </Card>
  )
}
