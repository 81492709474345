import React from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { TableBodyProps, TableErrorProps } from './table.types'

export const TableError = ({ onRetry, table }: TableErrorProps) => {
  const { t } = useTranslation()

  return (
    <tbody className='error'>
      <tr>
        <td colSpan={table.getAllColumns().length}>
          <div className='text-center pt-2 pb-3'>
            <div className='d-flex align-items-center justify-content-center text-danger mb-2'>
              <i className='mdi mdi-alert-rhombus mdi-24px me-1'></i>
              <h4 className='m-0'>{t('Unexpected Error')}</h4>
              <i className='mdi mdi-alert-rhombus mdi-24px ms-1'></i>
            </div>

            <p className='text-muted m-0'>{t('An error occurred while fetching data.')}</p>
            <p className='text-muted mb-2'>{t('Please try again later.')}</p>

            <Button onClick={onRetry} className='rounded-2' variant='primary'>
              <i className='mdi mdi-refresh me-1'></i>
              {t('Retry')}
            </Button>
          </div>
        </td>
      </tr>
    </tbody>
  )
}

export const TableEmpty = ({ table, newPath, newLabel }: TableBodyProps) => {
  const { t } = useTranslation('table')

  return (
    <tbody className='error'>
      <tr>
        <td colSpan={table.getAllColumns().length}>
          <div className='text-center pt-2 pb-3'>
            <div className='d-flex align-items-center justify-content-center text-muted mb-2'>
              <i className='mdi mdi-flask mdi-24px me-1'></i>
              <h4 className='m-0'>{t('No Data Found')}</h4>
              <i className='mdi mdi-flask mdi-24px ms-1'></i>
            </div>

            <p className='text-muted m-0'>{t('Sorry, no data were found.')}</p>

            {newPath && newLabel && (
              <>
                <p className='text-muted mb-2'>{t('You might want to add one now?')}</p>

                <Link
                  to={newPath || '#'}
                  className='btn btn-primary button-action rounded-4 fw-semibold '
                >
                  <span>{newLabel}</span>
                  <i className={'icon icon-plus align-middle ms-2'} />
                </Link>
              </>
            )}
          </div>
        </td>
      </tr>
    </tbody>
  )
}
