import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

import { groupAnalyticPath, userAnalyticPath } from 'config/paths'
import { LoadComponent } from 'routes/load-component'

const userAnalytic = lazy(() => import('./user-analytic/user-analytic'))
const groupAnalytic = lazy(() => import('./group-analytic/group-analytic'))

export const analyticRoutes = (): RouteObject[] => [
  {
    index: true,
    path: userAnalyticPath(),
    element: <LoadComponent component={userAnalytic} />,
  },
  {
    index: true,
    path: groupAnalyticPath(),
    element: <LoadComponent component={groupAnalytic} />,
  },
]
