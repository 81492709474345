import startCase from 'lodash/startCase'

import { ApiCore } from './api-core'
import { Payload } from './api-core.types'

export class BetaTesterApi extends ApiCore {
  protected multipart = false

  async addBetaUser(payload: Payload = {}) {
    return await this.post({
      path: 'beta-tests',
      payload,
    })
  }

  async editBetaTester(id: string | number, payload: Payload = {}) {
    return await this.put({
      path: `beta-tests/${id}`,
      payload,
    })
  }

  async submit(payload: Payload = {}, id?: string | number) {
    payload.type = startCase(payload.type).replace(/ /, '')

    const { ok, data } = id
      ? await this.editBetaTester(id, payload)
      : await this.addBetaUser(payload)

    return ok ? data?.data?.ID : false
  }

  async batchUpdateStatus(payload: Payload = {}) {
    payload.type = startCase(payload.type).replace(/ /, '')

    return await this.put({
      path: 'beta-tests',
      payload,
    })
  }

  async batchDelete(payload: Payload = {}) {
    return await this.delete({
      path: 'beta-tests',
      payload,
    })
  }
}

export const betaTesterApi = new BetaTesterApi()
