import React from 'react'
import { Button, ButtonProps, Spinner, SpinnerProps } from 'react-bootstrap'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type SubmitButtonProps = ButtonProps & {
  loading?: boolean
  loadingProps?: SpinnerProps
  isEditing?: boolean
}

export const SubmitButton = (props: SubmitButtonProps) => {
  const { loading, loadingProps, children, isEditing = false, disabled, ...buttonProps } = props

  const { t } = useTranslation()

  const {
    formState: { isDirty },
  } = useFormContext()

  return (
    <Button
      type='submit'
      variant='primary'
      className='rounded-4 fw-semibold submit-button'
      {...buttonProps}
      disabled={loading || (isEditing && !isDirty) || disabled}
      data-test-id='submit-button'
    >
      {loading && (
        <Spinner
          as='span'
          animation='grow'
          size='sm'
          role='status'
          aria-hidden='true'
          className='me-1'
          {...loadingProps}
        />
      )}

      {loading ? t('Submitting') : children}
    </Button>
  )
}
