import { ApiCore } from './api-core'
import { Payload } from './api-core.types'

export class UserApi extends ApiCore {
  protected multipart = false

  async addUser(payload: Payload = {}) {
    return await this.post({
      path: 'users',
      payload,
    })
  }

  async editUser(id: string | number, payload: Payload = {}) {
    return await this.put({
      path: `users/${id}`,
      payload,
    })
  }

  async submit(payload: Payload = {}, id?: string | number) {
    const { ok, data } = id ? await this.editUser(id, payload) : await this.addUser(payload)

    return ok ? data?.data?.id : false
  }

  async deleteUser(id: string | number) {
    return await this.delete({
      path: `users/${id}`,
    })
  }

  async batchDeleteUser(payload: Payload = {}) {
    return await this.delete({
      path: 'users',
      payload,
    })
  }

  async batchUpdateStatus(payload: Payload = {}) {
    return await this.put({
      path: 'users',
      payload,
    })
  }
}

export const userApi = new UserApi()
