import omit from 'lodash/omit'
import { proxy } from 'valtio'
import { derive } from 'valtio/utils'

import { AppStateStore } from './app-state-store.types'
import { proxyWithPersist } from './helpers/proxy-persist'

export const defaultAppStateStore: AppStateStore = {
  sidebarMode: 'fixed',
  colorScheme: 'light',
  locale: 'en',
  subdomain: '',
  confirmationModal: {
    title: '',
    body: '',
    onConfirm: () => null,
    avatarImage: '',
    variant: 'danger',
    action: 'delete',
    visible: false,
    confirmLabel: '',
  },
}

const initialState = proxyWithPersist(
  omit(defaultAppStateStore, 'confirmationModal'),
  'app-state-store'
)

export const appStateStore = proxy({
  state: initialState,
  confirmationModal: defaultAppStateStore.confirmationModal,
  computed: derive({
    darkModeEnabled: (get) => get(initialState).colorScheme === 'dark',
    isAppSubdomain: (get) => get(initialState).subdomain === (process.env.SUBDOMAIN ?? 'app'),
  }),
})
