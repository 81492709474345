import { ApiCore } from './api-core'
import { Payload } from './api-core.types'

export class AdminApi extends ApiCore {
  protected multipart = false

  async addAdmin(payload: Payload = {}) {
    return await this.post({
      path: 'admins',
      payload,
    })
  }

  async editAdmin(id: string | number, payload: Payload = {}) {
    return await this.put({
      path: `admins/${id}`,
      payload,
    })
  }

  async deleteAdmin(id: string | number) {
    return await this.delete({
      path: `admins/${id}`,
    })
  }

  async submit(payload: Payload = {}, id?: string | number) {
    const { ok, data } = id ? await this.editAdmin(id, payload) : await this.addAdmin(payload)

    return ok ? data.data.id : false
  }

  async batchDeleteAdmin(payload: Payload = {}) {
    return await this.delete({
      path: 'admins',
      payload,
    })
  }

  async batchUpdateStatus(payload: Payload = {}) {
    return await this.put({
      path: 'admins',
      payload,
    })
  }
}

export const adminApi = new AdminApi()
