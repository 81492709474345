import { deleteImage } from 'assets/images'

import { appStateStore, defaultAppStateStore } from './app-state-store'
import { ConfirmationModal, SidebarMode } from './app-state-store.types'

export const toggleSidebarMode = (mode?: SidebarMode) => {
  if (mode) {
    appStateStore.state.sidebarMode = mode
    return
  }

  if (appStateStore.state.sidebarMode === 'condensed') {
    appStateStore.state.sidebarMode = 'fixed'
  } else {
    appStateStore.state.sidebarMode = 'condensed'
  }
}

export const toggleDarkMode = () => {
  if (appStateStore.state.colorScheme === 'dark') {
    appStateStore.state.colorScheme = 'light'
  } else {
    appStateStore.state.colorScheme = 'dark'
  }
}

export const showConfirmationModal = (props: ConfirmationModal) => {
  appStateStore.confirmationModal = { ...defaultAppStateStore.confirmationModal, ...props }
  appStateStore.confirmationModal.visible = true
}

export const showDeleteModal = (props: ConfirmationModal) => {
  showConfirmationModal({ ...props, avatarImage: deleteImage })
}

export const hideConfirmationModal = () => {
  appStateStore.confirmationModal.visible = false
  appStateStore.confirmationModal = defaultAppStateStore.confirmationModal
}

export const setLocale = (locale: string) => {
  appStateStore.state.locale = locale
}

export const setSubdomain = (subdomain: string) => {
  appStateStore.state.subdomain = subdomain
}
