import React from 'react'
import { Nav } from 'react-bootstrap'

type TabProps = {
  tabContents: Array<{
    title: string
    icon?: string
    indicator?: string | number
    onClick?: () => void
    active?: boolean
  }>
}
export const Tab = ({ tabContents }: TabProps) => {
  return (
    <Nav variant='pills' className='tab-wrapper m-0'>
      {tabContents.map((tab, index) => (
        <Nav.Item key={index.toString()} className='text-center overflow-hidden'>
          <Nav.Link
            active={tab.active}
            as='button'
            eventKey={tab.title}
            onClick={tab.onClick}
            className='h-100 w-100 d-flex justify-content-center align-items-center gap-2 rounded-2 tab tab-outline-gray-light'
          >
            {tab.icon && <i className={`icon ${tab.icon}`} />}
            <span className='text-truncate'>{tab.title}</span>

            {(typeof tab?.indicator === 'number' || typeof tab?.indicator === 'string') && (
              <span className='indicator rounded-2'>{tab.indicator}</span>
            )}
          </Nav.Link>
        </Nav.Item>
      ))}
    </Nav>
  )
}
