import React, { Fragment, useMemo } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import SimpleBar from 'simplebar-react'

import { ConfirmAlert } from 'components/alert/alert'
import { ActionButton } from 'components/button/action-button'
import { Loader } from 'components/loader/loader'

import { ButtonItem } from './components/button-item'
import { SelectedItems } from './components/selected-items'
import { useSelectItemCard } from './select-item-card.hook'
import { ModalListContentProps } from './select-item-card.types'

export const ModalListContent = <ModelData extends object>(
  props: ModalListContentProps<ModelData>
) => {
  const { t } = useTranslation()

  const {
    isMulti,
    customListComponent,
    data = [],
    maxSelectedItems,
    initialData,
    setInitialData,
    setSelectedItemToDelete,
    scrollRef,
    selectedItemToDelete,
    setShowAlert,
    setIsCustomFormVisible,
    isCustomFormVisible,
    showAlert,
    customForm,
    columns,
    avatarName,
    setSearch,
    search,
    isLoading,
    deleteFn,
    deleteCancelLabel = t('Cancel'),
    deleteConfirmLabel = t('Delete'),
    deleteTitle,
    deleteDescription,
    mutate,
    editFn,
    searchPlaceholder = t('Search Name, Phone Number'),
    ...otherProps
  } = props

  const { addSelectedItem, removeSelectedItem, setInitialSelectedData, isSelectedItem } =
    useSelectItemCard(isMulti, initialData, setInitialData)

  const hasMaxItems = useMemo(
    () => Array.isArray(initialData) && initialData.length >= maxSelectedItems,
    [maxSelectedItems, initialData]
  )

  const selectItemHandler = (isSelected: boolean, item: ModelData) => {
    if (!isSelected) {
      if (hasMaxItems) return

      return addSelectedItem(item)
    }

    return removeSelectedItem(item, setInitialSelectedData)
  }

  const renderButtonList = (isSelected: boolean, item: ModelData) => (
    <ButtonItem
      isMulti={isMulti}
      isSelected={isSelected}
      onClick={() => selectItemHandler(isSelected, item)}
      label={isSelected ? t('Added') : t('Add')}
      disabled={!isSelected && hasMaxItems}
    />
  )

  const renderListComponent = useMemo(() => {
    if (customListComponent) {
      return (
        <ul className='list-unstyled'>
          {data.map((item, index) => (
            <li key={index} data-test-id='selection-item'>
              {customListComponent.cell(item, {
                buttonComponent: renderButtonList(isSelectedItem(item), item),
                deleteButtonComponent: (
                  <ActionButton
                    variant='outline-gray-light'
                    icon='icon-trash'
                    className='text-danger button-details p-1'
                    data-test-id='details-delete-action'
                    onClick={() => {
                      setSelectedItemToDelete(item)
                      setShowAlert(true)
                    }}
                  />
                ),
                editButtonButton: (
                  <ActionButton
                    variant='outline-gray-light'
                    icon='icon-edit'
                    label={t('Edit')}
                    className='text-raisin-black py-1 px-2 fw-medium'
                    data-test-id='details-edit-action'
                    onClick={() => {
                      editFn(item)
                      setIsCustomFormVisible(true)
                    }}
                  />
                ),
              })}
            </li>
          ))}
        </ul>
      )
    }

    return (
      <table className='w-100 mb-0'>
        <tbody className='w-100 d-table'>
          {data.map((item, index) => (
            <tr
              key={index}
              className='w-100 group-member-item'
              onClick={() => !isMulti && selectItemHandler(isSelectedItem(item), item)}
              data-test-id={'selection-item'}
            >
              {columns.map((column, index) => (
                <td className='p-2 my-2 w-100' key={index}>
                  {column.cell(item)}
                </td>
              ))}
              <td className='d-flex justify-content-end p-2 my-1'>
                {renderButtonList(isSelectedItem(item), item)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  }, [customListComponent, data, columns, isMulti, isSelectedItem])

  if (customForm && isCustomFormVisible) return <Fragment>{customForm}</Fragment>

  return (
    <>
      <SelectedItems
        selectedItems={initialData}
        avatarName={avatarName}
        removeFn={(selected) => removeSelectedItem(selected, setInitialSelectedData)}
        {...otherProps}
      />
      <InputGroup className='group-float mb-2'>
        <Form.Control
          name='search'
          placeholder={searchPlaceholder}
          className='text-text-secondary d-inline-block text-truncate'
          onChange={(search) => setSearch(search.target.value)}
          value={search}
        />

        <span
          aria-label='Search'
          role='button'
          className='d-flex align-items-center justify-content-center px-2'
        >
          <i className='icon icon-20px icon-search' />
        </span>
      </InputGroup>

      <SimpleBar
        ref={scrollRef}
        style={{ maxHeight: 'calc(6 * 78px)', minHeight: 'calc(1.5 * 78px)' }}
      >
        <Fragment>
          {renderListComponent}

          <div className='mt-4'>
            {isLoading && <Loader preset='centered' size='sm' />}

            {}

            {!data.length && !isLoading && (
              <h5 className='text-muted font-16 m-0 pt-3'>{t('Sorry, no data were found.')}</h5>
            )}
          </div>
        </Fragment>
      </SimpleBar>

      <ConfirmAlert
        cancelLabel={deleteCancelLabel}
        confirmLabel={deleteConfirmLabel}
        onConfirm={async () => {
          await deleteFn(selectedItemToDelete)
          setSelectedItemToDelete(null)
          mutate()
        }}
        title={deleteTitle || t('Delete Items', { name: selectedItemToDelete?.[avatarName] })}
        description={deleteDescription}
        variant='danger'
        show={showAlert}
        onCancel={() => {
          setSelectedItemToDelete(null)
          setShowAlert(false)
        }}
      />
    </>
  )
}
