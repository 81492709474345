import classNames from 'classnames'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Form, FormControlProps, InputGroup } from 'react-bootstrap'
import { useController, useFormContext } from 'react-hook-form'
import ReactSelect, { OptionProps, SingleValueProps } from 'react-select'

import { CountryModel } from 'services/swr/models/country.types'
import { useCountryCollection } from 'services/swr/use-country-collection'

import { deepSearchSelect } from '../select/select.helper'

type InputProps = FormControlProps & {
  label: string
  containerClass?: string
}

export const PhoneNumberInput = (props: InputProps) => {
  const { label, containerClass, placeholder, disabled, ...otherProps } = props

  const [isFocused, setIsFocused] = useState<boolean>(false)

  const { control, register } = useFormContext()

  const {
    field: { value: phoneCode, onChange: onPhoneCodeChange },
    fieldState: { error: phoneCodeError, invalid: phoneCodeInvalid },
  } = useController({ control, name: 'countryCode' })

  const {
    field: { onChange: onPhoneNumberChange },
    fieldState: { error: phoneNumberError, invalid: phoneNumberInvalid },
  } = useController({ control, name: 'phoneNumber' })

  const error = useMemo(
    () => phoneCodeError || phoneNumberError,
    [phoneCodeError, phoneNumberError]
  )

  const invalid = useMemo(
    () => phoneCodeInvalid || phoneNumberInvalid,
    [phoneCodeInvalid, phoneNumberInvalid]
  )

  const countries = useCountryCollection()

  const imageUrls = useMemo(
    () =>
      countries.reduce((acc, country) => {
        fetch(country.flagImageURL)
          .then(async (response) => {
            const blob = await response.blob()
            const imageUrl = URL.createObjectURL(blob)

            acc[country.code] = imageUrl
          })
          .catch(() => ({}))

        return acc
      }, Object.assign({})),
    []
  )

  const optionsComponent = (props: OptionProps<CountryModel>) => {
    const { data, innerProps, innerRef } = props

    const imageUrl = imageUrls[data.code] || data.flagImageURL

    return (
      <div
        ref={innerRef}
        className={classNames('react-select__option d-flex gap-2', {
          'react-select__option--is-selected': data.dialCode === phoneCode,
        })}
        {...innerProps}
      >
        <img src={imageUrl} style={{ objectFit: 'contain' }} />

        <label htmlFor={data.code}>
          {data.name} {data.dialCode}
        </label>
      </div>
    )
  }

  const singleValueComponent = useCallback(
    (props: SingleValueProps<CountryModel>) => {
      const { dialCode, flagImageURL } = props.getValue()[0]

      return (
        <div
          className='react-select__single-value'
          style={{ gridArea: props.getStyles('singleValue', props).gridArea as string }}
        >
          <img
            className='border border-body'
            src={flagImageURL}
            style={{ objectFit: 'contain', width: 16 }}
          />
          <label style={{ marginLeft: 2 }}>{dialCode}</label>
        </div>
      )
    },
    [phoneCode]
  )

  useEffect(() => {
    const phoneValue = countries.filter(({ dialCode }) => dialCode === phoneCode)[0]

    if (!phoneValue && !phoneCode) onPhoneCodeChange('+62')
  }, [phoneCode, countries])

  return (
    <Form.Group className={containerClass}>
      <Form.Label className='text-raisin-black fw-normal'>{label}</Form.Label>

      <InputGroup className={invalid && 'is-invalid'}>
        <ReactSelect
          filterOption={deepSearchSelect}
          components={{ Option: optionsComponent, SingleValue: singleValueComponent }}
          options={countries}
          value={countries.filter(({ dialCode }) => dialCode === phoneCode)[0]}
          className={classNames('react-select country-code-select', {
            'is-invalid': invalid,
          })}
          onChange={(e: any) => onPhoneCodeChange(e?.dialCode)}
          classNamePrefix='react-select'
          isDisabled={disabled}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          data-test-id='selectPhoneCode'
        />

        <Form.Control
          as='input'
          className={classNames({ 'border-primary': isFocused })}
          {...otherProps}
          onChange={onPhoneNumberChange}
          isInvalid={invalid}
          placeholder={placeholder}
          disabled={disabled}
          type='tel'
          {...register('phoneNumber')}
          data-test-id={'phoneNumberInput'}
        />
      </InputGroup>

      {error && (
        <Form.Control.Feedback type='invalid' className='d-block'>
          {error.message}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  )
}
