import { ColumnSort, PaginationState, SortingState } from '@tanstack/react-table'
import isEqual from 'lodash/isEqual'
import uniqWith from 'lodash/uniqWith'

export const getFilterFromSearchParams = (
  searchParams: URLSearchParams
): Record<string, string> => {
  const filters = Object.create({})

  for (const [key, value] of searchParams.entries()) {
    if (key.startsWith('filter')) {
      const newKey = key.replace('filter[', '').replace(']', '')
      filters[newKey] = value
    }

    if (key === 'search') filters.search = value
  }

  return filters
}

export const getSortFromSearchParams = (searchParams: URLSearchParams): SortingState => {
  const sorts = []
  const objects: ColumnSort = {
    id: '',
    desc: false,
  }

  for (const [key, value] of searchParams.entries()) {
    if (key.startsWith('sort')) objects.id = value
    if (key.startsWith('direction')) objects.desc = value === 'desc'

    sorts.push(objects)
  }

  return uniqWith(sorts, isEqual)
}

export const getPaginationFromSearchParams = (
  searchParams: URLSearchParams,
  defaultLimit = 10,
  suffix?: string
): PaginationState => {
  const page = Number(searchParams.get(suffix ? `page[${suffix}]` : 'page'))
  const perPage = Number(searchParams.get(suffix ? `perPage[${suffix}]` : 'perPage'))

  return {
    pageIndex: page ? page - 1 : 0,
    pageSize: [10, 20, 30, 40, 50].includes(perPage) ? perPage : defaultLimit,
  }
}

export const getFilterParams = (searchParams: URLSearchParams): Record<string, string> => {
  const filters = Object.create({})

  for (const [key, value] of searchParams.entries()) {
    if (!key.startsWith('filter') && key !== 'search') {
      filters[key] = value
    }
  }

  return filters
}

export const getSortParams = (searchParams: URLSearchParams): Record<string, string> => {
  const sorts = Object.create({})

  for (const [key, value] of searchParams.entries()) {
    if (!key.startsWith('sort') && !key.startsWith('direction')) sorts[key] = value
  }

  return sorts
}
