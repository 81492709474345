import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { ForgotPasswordForm } from './forgot-password-form'
import { ForgotPasswordSuccess } from './forgot-password-success'
import { useForgotPassword } from './forgot-password.hook'

const ForgotPassword = () => {
  const { t } = useTranslation('auth')

  const { email, ...formProps } = useForgotPassword()
  const emailSent = !!email

  return (
    <>
      {emailSent ? <ForgotPasswordSuccess /> : <ForgotPasswordForm {...formProps} />}

      <Row className='mt-3'>
        <Col className='text-center'>
          <p className='text-muted'>
            {t('Back to')}

            <Link to='/account/login' className='text-muted ms-1'>
              <b>{t('Login')}</b>
            </Link>
          </p>
        </Col>
      </Row>
    </>
  )
}

export default ForgotPassword
