import React from 'react'
import { Badge } from 'react-bootstrap'

type RouteBadgeProps = {
  type: string
  distance: string
  duration: string
  startTime?: string
  variant?: 'rounded-pill' | 'rounded-3'
}

export const RouteBadge = (props: RouteBadgeProps) => {
  const { distance, duration, startTime, type, variant = 'rounded-3' } = props

  return (
    <div className='d-flex gap-2 flex-wrap mb-2'>
      <Badge bg='body' className={`font-13 fw-medium text-raisin-black ${variant} py-1 px-2`}>
        {type}
      </Badge>

      <Badge
        bg='body'
        className={`font-13 fw-medium text-raisin-black d-flex gap-2 align-items-center py-1 px-2 ${variant}`}
      >
        <div className='d-flex gap-1 align-items-center'>
          <i className='icon-flag' />
          <span>{distance}</span>
        </div>

        <div className='d-flex gap-1 align-items-center'>
          <i className='icon-clock' />
          <span>{duration}</span>
        </div>
      </Badge>

      {startTime && (
        <Badge bg='body' className={`font-13 text-raisin-black py-1 px-2 ${variant}`}>
          {startTime}
        </Badge>
      )}
    </div>
  )
}
