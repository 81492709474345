import { ApiCore } from './api-core'
import { Payload } from './api-core.types'

export class GroupApi extends ApiCore {
  protected multipart = false
  protected pathPrefix = 'admin'

  async addGroup(payload: Payload = {}) {
    return await this.post({
      path: 'groups',
      payload,
    })
  }

  async editGroup(id: string | number, payload: Payload = {}) {
    return await this.put({
      path: `groups/${id}`,
      payload,
    })
  }

  async submit(payload: Payload = {}, id?: string | number) {
    const { ok, data } = id ? await this.editGroup(id, payload) : await this.addGroup(payload)

    return ok ? data.data.id : false
  }

  async batchDeleteGroup(payload: Payload = {}) {
    return await this.delete({
      path: 'groups',
      payload,
    })
  }

  async batchUpdateStatusGroup(payload: Payload = {}) {
    return await this.put({
      path: 'groups',
      payload,
    })
  }

  async batchRemoveMember(id: string | number, payload: Payload = {}) {
    return await this.delete({
      path: `groups/${id}/members`,
      payload,
    })
  }

  async promoteMember(id: string | number, payload: Payload = {}) {
    return await this.put({
      path: `groups/${id}/members/promote`,
      payload,
    })
  }

  async demoteMember(id: string | number, payload: Payload = {}) {
    return await this.put({
      path: `groups/${id}/members/demote`,
      payload,
    })
  }

  async addMember(payload: Payload = {}, id: string | number) {
    const { ok, data } = await this.post({
      path: `groups/${id}/members`,
      payload,
    })

    return ok ? data.data.id : false
  }
}

export const groupApi = new GroupApi()
