import classNames from 'classnames'
import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { Controller, useController, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import ReactSelect from 'react-select/async'

import { googleMapApi } from 'services/google-maps-api'

import { AddressInputProps } from './address-input.types'

export const ControlledAddressInput = (props: AddressInputProps) => {
  const { label, name, containerClass, placeholder, withTimezone = true } = props

  const { t } = useTranslation()

  const { control } = useFormContext()
  const {
    field: { onChange, value },
    fieldState: { error, invalid },
  } = useController({ control, name })

  const errors: any = error

  const loadOptions = async (inputValue: string) => {
    const { ok, data } = await googleMapApi.autocomplete(inputValue)

    if (ok) {
      const resolvedData = data.predictions.map((item: Record<string, any>) => ({
        label: item.description,
        value: item.placeId,
      }))

      return new Promise((resolve) => resolve(resolvedData))
    }

    return new Promise((resolve) => resolve([]))
  }

  const getAddressDetails = async (placeId: string) => {
    const {
      data: { result, status },
    } = await googleMapApi.getDetails(placeId)

    if (status === 'OK') {
      const {
        addressComponents,
        geometry: {
          location: { lat, lng },
        },
      } = result

      const country = addressComponents.filter((component: any) =>
        component.types.includes('country')
      )

      const region = addressComponents.filter((component: any) =>
        component.types.includes('administrative_area_level_1')
      )

      const city = addressComponents.filter((component: any) =>
        component.types.includes('administrative_area_level_2')
      )

      return {
        lat,
        lon: lng,
        country: country?.[0]?.longName || '',
        countryCode: country?.[0]?.shortName || '',
        region: region?.[0]?.longName || '',
        city: city?.[0]?.longName || '',
      }
    }

    throw new Error()
  }

  const getTimezone = async (lat: string, lon: string) => {
    const {
      data: { timeZoneId, status },
    } = await googleMapApi.getTimezone(`${lat},${lon}`)

    if (status === 'OK') return timeZoneId

    throw new Error()
  }

  const onOptionSelected = async ({ value, label: address }: any) => {
    try {
      const { lat, lon, ...other } = await getAddressDetails(value)
      const timeZone = withTimezone ? await getTimezone(lat, lon) : null

      onChange({ [name]: address, lat, lon, ...other, timeZone })
    } catch (error) {
      alert(t('Failed to select address'))
    }
  }

  return (
    <Form.Group className={containerClass} id={`${name}-input`}>
      <Form.Label>{label}</Form.Label>
      <Controller
        control={control}
        name={name}
        render={() => (
          <Row>
            <Col xs={12}>
              <ReactSelect
                placeholder={placeholder}
                value={value ? { label: value[name] } : null}
                onChange={onOptionSelected}
                loadOptions={loadOptions}
                cacheOptions
                className={classNames('react-select', {
                  'is-invalid': invalid,
                })}
                classNamePrefix='react-select'
                id={`${name}-select`}
              />
              {errors?.[name] && (
                <Form.Control.Feedback type='invalid' className='d-block'>
                  {errors?.[name]?.message}
                </Form.Control.Feedback>
              )}
            </Col>
            <Col lg={4} className='mt-3'>
              <Form.Label>{t('Latitude')}</Form.Label>
              <Form.Control
                disabled
                value={value?.lat || '-'}
                onChange={null}
                data-test-id={`${name}-latitude`}
              />
              {errors?.lat && (
                <Form.Control.Feedback type='invalid' className='d-block'>
                  {errors?.lat?.message}
                </Form.Control.Feedback>
              )}
            </Col>
            <Col lg={4} className='mt-3'>
              <Form.Label>{t('Longitude')}</Form.Label>
              <Form.Control
                disabled
                value={value?.lon || '-'}
                onChange={null}
                data-test-id={`${name}-longitude`}
              />
              {errors?.lon && (
                <Form.Control.Feedback type='invalid' className='d-block'>
                  {errors?.lon?.message}
                </Form.Control.Feedback>
              )}
            </Col>
            <Col lg={4} className='mt-3'>
              <Form.Label>{t('Country')}</Form.Label>
              <Form.Control
                disabled
                value={value?.country || '-'}
                onChange={null}
                data-test-id={`${name}-country`}
              />
              {errors?.country && (
                <Form.Control.Feedback type='invalid' className='d-block'>
                  {errors?.country?.message}
                </Form.Control.Feedback>
              )}
            </Col>
            <Col lg={4} className='mt-3'>
              <Form.Label>{t('Region')}</Form.Label>
              <Form.Control
                disabled
                value={value?.region || '-'}
                onChange={null}
                data-test-id={`${name}-region`}
              />
              {errors?.region && (
                <Form.Control.Feedback type='invalid' className='d-block'>
                  {errors?.region?.message}
                </Form.Control.Feedback>
              )}
            </Col>
            <Col lg={4} className='mt-3'>
              <Form.Label>{t('City')}</Form.Label>
              <Form.Control
                disabled
                value={value?.city || '-'}
                onChange={null}
                data-test-id={`${name}-city`}
              />
              {errors?.city && (
                <Form.Control.Feedback type='invalid' className='d-block'>
                  {errors?.city?.message}
                </Form.Control.Feedback>
              )}
            </Col>
            {withTimezone && (
              <Col lg={4} className='mt-3'>
                <Form.Label>{t('Timezone')}</Form.Label>
                <Form.Control
                  disabled
                  value={value?.timeZone || '-'}
                  onChange={null}
                  data-test-id={`${name}-timezone`}
                />
                {errors?.timeZone && (
                  <Form.Control.Feedback type='invalid' className='d-block'>
                    {errors?.timeZone?.message}
                  </Form.Control.Feedback>
                )}
              </Col>
            )}
          </Row>
        )}
      />
    </Form.Group>
  )
}
