import classNames from 'classnames'
import React from 'react'
import { Form } from 'react-bootstrap'
import { Controller, useFormContext } from 'react-hook-form'
import ReactSelect, { MultiValueProps, SingleValueProps } from 'react-select'

import { RenderArrowIcon } from './render-arrow-icon'
import { convertValuesToString, deepSearchSelect, getValuesFromString } from './select.helper'
import { OptionWithBadgeProps, SelectBadgeProps } from './select.types'

export const ControlledBadgeSelect = (props: SelectBadgeProps) => {
  const { name, label, options, containerClass, placeholder, isMulti = false, onChange } = props

  const { control } = useFormContext()

  const singleValueBadgeComponent = (props: SingleValueProps<OptionWithBadgeProps>) => {
    const { label, variant, icon } = props.data

    return (
      <div
        className='react-select__single-value'
        style={{ gridArea: props.getStyles('singleValue', props).gridArea as string }}
      >
        <span
          className={`d-flex align-items-center justify-content-center custom-badge badge bg-${variant}`}
        >
          <i className={`icon ${icon} icon-18px align-middle`} />
          <span className='fw-medium'>{label}</span>
        </span>
      </div>
    )
  }

  const multiValueBadgeComponent = (props: MultiValueProps<OptionWithBadgeProps>) => {
    const { label, variant, icon } = props.data

    return (
      <span className={`d-flex align-items-center justify-content-center badge bg-${variant}`}>
        <i className={`icon ${icon} icon-18px align-middle`} />
        <span className='ms-1 fw-medium'>{label}</span>

        <div
          role='button'
          className='react-select__multi-value__remove badge-select-remove'
          {...props.removeProps}
        >
          <i
            className='icon icon-close align-middle font-11'
            style={{ lineHeight: '13px', marginTop: '2px' }}
          />
        </div>
      </span>
    )
  }

  return (
    <Form.Group className={containerClass}>
      {label && <Form.Label>{label}</Form.Label>}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange: onFormChange, value }, fieldState: { error, invalid } }) => (
          <>
            <ReactSelect
              filterOption={deepSearchSelect}
              styles={{ valueContainer: (base) => ({ ...base, gap: 6 }) }}
              components={{
                SingleValue: singleValueBadgeComponent,
                MultiValue: multiValueBadgeComponent,
                DropdownIndicator: RenderArrowIcon,
              }}
              isMulti={isMulti}
              options={options}
              placeholder={placeholder}
              value={getValuesFromString<OptionWithBadgeProps>(value, options)}
              className={classNames('react-select', {
                'is-invalid': invalid,
              })}
              classNamePrefix='react-select'
              onChange={(val: any) => {
                onChange && onChange(val)
                onFormChange(isMulti ? convertValuesToString(val) : val.value)
              }}
              id={`${name}-select`}
            />

            {error && (
              <Form.Control.Feedback type='invalid' className='d-block'>
                {error.message}
              </Form.Control.Feedback>
            )}
          </>
        )}
      />
    </Form.Group>
  )
}
