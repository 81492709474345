import { InputHTMLAttributes, ReactElement } from 'react'
import { MultiValue } from 'react-select'

export type OptionProps = {
  value: string
  label: string | ReactElement
}

export type OptionWithBadgeProps = OptionProps & {
  variant: string
  icon: string
}

export type OptionRangeProps = OptionProps & {
  startValue: string
  endValue: string
}

export type OptionsProps = MultiValue<OptionProps>

export type OptionsWithBadgeProps = MultiValue<OptionWithBadgeProps>

export type OptionsRangeProps = MultiValue<OptionRangeProps>

export type SelectProps = InputHTMLAttributes<HTMLInputElement> & {
  options?: OptionsProps
  name: string
  label?: string
  containerClass?: string
  placeholder?: string
  isMulti?: boolean
}

export type SelectBadgeProps = InputHTMLAttributes<HTMLInputElement> &
  Omit<SelectProps, 'options'> & {
    options?: OptionsWithBadgeProps
  }

export type SelectRangeProps = InputHTMLAttributes<HTMLInputElement> &
  Omit<SelectProps, 'options' | 'name'> & {
    options?: OptionsRangeProps
    startRange?: string
    endRange?: string
  }
