import React, { FunctionComponent, LazyExoticComponent, Suspense } from 'react'
import { useSnapshot } from 'valtio'

import { ConfirmationModal, Loader } from 'components'
import { appStateStore } from 'stores/app-state-store'
import { hideConfirmationModal } from 'stores/app-state-store.actions'

type LoadComponentProps = {
  props?: any
  component: LazyExoticComponent<FunctionComponent>
}

export const LoadComponent = ({ component: Component, props }: LoadComponentProps) => {
  const { confirmationModal } = useSnapshot(appStateStore)

  return (
    <Suspense fallback={<Loader preset='cover-page' />}>
      <Component {...props} />

      <ConfirmationModal
        onCancel={() => hideConfirmationModal()}
        testId={`${confirmationModal.action}-confirmation-modal`}
        {...confirmationModal}
      />
    </Suspense>
  )
}
