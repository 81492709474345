import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

import { eventEditPath, eventNewPath, eventPath } from 'config/paths'
import { LoadComponent } from 'routes/load-component'

const eventList = lazy(() => import('./event-list/event-list'))
const eventDetails = lazy(() => import('./event-details/event-details'))
const eventForm = lazy(() => import('./event-form/event-form'))

export const eventRoutes = (): RouteObject[] => [
  {
    index: true,
    path: eventPath(),
    element: <LoadComponent component={eventList} />,
  },
  {
    path: eventPath(':id'),
    element: <LoadComponent component={eventDetails} />,
  },
  {
    path: eventNewPath(),
    element: <LoadComponent component={eventForm} />,
  },
  {
    path: eventEditPath(':id'),
    element: <LoadComponent component={eventForm} />,
  },
]
