import React from 'react'
import { Form, FormControlProps } from 'react-bootstrap'
import { useController, useFormContext } from 'react-hook-form'

type InputProps = FormControlProps & {
  label: string
  name: string
  containerClass?: string
  rows?: number
}

export const ControlledTextArea = (props: InputProps) => {
  const { label, name, containerClass, children, ...otherProps } = props

  const { register, control } = useFormContext()
  const {
    fieldState: { invalid, error },
  } = useController({ control, name })

  return (
    <Form.Group className={containerClass}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        name={name}
        id={name}
        as='textarea'
        isInvalid={invalid}
        {...register(name)}
        {...otherProps}
        autoComplete={name}
        data-test-id={name}
      >
        {children}
      </Form.Control>

      {error && <Form.Control.Feedback type='invalid'>{error.message}</Form.Control.Feedback>}
    </Form.Group>
  )
}
