import React, { useEffect } from 'react'
import { type RouteObject, useNavigate } from 'react-router-dom'

import PageNotFound from 'pages/auth/errors/page-not-found'
import ServerError from 'pages/auth/errors/server-error'

const NavigateNotFoundPage = (): null => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate('/404', { replace: true })
  }, [])

  return null
}

const ErrorRoutes: RouteObject[] = [
  { path: '404', element: <PageNotFound /> },
  { path: '500', element: <ServerError /> },
  { path: '*', element: <NavigateNotFoundPage /> },
]

export const ErrorAppRoutes: RouteObject[] = [
  { path: '404', element: <PageNotFound isAppSubdomain /> },
  { path: '500', element: <ServerError /> },
  { path: '*', element: <PageNotFound isAppSubdomain /> },
]

export default ErrorRoutes
