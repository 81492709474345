import { RefObject, useEffect } from 'react'

type ClickOutsideProps = {
  targetRef: RefObject<HTMLDivElement>
  action: () => void
}

export function useClickOutside(props: ClickOutsideProps) {
  const { action, targetRef } = props

  useEffect(() => {
    const handleClickOutside = ({ target }: MouseEvent) => {
      const targetElement = targetRef.current

      const isOutsideMenu = !targetElement?.contains(target as Node)

      if (isOutsideMenu) return action()
    }

    window.addEventListener('click', handleClickOutside)

    return () => window.removeEventListener('click', handleClickOutside)
  }, [targetRef])
}
