import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { deleteImage } from 'assets/images'
import { useToggle } from 'hooks'
import { showConfirmationModal } from 'stores/app-state-store.actions'

import { TableActionButton } from './table-action-button'
import { TableActionButtonProps, TableActionsProps } from './table.types'

export const TableActionsHeader = () => {
  const { t } = useTranslation()

  return <div className='text-center'>{t('Actions')}</div>
}

export const TableActions = (props: TableActionsProps) => {
  const { t } = useTranslation()

  const {
    id,
    path,
    actions = ['delete', 'edit', 'view'],
    customActions = [],
    deleteConfirmationBody,
    deleteConfirmationTitle,
    confirmLabel,
    onDelete,
  } = props

  const actionsComponent: TableActionButtonProps[] = [
    {
      icon: 'icon-eye-open',
      to: `${path}/${id}`,
      label: t('Details'),
      visible: actions.includes('view'),
    },
    {
      icon: 'icon-edit',
      to: `${path}/edit/${id}`,
      label: t('Edit'),
      visible: actions.includes('edit'),
    },
    {
      icon: 'icon-trash',
      label: t('Delete'),
      onClick: () =>
        showConfirmationModal({
          title: deleteConfirmationTitle,
          body: deleteConfirmationBody,
          onConfirm: () => onDelete(id),
          confirmLabel,
          avatarImage: deleteImage,
        }),
      visible: actions.includes('delete'),
    },
    ...customActions,
  ]

  const [isOpen, toggleDropdown] = useToggle()

  return (
    <>
      <Dropdown
        show={isOpen}
        onToggle={toggleDropdown}
        className='d-flex align-items-center justify-content-center'
      >
        <Dropdown.Toggle
          variant='link'
          id='table-action-toggle'
          as='button'
          onClick={toggleDropdown}
          className='arrow-none table-action-dropdown'
          role='button'
          aria-label={t('Dropdown Action')}
        >
          <i className='icon icon-more icon-18px' />
        </Dropdown.Toggle>
        <Dropdown.Menu align='end' className='dropdown-menu-animated'>
          {actionsComponent.map((actionsProps, index) => (
            <TableActionButton key={index} {...actionsProps} />
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}
