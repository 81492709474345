import { SWRHook } from 'swr'
import { proxy, snapshot } from 'valtio'

import { ModelAdaptor } from './model-adaptor.types'

export const modelAdaptor: ModelAdaptor = (computeFn, keyName = 'data') => {
  return (useSWRNext: SWRHook) => {
    return (key, fetcher, config) => {
      const swr = useSWRNext<any>(key, fetcher, config)

      if (computeFn && swr.data) {
        const state = proxy(swr.data[keyName])
        const computed = computeFn(state)
        const data = { ...snapshot(state), ...snapshot(computed) }

        return Object.assign({}, swr, { data })
      }

      if (swr.data) {
        return Object.assign({}, swr, { data: swr.data[keyName] })
      }

      return swr
    }
  }
}
