import dayjs, { extend } from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'

extend(advancedFormat)

type FormatDate =
  | 'with-time-ampm'
  | 'time-only'
  | 'time-ampm'
  | 'date-month-abbreviation'
  | 'ordinal'
  | string

export const formatDate = (date: string, format?: FormatDate): string => {
  switch (format) {
    case 'with-time-ampm':
      return dayjs(date).format('D MMM YYYY, hh:mm A')
    case 'time-only':
      return dayjs(date).format('hh:mm A')
    case 'time-ampm':
      return dayjs(date).format('hh:mm A')
    case 'date-month-abbreviation':
      return dayjs(date).format('D MMM YYYY')
    case 'ordinal':
      return dayjs(date).format('Do MMM, YYYY')
    default:
      return dayjs(date).format(format || 'D MMM, YYYY')
  }
}

export const convertHourToMinute = (minutes: number) => {
  return String(minutes * 60)
}

export const formatDistance = (distanceInMeters: number): string => {
  return `${Number((distanceInMeters / 1000).toFixed(1))} km`
}

export const formatElevation = (distanceInMeters: number): string => {
  return `${Number(distanceInMeters.toFixed(0))} m`
}

export const formatDuration = (durationInMinutes: number, short = false): string => {
  const hourUnit = short ? 'h' : 'hr'
  const minuteUnit = 'min'

  return durationInMinutes < 60
    ? `${durationInMinutes} ${minuteUnit}`
    : `${Math.round(durationInMinutes / 60)} ${hourUnit}`
}
