import React from 'react'
import { Col } from 'react-bootstrap'

import { Badge } from 'components/badge/badge'

import { DetailItemProps } from './detail-item.types'

export const BaseDetailItem = (props: DetailItemProps) => {
  const {
    label,
    whenEmpty = 'hide',
    children,
    withBadge = false,
    badgeLabel,
    badgeOutline,
    badgeTextColor,
    badgeVariant = 'white',
    ...colProps
  } = props

  if (!children && whenEmpty === 'hide') return null

  return (
    <Col xs={12} className='mt-1 mb-1' {...colProps}>
      <span className='mt-0 mb-1 d-block w-100 text-secondary'>{label}</span>
      <div className='d-flex gap-1 align-items-center flex-wrap'>
        {children || <span className='fw-semibold'>-</span>}

        {withBadge && (
          <Badge
            className='font-12 px-1'
            label={badgeLabel}
            bg={badgeVariant}
            outline={badgeOutline}
            textColor={badgeTextColor}
          />
        )}
      </div>
    </Col>
  )
}
