import { createPath } from './path-utils'
import { PathGenerator, PathGeneratorWithId, PathGeneratorWithParams } from './path.types'

const BETA_TESTER = '/accounts/beta-testers'

export const betaTesterPath: PathGeneratorWithParams = (params) => {
  return createPath(BETA_TESTER, params)
}

export const betaTesterNewPath: PathGenerator = () => {
  return createPath(BETA_TESTER, { suffix: 'new' })
}

export const betaTesterEditPath: PathGeneratorWithId = (id) => {
  return createPath(`${BETA_TESTER}/edit`, id)
}
