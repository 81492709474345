import { useTranslation } from 'react-i18next'

import { cleanStackedToast, showBasicToast, showStackedToast } from 'stores/toast-store.actions'

type ToastOptions = {
  title?: string
  body?: string
  variant?: 'success' | 'danger' | 'warning' | 'info'
  type?: 'basic' | 'stacked'
}

export const useToast = () => {
  const { t } = useTranslation()

  const showToast = async ({ type = 'stacked', ...toast }: ToastOptions) => {
    if (type === 'basic') return showBasicToast(toast)

    showStackedToast(toast)
    cleanStackedToast()
  }

  const showUnexpectedToast = async (type = 'stacked') => {
    const toast: ToastOptions = {
      variant: 'danger',
      title: t('Unexpected Error!'),
      body: t('An unexpected error occurred, please try again later.'),
    }
    if (type === 'basic') return showBasicToast(toast)

    showStackedToast(toast)
    cleanStackedToast()
  }

  return { showToast, showUnexpectedToast }
}
