import { derive } from 'valtio/utils'

import { adminPath, userPath } from 'config/paths'
import { remoteImage } from 'utils/remote-image'

import { MemberModel } from './member.types'

export const computeMember = (state: MemberModel) =>
  derive({
    path: (get) => {
      if (!get(state).firstName || !get(state).lastName) return '#'

      switch (get(state).accountType.toLowerCase()) {
        case 'user':
          return userPath(get(state).id)
        case 'admin':
        default:
          return adminPath(get(state).id)
      }
    },
    photoProfile: (get) => remoteImage(get(state).photoProfileURL || get(state).photoProfile),
    fullName: (get) =>
      get(state).firstName && get(state).lastName
        ? `${get(state).firstName} ${get(state).lastName}`
        : '-',
    phoneNumberWithCountryCode: (get) => `${get(state).countryCode}${get(state).phoneNumber}`,
    roleColor: (get) => {
      switch (get(state)?.groupRole?.toLowerCase()) {
        case 'admin':
          return 'primary-dark'
        case 'creator':
          return 'gold-dark'
        default:
          return ''
      }
    },
  })
