import { exportToBase64 } from './convert-to-base64'
import { FileType } from './file-uploader.types'

export default function useFileUploader(
  multiSelect = false,
  defaultValues?: FileType[],
  onFileUpload?: (files?: FileType[]) => void
) {
  /**
   * Handled the accepted files and shows the preview
   */
  const handleAcceptedFiles = async (files: FileType[]) => {
    const allFiles: FileType[] = []

    files.map((file) =>
      Object.assign(file, {
        preview: file.type.split('/')[0] === 'image' ? URL.createObjectURL(file) : null,
        formattedSize: formatBytes(file.size),
      })
    )

    const formattedFiles = await exportToBase64(files)

    if (multiSelect) {
      if (Array.isArray(defaultValues)) allFiles.push(...defaultValues)
      allFiles.push(...formattedFiles)
    } else {
      allFiles.push(...formattedFiles)
    }

    if (onFileUpload) onFileUpload(allFiles)
  }

  /**
   * Formats the size
   */
  const formatBytes = (bytes: number, decimals = 2) => {
    if (bytes === 0) return '0 Bytes'
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  }

  /*
   * Removes the selected file
   */
  const removeFile = (values: FileType[], fileIndex: number) => {
    const filteredFiles = values.filter((_file, index) => index !== fileIndex)

    if (!filteredFiles.length) return onFileUpload([])

    onFileUpload(filteredFiles)
  }

  return {
    handleAcceptedFiles,
    removeFile,
  }
}
