import { createPath } from './path-utils'
import { PathGenerator, PathGeneratorWithId, PathGeneratorWithParams } from './path.types'

const ADMIN_PATH = '/accounts/admins'

export const adminEditPath: PathGeneratorWithParams = (params) => {
  return createPath(`${ADMIN_PATH}/edit`, params)
}

export const adminPath: PathGeneratorWithId = (id) => {
  return createPath(ADMIN_PATH, id)
}

export const adminNewPath: PathGenerator = () => {
  return createPath(ADMIN_PATH, { suffix: 'new' })
}
