import { derive } from 'valtio/utils'

import { remoteImage } from 'utils/remote-image'

import { UserModel } from './user.types'

export const computeUser = (state: UserModel) =>
  derive({
    fullName: (get) => `${get(state).firstName} ${get(state).lastName}`,
    statusIcon: (get) => {
      switch (get(state)?.userStatus?.toLowerCase()) {
        case 'active':
          return 'icon icon-check icon-18px'
        case 'inactive':
          return 'icon icon-close icon-18px'
        case 'banned':
          return 'icon icon-block icon-18px'
        default:
          return ''
      }
    },
    photoProfileURL: (get) => remoteImage(get(state).photoProfile),
    phoneNumberWithCountryCode: (get) => get(state).countryCode + get(state).phoneNumber,
    statusColor: (get) => {
      switch (get(state)?.userStatus?.toLowerCase()) {
        case 'active':
          return 'primary-light'
        case 'inactive':
          return 'secondary'
        case 'banned':
          return 'danger-light'
        default:
          return ''
      }
    },
  })
