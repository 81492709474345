import useSWR from 'swr'
import { useSnapshot } from 'valtio'
import { derive } from 'valtio/utils'

import { appConfigStore } from 'stores/app-config-store'
import { AppConfigStore } from 'stores/app-config-store.types'
import { appStateStore } from 'stores/app-state-store'
import reset from 'stores/helpers/reset'

import { twelveHours } from './helpers/millisecond-intervals'
import { modelAdaptor } from './middleware/model-adaptor'

const computeAppConfig = (initialState: AppConfigStore) =>
  derive({
    supportedVersion: (get) => {
      const getVersionsFormatted = () => {
        if (
          !get(initialState).supportedVersion.versions ||
          !get(initialState).supportedVersion.versions.length ||
          !get(initialState).supportedVersion.versions[0]
        ) {
          return []
        }

        return get(initialState).supportedVersion.versions.map((item) => {
          const appVersion = item?.split('(')[0]
          const buildVersion = item?.split('(')[1]?.replace(')', '')

          return {
            appVersion,
            buildVersion,
          }
        })
      }
      return {
        ...get(initialState).supportedVersion,
        versionsFormatted: getVersionsFormatted(),
      }
    },
  })

export const useAppConfig = () => {
  const { isAppSubdomain } = useSnapshot(appStateStore?.computed)

  const { isLoading, error, mutate, data } = useSWR<AppConfigStore>(
    !isAppSubdomain && { path: 'app-configs' },
    {
      dedupingInterval: twelveHours,
      use: [modelAdaptor(computeAppConfig)],
      suspense: location.pathname.split('/').includes('feature-flags'),
    }
  )

  if (data && !error) reset(appConfigStore.state, data)

  return { appConfig: data, isLoading, isError: !!error, mutate }
}
