import { ApiOkResponse } from 'apisauce'

import { authApi } from 'services/api'

import { authStore } from './auth-store'

export const setAuthenticatedUser = (result: ApiOkResponse<any>) => {
  const { headers, data } = result

  authStore.state.user = data.data
  authStore.state.token = headers.authorization
  authStore.state.tokenExpiry = headers['expired-at']
  authStore.state.refreshToken = headers['refresh-token']
  authStore.state.refreshTokenExpiry = headers['refresh-token-expired']
  authStore.state.mapsAuthToken = data.data.mapsAuthToken
}

export const verifyOTP = async (payload = {}) => {
  const result = await authApi.verifyOTP(payload)

  if (result.ok) setAuthenticatedUser(result)

  return result.ok
}
