import classNames from 'classnames'
import React, { useMemo } from 'react'
import { Form } from 'react-bootstrap'
import { useController, useFormContext } from 'react-hook-form'
import ReactSelect from 'react-select'

import { RenderArrowIcon } from './render-arrow-icon'
import { getValuesRangeFromString } from './select.helper'
import { OptionRangeProps, SelectRangeProps } from './select.types'

export const ControlledRangeSelect = (props: SelectRangeProps) => {
  const {
    startRange,
    endRange,
    label,
    options,
    containerClass,
    placeholder,
    isMulti = false,
    onChange,
  } = props

  const { control } = useFormContext()

  const {
    field: { onChange: onStartRangeChange, value: startRangeValue },
    fieldState: { invalid: startInvalid, error: startError },
  } = useController({ control, name: startRange })

  const {
    field: { onChange: onEndRangeChange, value: endRangeValue },
    fieldState: { invalid: endInvalid, error: endError },
  } = useController({ control, name: endRange })

  const error = useMemo(() => endError || startError, [endError, startError])
  const invalid = useMemo(() => endInvalid || startInvalid, [endInvalid, startInvalid])

  return (
    <Form.Group className={containerClass}>
      {label && <Form.Label>{label}</Form.Label>}

      <ReactSelect
        isMulti={isMulti}
        components={{ DropdownIndicator: RenderArrowIcon }}
        options={options}
        placeholder={placeholder}
        value={getValuesRangeFromString<OptionRangeProps>(startRangeValue, endRangeValue, options)}
        onChange={(val: any) => {
          onChange && onChange(val)
          onStartRangeChange(val.startValue)
          onEndRangeChange(val.endValue)
        }}
        className={classNames('react-select', {
          'is-invalid': invalid,
        })}
        classNamePrefix='react-select'
        id={`${startRange}-${endRange}-select`}
      />

      {error && (
        <Form.Control.Feedback type='invalid' className='d-block'>
          {error.message}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  )
}
