import { ApiCore } from './api-core'
import { Payload } from './api-core.types'

export class SWRApi extends ApiCore {
  async fetch(path: string, params?: Payload) {
    return await this.get({ path, payload: params })
  }
}

export const swrApi = new SWRApi()
