import { createPath } from './path-utils'
import { PathGenerator, PathGeneratorWithId, PathGeneratorWithParams } from './path.types'

const EVENT_PATH = '/events'

export const eventPath: PathGeneratorWithParams = (params) => {
  return createPath(EVENT_PATH, params)
}

export const eventNewPath: PathGenerator = () => {
  return createPath(EVENT_PATH, { suffix: 'new' })
}

export const eventEditPath: PathGeneratorWithId = (id) => {
  return createPath(`${EVENT_PATH}/edit`, id)
}
