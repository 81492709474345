import { ApiCore } from './api-core'
import { Payload } from './api-core.types'

export class EventApi extends ApiCore {
  protected multipart = false
  protected pathPrefix = 'admin'

  async addEvent(payload: Payload = {}) {
    return await this.post({
      path: 'events',
      payload,
    })
  }

  async editEvent(id: string | number, payload: Payload = {}) {
    return await this.put({
      path: 'events',
      payload: { eventId: id, ...payload },
    })
  }

  async submit(payload: Payload = {}, id?: string | number) {
    const { ok, data } = id ? await this.editEvent(id, payload) : await this.addEvent(payload)

    return ok ? data.data.id : false
  }

  async batchDeleteEvent(payload: Payload = {}) {
    return await this.delete({
      path: 'events',
      payload,
    })
  }

  async batchUpdateStatusEvent(payload: Payload = {}) {
    return await this.put({
      path: 'events/batch',
      payload,
    })
  }
}

export const eventApi = new EventApi()
