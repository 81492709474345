import dayjs from 'dayjs'
import React, { useEffect, useMemo, useState } from 'react'

import { eventBackground, logoColorSm } from 'assets/images'
import { usePublicEvent } from 'services/swr/use-public-event'

export const SingleGrid = ({ id }: { id: string }) => {
  const { event } = usePublicEvent(id)
  const [loadedImages, setLoadedImages] = useState<boolean[]>([false, false])
  const [backgroundImageLoaded, setBackgroundImageLoaded] = useState(false)

  const route = useMemo(() => {
    if (!event) return null

    if (event.routes.length === 1) return event.routes[0]

    if (!event?.chosenRoute) {
      const routes = event.routes.map((r) => ({
        ...r,
        unixTime: dayjs()
          .hour(dayjs(r.startTime).hour())
          .minute(dayjs(r.startTime).minute())
          .second(dayjs(r.startTime).second())
          .unix(),
      }))
      const routesSortedByTime = routes.sort((a, b) => a.unixTime - b.unixTime)
      return routesSortedByTime[0]
    }

    return event.routes.find((r) => r.id === event.chosenRoute.chosenRouteId)
  }, [event])

  const allImagesLoaded = useMemo(() => loadedImages.every(Boolean), [loadedImages])

  const handleImageLoad = (index: number) => {
    setLoadedImages((prev) => {
      const updated = [...prev]
      updated[index] = true
      return updated
    })
  }

  useEffect(() => {
    const img = new Image()
    img.src = eventBackground
    img.onload = () => setBackgroundImageLoaded(true)
  }, [])

  return (
    <div
      className='share-event-container'
      id={allImagesLoaded && backgroundImageLoaded ? 'event-share-ready' : 'event-share'}
    >
      <div className='card-container'>
        <img className='route-img' src={route.routeMapURL} onLoad={() => handleImageLoad(0)} />

        <div className='event-details'>
          <img className='logo-image' src={logoColorSm} onLoad={() => handleImageLoad(1)} />

          <p className='event-details-text'>{route.name}</p>
        </div>
      </div>
    </div>
  )
}
