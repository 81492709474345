{
  "Event Name": "Event Name",
  "Add Group": "Add Group",
  "You must select a group": "You must select a group",
  "You’ll change the account status by clicking the save changes button": "You’ll change the account status by clicking the save changes button",
  "Search Name, ID, etc.": "Search Name, ID, etc.",
  "Change Route": "Change Route",
  "Add Route": "Add Route",
  "Select a route": "Select a route",
  "You can only choose one or a maximum of two routes in one event.": "You can only choose one or a maximum of two routes in one event.",
  "Create Event": "Create Event",
  "Select an user as event organizer": "Select an user as event organizer",
  "Add Organizer": "Add Organizer",
  "Event Date": "Event Date",
  "Event Message": "Event Message",
  "Change Organizer": "Change Organizer",
  "Add Details": "Add Details",
  "Route Name": "Route Name",
  "Your route name": "Your route name",
  "Activity Type": "Activity Type",
  "Select Activity": "Select Activity",
  "Distance": "Distance",
  "Estimate Distance": "Estimate Distance",
  "Duration": "Duration",
  "Estimate Duration": "Estimate Duration",
  "Start Time": "Start Time",
  "Select Start Time": "Select Start Time",
  "Route": "Route",
  "Attendance": "Attendance",
  "Change Group": "Change Group",
  "Organizer": "Organizer",
  "Add New Route": "Add New Route",
  "Location": "Location",
  "route_timeline": [
    {
      "name": "startLocation",
      "label": "Start Location",
      "placeholder": "Your Start Location"
    },
    {
      "name": "coffeeStop",
      "label": "Coffee Stop",
      "placeholder": "Your Coffee Stop"
    },
    {
      "name": "finishLocation",
      "label": "Finish Location",
      "placeholder": "Your Finish Location"
    }
  ],
  "creator_in_group": "<0>{{ creatorName }}</0>\u00A0 in \u00A0<1>{{ groupName }}</1>",
  "Event Status": "Event Status",
  "Select an event status": "Select an event status",
  "Event is successfully update to": "Event is successfully update to {{ status }}",
  "Are you sure want to update this event to": "Are you sure want to update this event to {{ status }}?",
  "You’ll update the event to by click the update button": "You’ll update the event to {{ status }} by click the update button",
  "Failed to update event to": "Failed to update event to {{ status }}",
  "Failed to delete event": "Failed to delete event",
  "Event is successfully deleted": "Event is successfully deleted",
  "Are you sure want to delete this event?": "Are you sure want to delete this event?",
  "You’ll delete the event permanently by clicking the delete button": "You’ll delete the event permanently by clicking the delete button",
  "Delete Event": "Delete Event",
  "You’ll delete the route permanently": "You’ll delete the route permanently",
  "Failed to delete route": "Failed to delete route",
  "Route is successfully deleted": "Route is successfully deleted",
  "Search route": "Search Route",
  "Road Ride": "Road ride",
  "Gravel Ride": "Gravel ride",
  "MTB Ride": "MTB ride",
  "Run": "Run",
  "Trail Run": "Trail Run",
  "Walk": "Walk",
  "Snowboard": "Snowboard",
  "Ski": "Ski",
  "Swim": "Swim",
  "Other": "Other "
}
