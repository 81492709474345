import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

import { storyPath } from 'config/paths'
import { LoadComponent } from 'routes/load-component'

const storyList = lazy(() => import('./story-list/story-list'))

export const storyRoutes = (): RouteObject[] => [
  {
    index: true,
    path: storyPath(),
    element: <LoadComponent component={storyList} />,
  },
]
