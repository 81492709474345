import classNames from 'classnames'
import React from 'react'
import { Card, Form } from 'react-bootstrap'

type FormCardHeaderProps = {
  icon: string
  label: string
  rightAddon?: React.ReactNode
  error?: string
  className?: string
  withoutCard?: boolean
  containerClass?: string
  labelClass?: string
  description?: string
  countLabel?: number
}

export const FormCardHeader = (props: FormCardHeaderProps) => {
  const {
    icon,
    label,
    rightAddon,
    error,
    className,
    withoutCard = false,
    containerClass,
    labelClass = 'align-items-center',
    description,
    countLabel,
  } = props

  const header = (
    <div className={classNames('d-flex justify-content-between', containerClass)}>
      <div className={classNames('d-flex gap-2 h-100', labelClass)}>
        <div className='bg-body rounded-circle text-raisin-black background-icon-title d-flex align-items-center justify-content-center'>
          <i className={`icon ${icon}`} />
        </div>

        <div className={classNames('gap-1 align-items-center', { 'd-flex': countLabel })}>
          <h5 className='text-raisin-black fw-semibold my-0 text-nowrap'>{label}</h5>
          {description && <label className='text-muted'>{description}</label>}
          {countLabel && <span className='text-secondary fw-semibold font-16'>{countLabel}</span>}
        </div>
        {error && (
          <Form.Control.Feedback type='invalid' className='d-block'>
            {`* ${error}`}
          </Form.Control.Feedback>
        )}
      </div>

      {rightAddon}
    </div>
  )

  if (withoutCard) return header

  return <Card.Header className={classNames('border-0', className)}>{header}</Card.Header>
}
