import React, { useMemo } from 'react'
import { Button, Card, Col, Placeholder, Row, RowProps } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { useSnapshot } from 'valtio'

import { useViewport } from 'hooks'
import { appStateStore } from 'stores/app-state-store'

import { CustomTableProps } from './table.types'

export const CustomTable = (props: CustomTableProps) => {
  const { table, customColumns, isError, isLoading, defaultLimit } = props

  const { t } = useTranslation('table')

  const [, setSearchParams] = useSearchParams()

  const { sidebarMode } = useSnapshot(appStateStore.state)

  const { width } = useViewport()

  const isMobile = sidebarMode === 'condensed'

  const rowProps: RowProps = useMemo(() => {
    return {
      xxl: isMobile || width > 1660 ? 5 : 4,
      xl: isMobile ? 4 : 3,
      lg: isMobile ? 3 : 2,
      sm: isMobile ? 2 : 1,
      xs: 1,
    }
  }, [width, isMobile])

  if (isLoading) {
    return (
      <Row {...rowProps}>
        {Array(defaultLimit)
          .fill('row')
          .map((_, index) => (
            <Col key={index}>
              <Card className='story-card skeleton'>
                <Card.Body className='d-flex flex-column'>
                  <div className='header-skeleton' />

                  <div className='d-flex flex-column gap-1 mt-2'>
                    <Placeholder as='div' animation='wave'>
                      <Placeholder size='lg' bg='secondary' className='w-100' />
                    </Placeholder>

                    <Placeholder as='div' animation='wave'>
                      <Placeholder size='lg' bg='secondary' className='w-100' />
                    </Placeholder>

                    <div className='d-flex gap-3 mt-1'>
                      <div className='w-25'>
                        <Placeholder as='div' animation='wave'>
                          <Placeholder size='lg' bg='secondary' className='w-100' />
                        </Placeholder>
                      </div>

                      <div className='w-75'>
                        <Placeholder as='div' animation='wave'>
                          <Placeholder size='lg' bg='secondary' className='w-100' />
                        </Placeholder>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
      </Row>
    )
  }

  if (isError) {
    return (
      <div className='text-center pt-2 pb-3'>
        <div className='d-flex align-items-center justify-content-center text-danger mb-2'>
          <i className='mdi mdi-alert-rhombus mdi-24px me-1'></i>
          <h4 className='m-0'>{t('Unexpected Error')}</h4>
          <i className='mdi mdi-alert-rhombus mdi-24px ms-1'></i>
        </div>

        <p className='text-muted m-0'>{t('An error occurred while fetching data.')}</p>
        <p className='text-muted mb-2'>{t('Please try again later.')}</p>

        <Button onClick={() => setSearchParams({})} className='rounded-2' variant='primary'>
          <i className='mdi mdi-refresh me-1'></i>
          {t('Retry')}
        </Button>
      </div>
    )
  }

  if (!table.getRowModel().rows.length) {
    return (
      <div className='text-center pt-2 pb-3'>
        <div className='d-flex align-items-center justify-content-center text-muted mb-2'>
          <i className='mdi mdi-flask mdi-24px me-1'></i>
          <h4 className='m-0'>{t('No Data Found')}</h4>
          <i className='mdi mdi-flask mdi-24px ms-1'></i>
        </div>

        <p className='text-muted m-0'>{t('Sorry, no data were found.')}</p>
      </div>
    )
  }

  return (
    <Row className='custom-table' {...rowProps}>
      {table.getRowModel().rows.map((row) => (
        <Col key={row.index}>{customColumns(row)}</Col>
      ))}
    </Row>
  )
}
