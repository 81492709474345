import React from 'react'
import { SWRConfig } from 'swr'

import Routes from 'routes/routes'
import { fetcher } from 'services/swr/fetcher'

import 'locales/i18n'
import './assets/scss/saas.scss'

export function App() {
  // Set locale & extra method for yup schema validator

  // Before we show the app, we have to wait for our state to be ready.
  // In the meantime, don't render anything. This will be the background
  return (
    <SWRConfig value={{ fetcher }}>
      <Routes />
    </SWRConfig>
  )
}
