import classNames from 'classnames'
import React from 'react'
import { Nav } from 'react-bootstrap'
import { Link, matchPath, useLocation } from 'react-router-dom'

type DetailsMenuProps = {
  menus: {
    title: string
    icon: string
    url: string
  }[]
}

export const DetailsMenu = ({ menus }: DetailsMenuProps) => {
  const location = useLocation()

  return (
    <Nav variant='pills' className='flex-row mb-3'>
      {menus.map((tab, index) => (
        <Nav.Item key={index.toString()} className='text-center rounded-start pe-auto col'>
          <Nav.Link
            to={tab.url}
            as={Link}
            eventKey={tab.title}
            className={classNames('d-flex justify-content-center align-items-center w-100 gap-2', {
              active: !!matchPath(location.pathname, tab.url),
            })}
          >
            <i className={`${tab.icon} d-block me-1`}></i>
            <span className='d-sm-block d-none'>{tab.title}</span>
          </Nav.Link>
        </Nav.Item>
      ))}
    </Nav>
  )
}
