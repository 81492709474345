import classNames from 'classnames'
import React from 'react'
import { Form } from 'react-bootstrap'
import { Controller, useFormContext } from 'react-hook-form'
import ReactSelect from 'react-select'

import { RenderArrowIcon } from './render-arrow-icon'
import { convertValuesToString, getValuesFromString } from './select.helper'
import { OptionProps, SelectProps } from './select.types'

export const ControlledSelect = (props: SelectProps) => {
  const { name, label, options, containerClass, placeholder, isMulti = false, onChange } = props

  const { control } = useFormContext()

  return (
    <Form.Group className={containerClass}>
      {label && <Form.Label>{label}</Form.Label>}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange: onFormChange, value }, fieldState: { error, invalid } }) => (
          <>
            <ReactSelect
              isMulti={isMulti}
              components={{ DropdownIndicator: RenderArrowIcon }}
              options={options}
              placeholder={placeholder}
              value={getValuesFromString<OptionProps>(value, options)}
              onChange={(val: any) => {
                onChange && onChange(val)
                onFormChange(isMulti ? convertValuesToString(val) : val.value)
              }}
              className={classNames('react-select', {
                'is-invalid': invalid,
              })}
              classNamePrefix='react-select'
              id={`${name}-select`}
            />

            {error && (
              <Form.Control.Feedback type='invalid' className='d-block'>
                {error.message}
              </Form.Control.Feedback>
            )}
          </>
        )}
      />
    </Form.Group>
  )
}
