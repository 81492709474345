{
  "Change Email": "Change Email",
  "Didn’t receive the code?": "Didn’t receive the code? ",
  "Enter your email address to access the admin panel": "Enter your email address to access the admin panel",
  "Forgot your password?": "Forgot your password?",
  "OTP Code": "OTP Code",
  "otp is expired": "OTP is expired",
  "otp is incorrect": "OTP is incorrect",
  "resend_code_countdown_zero": "Resend Code",
  "resend_code_countdown_other": "Time Remaining: {{ countdownFormatted }} ",
  "See You Again!": "See You Again!",
  "Verify": "Verify",
  "We’ve sent a verification code to": "We’ve sent a verification code to {{ email }}, ",
  "Welcome Back 👋": "Welcome Back 👋",
  "You are now successfully sign out.": "You are now successfully sign out.",
  "Requirements": "Requirements : ",
  "Let’s download your app and explore our feature now!": "Let’s download your app and explore our feature now!",
  "You are invited! 🥳": "You are invited! 🥳",
  "Page Not Found Message": "It's looking like you may have taken a wrong turn. Don't worry... it happens to the best of us. Here's a little tip that might help you get back on track.",
  "Page Not Found": "Page Not Found",
  "Return Home": "Return Home",
  "Congrats! You’re Connected with Strava": "Congrats! You’re Connected with Strava",
  "You have successfully connected your account with Strava.": "You have successfully connected your account with Strava.",
  "Back to Apps": "Back to Apps",
  "Sorry, You’re Failed to Connect with Strava": "Sorry, You’re Failed to Connect with Strava",
  "Failed to connect your account with Strava.": "Failed to connect your account with Strava.",
  "Personal Information": "Personal Information",
  "Privacy & Security": "Privacy & Security",
  "Your profile is updated successfully": "Your profile is updated successfully",
  "Let’s download Factum mobile apps to see people stories": "Let’s download Factum mobile apps to see people stories",
  "Open App": "Open App",
  "share_story_title": "{{ storyName }} - {{ groupName }}",
  "share_story_description": "Celebrate {{ groupName }}'s achievements.",
  "share_story_description_withEvent": "Celebrate all the group achievements from {{ routeName }} on {{ activityDate }}",
  "join_group_title": "Group Invitation - Factum",
  "join_group_description": "Join Factum and be part of our exclusive group! Receive invitations, share stories, and connect with like-minded individuals.",
  "connect_with_strava_title_success": "Strava Connection Successfully - Factum",
  "connect_with_strava_title_failed": "Strava Connection Failed - Factum",
  "connect_with_strava_description_success": "Congratulations! You have successfully connected your Strava account to Factum. Enjoy seamless integration, track and share your activities",
  "connect_with_strava_description_failed": "Oops! Something went wrong while trying to connect with Strava on Factum. Please check your Strava account settings and try again.",
  "app_title": "PLAN, UNITE, ACHIEVE. FACTUM.",
  "app_description": "Welcome to Factum, where every journey begins with a community. Factum is your go-to hub for organizing, sharing, and enjoying adventures with friends and like-minded individuals. ",
  "Forgot Password": "Forgot Password",
  "We will send you an email with a link to create a new password.": "We will send you an email with a link to create a new password.",
  "We’ve sent the link to your email": "We’ve sent the link to your email",
  "Open your email to check the link we’ve sent to change your password.": "Open your email to check the link we’ve sent to change your password.",
  "Reset Password": "Reset Password",
  "Create new password for your Factum admin account by filling out the form below.": "Create new password for your Factum admin account by filling out the form below.",
  "Your password is updated, please login with your new password.": "Your password is updated, please login with your new password.",
  "Send Email": "Send Email",
  "new password or old password is invalid": "Cannot re-use previous password. Please choose a new password",
  "Join the Adventure!": "Join the Adventure!",
  "Download now to plan, participate, and celebrate your adventures together.": "Download now to plan, participate, and celebrate your adventures together.",
  "Congrats! You’re Connected with Garmin": "Congrats! You’re Connected with Garmin",
  "You have successfully connected your account with Garmin.": "You have successfully connected your account with Garmin.",
  "Sorry, You’re Failed to Connect with Garmin": "Sorry, You’re Failed to Connect with Garmin",
  "Failed to connect your account with Garmin.": "Failed to connect your account with Garmin.",
  "connect_with_garmin_title_success": "Garmin Connection Successfully - Factum",
  "connect_with_garmin_title_failed": "Garmin Connection Failed - Factum",
  "connect_with_garmin_description_success": "Congratulations! You have successfully connected your Garmin account to Factum. Enjoy seamless integration, track and share your activities",
  "connect_with_garmin_description_failed": "Oops! Something went wrong while trying to connect with Garmin on Factum. Please check your Strava account settings and try again.",
  "connect_with_wahoo_title_success": "Wahoo Connection Successfully - Factum",
  "connect_with_wahoo_title_failed": "Wahoo Connection Failed - Factum",
  "connect_with_wahoo_description_success": "Congratulations! You have successfully connected your Wahoo account to Factum. Enjoy seamless integration, track and share your activities",
  "connect_with_wahoo_description_failed": "Oops! Something went wrong while trying to connect with Wahoo on Factum. Please check your Strava account settings and try again.",
  "page_not_found_title": "Page Not Found - Factum",
  "page_not_found_description": "It's looking like you may have taken a wrong turn. Don't worry... it happens to the best of us. Here's a little tip that might help you get back on track.",
  "leaderboard_title_weekly_distance": "Weekly distance leaderboard",
  "leaderboard_title_monthly_distance": "Monthly distance leaderboard",
  "leaderboard_title_weekly_elevation": "Weekly elevation leaderboard",
  "leaderboard_title_monthly_elevation": "Monthly elevation leaderboard",
  "leaderboard_title_weekly_watt_per_kilo": "Weekly W/kg leaderboard",
  "leaderboard_title_monthly_watt_per_kilo": "Monthly W/kg leaderboard"
}
