import classNames from 'classnames'
import React, { useState } from 'react'

import { dummyAvatar, placeholder } from 'assets/images'

type RoundedImageProps = {
  image?: string
  size?: string
  alt?: string
  className?: string
}

export const RoundedImage = (props: RoundedImageProps) => {
  const { image, size = 'md', alt = '', className } = props
  const [onLoad, setOnLoad] = useState<boolean>(false)

  return (
    <img
      src={!onLoad ? placeholder : image || dummyAvatar}
      className={classNames('rounded-circle rounded-image', className, `avatar-${size}`, {
        'img-error': !image,
      })}
      alt={alt}
      onLoad={() => setOnLoad(true)}
      onError={(event) => {
        event.currentTarget.src = dummyAvatar
        event.currentTarget.className += ' img-error'
      }}
    />
  )
}
