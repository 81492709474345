import useSWR from 'swr'

import { oneHour } from './helpers/millisecond-intervals'
import { FetcherParams } from './helpers/swr.types'
import { modelAdaptor } from './middleware/model-adaptor'
import { AdminModel } from './models/admin.types'
import { computeUser } from './models/user.computed'

export const useProfile = () => {
  const { isLoading, error, mutate, data } = useSWR<AdminModel, any, FetcherParams>(
    { path: 'profile' },
    {
      use: [modelAdaptor(computeUser)],
      dedupingInterval: oneHour,
      suspense: location.pathname.split('/').includes('me'),
    }
  )

  return { profile: data, isLoading, isError: !!error, mutate }
}
