{
  "maximum_number": "Please enter less than or equal to {{ max }}",
  "minimum_character": "Please enter at least {{ min }} characters",
  "minimum_number": "Please enter at least or equal to {{ min }}",
  "more_than_number": "Please enter more than {{ more }}",
  "not_type_of": "Please enter valid {{ type }}",
  "required_code": "Please enter an OTP code",
  "required_country": "Please select your country",
  "required_country_code": "Please select your phone code",
  "required_current_password": "Please enter your current password",
  "required_email": "Please enter your email",
  "required_first_name": "Please enter your first name",
  "required_full_name": "Please enter your name",
  "required_last_name": "Please enter your last name",
  "required_password": "Please enter your password",
  "required_password_confirmation": "Please enter your password confirmation",
  "required_phone_number": "Please enter your phone number",
  "required_user_status": "Please select a status",
  "validity_email": "Please enter a valid email",
  "validity_password_confirmation": "Please make sure your password match",
  "validity_phone_number": "Please enter a valid phone number",
  "required_email_address": "Please enter your email address",
  "required_type": "Please select a type",
  "required_confirm_password": "Please confirm your password",
  "validity_confirm_password": "Please make sure your password match",
  "password_min_length": "Use at least {{ min }} characters",
  "password_lowercase_and_uppercase": "Use upper and lower case characters",
  "password_number": "Use at least one number",
  "required_new_password": "Please enter your new password",
  "required_date": "Please select a date",
  "required_distance": "Please enter a distance",
  "required_duration": "Please select a duration",
  "required_activity_type": "Please select an activity type",
  "required_start_time": "Please enter a start time",
  "required_start_location": "Please select a start location",
  "required_finish_location": "Please select a finish location",
  "required_creator": "Please select an organizer",
  "minimum_array_routes_ids": "Please select a route at least {{ min }} items",
  "required_title": "Please enter a title",
  "required_name": "Please enter a name",
  "image_size_validation": "Please upload an image less than {{ max }} Mb",
  "required_group_type": "Please select a type"
}
