import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import { Container } from 'react-bootstrap'
import { Outlet, useLocation } from 'react-router-dom'
import { useSnapshot } from 'valtio'

import LeftSidebar from 'components/side-bar/side-bar'
import TopBar from 'components/top-bar/top-bar'
import { useViewport } from 'hooks'
import { appStateStore } from 'stores/app-state-store'
import { toggleSidebarMode } from 'stores/app-state-store.actions'

import { BasicToast } from './basic-toast'
import { StackedToast } from './stacked-toast'

export const Layout: FunctionComponent = () => {
  const [paddingTopBar, setPaddingTopBar] = useState(0)

  const { colorScheme, sidebarMode } = useSnapshot(appStateStore.state)

  const location = useLocation()
  const { width } = useViewport()

  const topBarRef = useRef<HTMLDivElement>(null)

  const changeBodyAttribute = (attribute: string, value: string): void => {
    if (document.body) document.body.setAttribute(attribute, value)
  }

  const changeSidebarModeOnResize = useCallback(() => {
    if (width >= 768 && width <= 1028) {
      toggleSidebarMode('condensed')
    }
  }, [width])

  useEffect(() => {
    changeBodyAttribute('data-layout', 'full')
    changeBodyAttribute('data-layout-mode', 'fluid')
  }, [])

  useEffect(() => {
    changeBodyAttribute('data-layout-color', colorScheme)
    changeBodyAttribute('data-leftbar-theme', colorScheme)
  }, [colorScheme])

  useEffect(() => {
    changeBodyAttribute('data-leftbar-compact-mode', sidebarMode)
  }, [sidebarMode])

  useEffect(() => {
    window.addEventListener('resize', changeSidebarModeOnResize)

    return () => {
      window.removeEventListener('resize', changeSidebarModeOnResize)
    }
  }, [])

  useEffect(() => {
    if (location.pathname.split('/').includes('analytics') && !!location.search) return

    window.scrollTo(0, 0)
  }, [location])

  useEffect(() => {
    const siblingElement = topBarRef.current.nextElementSibling as HTMLElement
    const newHeight = topBarRef.current?.clientHeight

    if (topBarRef.current) {
      siblingElement.style.marginTop = `${newHeight}px`
      setPaddingTopBar(newHeight - 72)
    }
  }, [topBarRef.current?.clientHeight, width, location])

  return (
    <div className='wrapper'>
      <LeftSidebar
        paddingTop={paddingTopBar}
        isLight={colorScheme === 'light'}
        isCondensed={sidebarMode === 'condensed'}
      />
      <div className='content-page'>
        <div className='content'>
          <TopBar ref={topBarRef} />
          <Container fluid>
            <Outlet />

            <BasicToast />
            <StackedToast />
          </Container>
        </div>
      </div>
    </div>
  )
}
