import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { Outlet } from 'react-router-dom'

import { logoLight } from 'assets/images'
import { useAccountLayout } from 'hooks'

import { BasicToast } from './basic-toast'
import { StackedToast } from './stacked-toast'

const AuthLayout = () => {
  useAccountLayout()

  return (
    <div className='account-pages pt-2 pt-sm-5 pb-4 pb-sm-5'>
      <Container>
        <Row className='justify-content-center'>
          <Col sm={9} md={8} lg={6} xl={5} xxl={4}>
            <Card className='gap-1'>
              <Card.Header className='text-center bg-primary'>
                <img
                  src='/logo-light.svg'
                  onError={(event) => {
                    event.currentTarget.src = logoLight
                  }}
                  alt='logo'
                  height='24'
                />
              </Card.Header>
              <Card.Body className='p-3'>
                <Outlet />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <BasicToast />
      <StackedToast />
    </div>
  )
}

export default AuthLayout
