import classNames from 'classnames'
import dayjs from 'dayjs'
import React, { useRef } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'
import { useController, useFormContext } from 'react-hook-form'

export type DatePickerInputProps = {
  name: string
  label: string
  placeholder: string
  containerClass?: string
  valueFormat?: string
} & Omit<ReactDatePickerProps, 'onChange'>

export const ControlledDatePickerInput = (props: DatePickerInputProps) => {
  const {
    label,
    name,
    placeholder,
    containerClass,
    valueFormat,
    showTimeSelectOnly = false,
  } = props

  const ref = useRef<DatePicker>(null)

  const { control } = useFormContext()

  const {
    field: { onChange, value },
    fieldState: { invalid, error },
  } = useController({ control, name })

  const onChangeDate = (date: Date) => {
    const formattedDate = date ? dayjs(date).format(valueFormat) : null
    onChange(formattedDate)
  }

  return (
    <Form.Group className={containerClass}>
      <Form.Label>{label}</Form.Label>
      <InputGroup
        className={classNames('mb-1 date-picker-input-group', {
          'is-invalid': invalid,
        })}
      >
        <DatePicker
          ref={ref}
          className={classNames('form-control', {
            'is-invalid': error,
          })}
          selected={value && new Date(value)}
          placeholderText={placeholder}
          onChange={onChangeDate}
          isClearable={true}
          showTimeSelectOnly={showTimeSelectOnly}
          {...props}
        />

        <div
          role='button'
          className='input-group-text input-group-password border-0'
          onClick={() => ref.current?.setFocus()}
        >
          <i className={`icon icon-20px ${showTimeSelectOnly ? 'icon-clock' : 'icon-calendar'}`} />
        </div>
      </InputGroup>

      {error && (
        <Form.Control.Feedback className='d-block m-0' type='invalid'>
          {error.message}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  )
}
