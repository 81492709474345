import kebabCase from 'lodash/kebabCase'
import React from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { ActionButton } from 'components/button/action-button'

type ConfirmationModalProps = {
  title: string
  body: string
  visible?: boolean
  onCancel: () => void
  onConfirm: () => void
  cancelLabel?: string
  confirmLabel?: string
  variant?: string
  testId?: string
  avatarImage?: string
  avatarIcon?: string
}

export const ConfirmationModal = (props: ConfirmationModalProps) => {
  const { t } = useTranslation()
  const {
    title,
    body,
    visible,
    onCancel,
    onConfirm,
    confirmLabel,
    variant = 'success',
    testId = 'confirmation-modal',
    avatarImage,
    avatarIcon,
  } = props

  const onConfirmModal = () => {
    try {
      if (onConfirm) onConfirm()
    } finally {
      onCancel()
    }
  }

  return (
    <Modal show={visible} onHide={onCancel} centered data-test-id={kebabCase(testId)} size='sm'>
      <Modal.Body className='rounded-3 text-center'>
        <button
          className='button-menu-mobile button-close-modal'
          onClick={onCancel}
          aria-label={t('Toggle Modal')}
        >
          <i className='icon-close' />
        </button>

        <div className='modal-body-content'>
          <div className={`image-frame ${avatarImage ? 'bg-transparent' : ''} rounded-circle`}>
            {avatarImage && (
              <>
                <img src={avatarImage} alt={avatarImage} />
                {avatarIcon && <i className={`${avatarIcon} icon-24px delete-icon icon`} />}
              </>
            )}
          </div>

          <h3 className='font-22 fw-semibold'>{title}</h3>
          <span className='font-14'>{body}</span>
        </div>

        <ActionButton
          id='confirmation-modal-confirm'
          className='m-auto'
          variant={variant}
          label={confirmLabel || t('Delete Account')}
          onClick={onConfirmModal}
        />
      </Modal.Body>
    </Modal>
  )
}
