import classNames from 'classnames'
import React from 'react'
import { Form, FormControlProps, InputGroup } from 'react-bootstrap'
import { useController, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useToggle } from 'hooks'

import { PasswordValidation } from './password-validation'

type PasswordInputProps = FormControlProps & {
  name: string
  placeholder?: string
  containerClass?: string
  label: string
  withForgotPassword?: boolean
  hasMultipleValidation?: boolean
  passwordValidationRules?: Array<any>
}

export const ControlledPasswordInput = (props: PasswordInputProps) => {
  const { t } = useTranslation('auth')

  const {
    label,
    name,
    containerClass,
    withForgotPassword = false,
    hasMultipleValidation = false,
    passwordValidationRules = [],
    ...otherProps
  } = props

  const [showPassword, togglePassword] = useToggle()
  const [passwordModal, , showPasswordModal, hidePasswordModal] = useToggle()

  const { register, control } = useFormContext()
  const {
    formState: { isSubmitted },
    fieldState: { invalid, error },
  } = useController({ control, name })

  return (
    <Form.Group className={containerClass}>
      <Form.Label>{label}</Form.Label>
      <InputGroup className={classNames('mb-1 password-input', { 'is-invalid': invalid })}>
        <Form.Control
          name={name}
          id={name}
          as='input'
          isInvalid={invalid}
          type={showPassword ? 'text' : 'password'}
          {...register(name)}
          onFocus={() => showPasswordModal()}
          onBlur={() => hidePasswordModal()}
          {...otherProps}
          data-test-id={name}
        />
        <div
          role='button'
          onClick={togglePassword}
          className={classNames('input-group-text', 'input-group-password', {
            'show-password': showPassword,
          })}
          data-password={showPassword ? 'true' : 'false'}
        >
          <span className='password-eye' />
        </div>
      </InputGroup>

      {!hasMultipleValidation && (
        <Form.Group className='d-flex'>
          {error && (
            <Form.Control.Feedback className='d-block m-0' type='invalid'>
              {error.message}
            </Form.Control.Feedback>
          )}

          {withForgotPassword && (
            <Link
              to='/account/forgot-password'
              className='fw-semibold w-100 text-end forgot-password'
            >
              {t('Forgot your password?')}
            </Link>
          )}
        </Form.Group>
      )}

      {hasMultipleValidation && isSubmitted && (
        <PasswordValidation
          error={error}
          visible={passwordModal}
          rules={passwordValidationRules}
          invalid={invalid}
        />
      )}
    </Form.Group>
  )
}
