import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

import { userEditPath, userNewPath, userPath } from 'config/paths'
import { LoadComponent } from 'routes/load-component'

const userList = lazy(() => import('./user-list/user-list'))
const userDetails = lazy(() => import('./user-details/user-details'))
const userForm = lazy(() => import('./user-form/user-form'))

export const userRoutes = (): RouteObject[] => [
  {
    index: true,
    path: userPath(),
    element: <LoadComponent component={userList} />,
  },
  {
    path: userPath(':id'),
    element: <LoadComponent component={userDetails} />,
  },
  {
    path: userNewPath(),
    element: <LoadComponent component={userForm} />,
  },
  {
    path: userEditPath(':id'),
    element: <LoadComponent component={userForm} />,
  },
]
