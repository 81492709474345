import React, { useCallback, useEffect, useState } from 'react'
import { Pagination as RBPagination } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { useViewport } from 'hooks'

import { PaginationProps } from './table.types'

export const Pagination = <TableValues extends object>(props: PaginationProps<TableValues>) => {
  const { table, currentPage = 1, totalPage = 1, totalItems, size, paginationLength = 5 } = props
  const { width } = useViewport()

  const { t } = useTranslation()

  const filterPages = useCallback(
    (visiblePages: number[]) => {
      return visiblePages.filter((page: number) => page <= totalPage)
    },
    [totalPage]
  )

  const getVisiblePages = useCallback(
    (page: number, total: number) => {
      if (width < 576) return []

      if (total < 7) {
        return filterPages([1, 2, 3, 4, 5, 6])
      } else {
        if (page % paginationLength >= 0 && page > paginationLength - 1 && page + 2 < total) {
          return paginationLength < 5 ? [1, page, total] : [1, page - 1, page, page + 1, total]
        } else if (
          page % paginationLength >= 0 &&
          page > paginationLength - 1 &&
          page + 2 >= total
        ) {
          return [1, total - 3, total - 2, total - 1, total]
        } else {
          const length = Array(paginationLength)
            .fill(null)
            .map((_, i) => i + 1)

          return [...length, total]
        }
      }
    },
    [filterPages, width]
  )

  const [visiblePages, setVisiblePages] = useState<number[]>(
    getVisiblePages(currentPage, totalPage)
  )

  useEffect(() => {
    const visiblePages = getVisiblePages(currentPage, totalPage)

    setVisiblePages(visiblePages)
  }, [currentPage, totalPage, getVisiblePages])

  return (
    <div className='table-pagination-details'>
      {typeof totalItems === 'number' && (
        <div className='bg-body text-raisin-black d-flex align-items-center justify-content-center rounded-2 px-2 py-1 fw-medium'>
          {t('total_item_result', { totalItems })}
        </div>
      )}

      {totalPage > 1 && (
        <div className='table-pagination-numbers'>
          <RBPagination size={size} className='mb-0'>
            <RBPagination.Prev
              disabled={!table.getCanPreviousPage()}
              onClick={() => table.previousPage()}
            >
              <i className='icon icon-double-chevron-l' />
            </RBPagination.Prev>

            {(visiblePages || []).map((page, index, array) => {
              return array[index - 1] + 1 < page ? (
                <React.Fragment key={page.toString()}>
                  <RBPagination.Item>...</RBPagination.Item>
                  <RBPagination.Item
                    active={page === currentPage}
                    onClick={() => table.setPageIndex(page - 1)}
                  >
                    {page}
                  </RBPagination.Item>
                </React.Fragment>
              ) : (
                <RBPagination.Item
                  key={page.toString()}
                  active={page === currentPage}
                  onClick={() => table.setPageIndex(page - 1)}
                >
                  {page}
                </RBPagination.Item>
              )
            })}

            <RBPagination.Next disabled={!table.getCanNextPage()} onClick={() => table.nextPage()}>
              <i className='icon icon-double-chevron-r' />
            </RBPagination.Next>
          </RBPagination>
        </div>
      )}
    </div>
  )
}
