import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useSearchParams } from 'react-router-dom'

import { appleStoreBadge, playStoreBadge } from 'assets/images'
import { APP_STORE_LINK, DEEP_LINK_BASE_URL, GOOGLE_STORE_LINK } from 'config/env'

const ShareLeaderboard = () => {
  const [searchParams] = useSearchParams()
  const { id } = useParams()
  const { t } = useTranslation('auth')

  const leaderboardType = useMemo(() => searchParams.get('type') || 'distance', [searchParams])
  const leaderboardDate = useMemo(
    () => searchParams.get('date') || new Date().toISOString(),
    [searchParams]
  )
  const leaderboardDateType = useMemo(
    () => searchParams.get('dateType') || 'weekly',
    [searchParams]
  )

  const searchParamsString = useMemo(() => {
    const searchParams = new URLSearchParams()
    searchParams.append('type', leaderboardType)
    searchParams.append('date', leaderboardDate)
    searchParams.append('dateType', leaderboardDateType)

    return searchParams.toString()
  }, [leaderboardDate, leaderboardDateType, leaderboardType])

  const deepLinkUrl = `${DEEP_LINK_BASE_URL}group-leaderboard/${id}?${searchParamsString}`

  const openFactumApp = () => {
    setTimeout(() => {
      location.replace(deepLinkUrl)
    }, 500)
  }

  useEffect(() => {
    if (!window) return

    openFactumApp()
  }, [])

  useEffect(() => {
    if (!document.body) return null

    document.body.classList.add('invite-group')

    return () => document.body.classList.remove('invite-group')
  }, [])

  return (
    <div className='invite-group-overlay'>
      <div className='invite-group-logo-container'>
        <div className='invite-group-logo' />
      </div>
      <div className='invite-group-content'>
        <h1>{t('Join the Adventure!')}</h1>
        <p>{t('Download now to plan, participate, and celebrate your adventures together.')}</p>
      </div>
      <div className='invite-group-btn'>
        <a href={GOOGLE_STORE_LINK}>
          <img
            src='/play-store-badge.svg'
            alt='play-store-download'
            onError={(event) => {
              event.currentTarget.src = playStoreBadge
            }}
          />
        </a>
      </div>
      <div className='invite-group-btn'>
        <a href={APP_STORE_LINK}>
          <img
            src='/apple-store-badge.svg'
            alt='apple-store-download'
            onError={(event) => {
              event.currentTarget.src = appleStoreBadge
            }}
          />
        </a>
      </div>
    </div>
  )
}

export default ShareLeaderboard
