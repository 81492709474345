{
  "Added": "Added",
  "total_added": "{{ count }} Added",
  "Add Member": "Add Member",
  "Creator": "Creator",
  "View All": "View All",
  "Member": "Member",
  "admin_count": "{{ count }} Admin",
  "member_count": "{{ count }} Member",
  "creator_count": "{{ count }} Creator",
  "Go To Detail": "Go To Detail",
  "Add": "Add",
  "If you’re not select a creator, you’ll set as a creator": "If you’re not select a creator, you’ll set as a creator",
  "Select Creator": "Select Creator",
  "Set Creator": "Set Creator",
  "Set Admin": "Set Admin",
  "Remove": "Remove",
  "Group Profile Picture": "Group Profile Picture",
  "Create Group": "Create Group",
  "You’ll delete the account permanently by clicking the delete button": "You’ll delete the account permanently by clicking the delete button",
  "Delete Group": "Delete Group",
  "Are you sure want to delete this group?": "Are you sure want to delete this group?",
  "Are you sure want to update this group member role to": "Are you sure want to update this group member role to {{ role }}?",
  "You’ll update the group member role to by click the update button": "You’ll update the group member role to {{ role }} by click the update button",
  "Update role to": "Update role to {{ role }}",
  "Are you sure want to remove this member from group?": "Are you sure want to remove this member from group?",
  "You’ll remove the member from group permanently by clicking the remove button": "You’ll remove the member from group permanently by clicking the remove button",
  "Remove member from group": "Remove member from group",
  "Delete group": "Delete group",
  "Dismiss Admin": "Dismiss Admin",
  "Member is successfully update to": "Member is successfully {{ roleChange }} member to {{ updatedRole }}",
  "Failed to update member to": "Failed to {{ roleChange }} member to {{ updatedRole }}",
  "Failed to remove member from group": "Failed to remove member from group",
  "Member is successfully removed from group": "Member is successfully removed from group",
  "Are you sure want to update this group to": "Are you sure want to update this group to {{ status }}",
  "You’ll update the group to by click the update button": "You’ll update the group to {{ status }} by click the update button",
  "Group is successfully deleted": "Group is successfully deleted",
  "Failed to delete group": "Failed to delete group",
  "Remove creator is prohibited": "Remove 'Creator' is prohibited",
  "You’ll delete the group permanently by clicking the delete button": "You’ll delete the group permanently by clicking the delete button",
  "Group Description": "Group Description",
  "Your group description": "Your group description",
  "Member Message": "Member Message",
  "Group Type": "Group Type",
  "Allow all member to send a message in group": "Allow all member to send a message in group",
  "Event Name": "Event Name",
  "Add Event": "Add Event",
  "You’ll change the status by clicking the 'Save Changes' button below.": "You’ll change the status by clicking the 'Save Changes' button below.",
  "Group messaging permissions updated successfully!": "Group messaging permissions updated successfully!",
  "Unable to update group permissions.": "Unable to update group permissions.",
  "Public Group": "Public Group",
  "Default Group": "Default Group",
  "Add Creator": "Add Creator",
  "Change Creator": "Change Creator",
  "Select a user as a group creator": "Select a user as a group creator",
  "You must select a user as a group creator": "You must select a user as a group creator",
  "Select a user as a group member": "Select a user as a group member",
  "You must select a user as a group member": "You must select a user as a group member",
  "Your group type": "Your group type",
  "Search Name, ID, etc": "Search Name, ID, etc",
  "all members to send messages in this group?": "{{ status }} all members to send messages in this group?",
  "Super Group": "Super Group",
  "Activate super group features": "Activate super group features",
  "Super group features updated successfully!": "Super group features updated successfully!",
  "super group features?": "{{ status }} super group features?",
  "superGroupModalBody": "You will {{ status }} the super group features by clicking the 'Save Changes' button below."
}
