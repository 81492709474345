import isEqual from 'lodash/isEqual'
import { useState } from 'react'

import { ModalListItemProps } from './select-item-card.types'

export function useSelectItemCard<ModelData extends { id?: string | number }>(
  isMulti: boolean,
  initialSelectedData: ModalListItemProps<ModelData>['initialData'],
  setInitialSelectedData: ModalListItemProps<ModelData>['setInitialData']
) {
  const [modalVisible, setModalVisible] = useState<boolean>(false)

  const removeSelectedItem = (selectedData: ModelData, fn: (data: any) => void) => {
    if (!isMulti) return fn(null)

    fn((prevData: any) => {
      return prevData.filter((itemPrev: ModelData) => !isEqual(itemPrev, selectedData))
    })
  }

  const addSelectedItem = (selectedData: ModelData) => {
    if (!isMulti) return setInitialSelectedData(selectedData)

    setInitialSelectedData((prev) => {
      if (!Array.isArray(prev)) return []

      return [
        ...prev.filter((itemPrev: ModelData) => !isEqual(itemPrev, selectedData)),
        selectedData,
      ]
    })
  }

  const isSelectedItem = (item: ModelData) => {
    if (!Array.isArray(initialSelectedData)) {
      return isEqual(item, initialSelectedData) || initialSelectedData?.id === item?.id
    }

    return initialSelectedData.some(
      (valueItem: ModelData) => isEqual(item, valueItem) || valueItem?.id === item?.id
    )
  }

  return {
    initialSelectedData,
    setInitialSelectedData,
    isSelectedItem,
    removeSelectedItem,
    addSelectedItem,
    modalVisible,
    setModalVisible,
  }
}
