import React, { DetailedHTMLProps, ImgHTMLAttributes, VideoHTMLAttributes, forwardRef } from 'react'

import { ImageLoader } from 'components/img-loader/img-loader'
import { StoryItemModel } from 'services/swr/models/story.types'

type StoryPlayerProps = DetailedHTMLProps<VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement> &
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
    story: StoryItemModel
  }

export const StoryPlayer = forwardRef<any, StoryPlayerProps>((props, ref) => {
  const { story, ...rest } = props

  const storyType = story.storyType

  if (storyType === 'Video') {
    return <video ref={ref} {...rest} src={story.url} loop />
  }

  if (storyType === 'Photo') {
    return <ImageLoader ref={null} {...rest} src={story.url} alt={story.url} />
  }

  return null
})

StoryPlayer.displayName = 'StoryPlayer'
