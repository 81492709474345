import { derive } from 'valtio/utils'

import { translate as t } from 'locales/i18n'

import { ToastModel } from './toast-model.types'

export const computeToastModel = (state: ToastModel) =>
  derive({
    visible: (get) => !!get(state).body,
    displayTitle: (get) => get(state).title || t(get(state).variant),
    icon: (get) => {
      switch (get(state).variant) {
        case 'danger':
          return 'mdi mdi-close-circle'
        case 'warning':
          return 'mdi mdi-alert-outline'
        case 'info':
          return 'mdi mdi-web'
        case 'success':
        default:
          return 'mdi mdi-check'
      }
    },
  })
