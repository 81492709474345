/* eslint-disable @typescript-eslint/ban-types */
import { ApiCore } from './api-core'

export class AuthApi extends ApiCore {
  async login(payload = {}) {
    this.pathPrefix = 'auth/admin'

    return await this.post({
      path: 'login',
      payload,
    })
  }

  async verifyOTP(payload = {}) {
    this.pathPrefix = 'auth/admin'

    return await this.post({
      path: 'login/verify',
      payload,
    })
  }

  async logout() {
    this.pathPrefix = 'auth/admin'

    return await this.delete({ path: 'sign_out' })
  }

  async forgotPassword(payload = {}) {
    this.pathPrefix = 'auth'

    return await this.post({
      path: 'forgot-password',
      payload,
    })
  }

  async resendOTPCode(accountId: string) {
    this.pathPrefix = 'auth/admin'

    return await this.get({
      path: `resend-otp/${accountId}`,
    })
  }

  async updateProfile(payload = {}) {
    this.pathPrefix = ''

    return await this.put({
      path: 'profile',
      payload,
    })
  }

  async changePassword(payload = {}) {
    this.pathPrefix = 'profile'

    return await this.post({
      path: 'change-password',
      payload,
    })
  }

  async resetPassword(payload = {}) {
    this.pathPrefix = 'profile'

    return await this.post({
      path: 'reset-password',
      payload,
    })
  }

  async getRefreshToken() {
    this.pathPrefix = 'auth/admin'

    return await this.getNewRefreshToken()
  }
}

export const authApi = new AuthApi()
