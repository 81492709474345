import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useSnapshot } from 'valtio'
import * as yup from 'yup'

import { useCountdownOTP } from 'hooks/use-countdown-otp'
import { useToast } from 'hooks/use-toast.hook'
import { ApiErrorKind, authApi } from 'services/api'
import { authStore } from 'stores/auth-store'
import { loadString, saveString } from 'utils/session-storage'
import { InferType } from 'utils/typescript'

export default function useLogin() {
  const defaultEmail = loadString('email')

  const navigate = useNavigate()
  const { showToast, showUnexpectedToast } = useToast()

  const { start } = useCountdownOTP()

  const { isAuthenticated } = useSnapshot(authStore.computed)

  const schema = yup.object().shape({
    email: yup.string().ensure().required().email(),
    password: yup.string().ensure().required().min(8),
  })

  const methods = useForm<InferType<typeof schema>>({
    resolver: yupResolver(schema),
    defaultValues: { email: defaultEmail },
  })

  const verifyLogin = (email?: string, accountId?: string) => {
    const countdownStarted = start()

    if (countdownStarted) {
      if (email) saveString('email', email)
      if (accountId) saveString('accountId', accountId)
      navigate('/account/verify-otp-code')
    }
  }

  const onSubmit = async ({ email, password }: InferType<typeof schema>) => {
    try {
      const loggedIn = await authApi.login({ email, password })

      if (loggedIn.ok) verifyLogin(email, loggedIn.data.data?.accountId)
    } catch (error: any) {
      if (error.kind === ApiErrorKind.UNPROCESSABLE || error.kind === ApiErrorKind.UNAUTHORIZED) {
        showToast({ variant: 'danger', body: error.message })
        return
      }

      if (error.kind === ApiErrorKind.FOUND) return verifyLogin()

      showUnexpectedToast()
    }
  }

  useEffect(() => {
    if (isAuthenticated) navigate('/dashboard', { replace: true })
  }, [isAuthenticated])

  return { methods, onSubmit }
}
