import { proxy, snapshot } from 'valtio'

import { computeToastModel } from './toast-model.computed'
import { ToastModel } from './toast-model.types'
import { toastStore } from './toast-store'

export const showBasicToast = (toast: ToastModel) => {
  toastStore.basic = toast
}

export const showStackedToast = (toast: ToastModel) => {
  toastStore.stacked.push({ ...toast, ...computeToastModel(proxy(toast)) })
}

export const cleanStackedToast = () => {
  const { stacked } = snapshot(toastStore)

  toastStore.stacked = stacked.filter((item) => item.visible)
}

export const hideStackedToast = (index: number) => {
  toastStore.stacked[index].body = null
  toastStore.stacked[index].visible = false
}

export const hideBasicToast = () => {
  toastStore.basic.body = null
  toastStore.basic.visible = false
}
