export default {
  common: require('./common.json'),
  validation: require('./validation.json'),
  auth: require('./auth.json'),
  table: require('./table.json'),
  account: require('./account.json'),
  event: require('./event.json'),
  group: require('./group.json'),
  app_config: require('./app-config.json'),
  analytic: require('./analytic.json'),
  story: require('./story.json'),
}
