{
  "Account is successfully deleted": "Account is successfully deleted",
  "Account is successfully update to": "Account is successfully updated to {{ status }}",
  "Account Status": "Account Status",
  "Are you sure want to ban this account?": "Are you sure want to ban this account?",
  "Are you sure want to delete this account?": "Are you sure want to delete this account?",
  "Are you sure want to update the selected account?": "Are you sure want to update the selected account?",
  "Ban Account": "Ban Account",
  "Create Account": "Create Account",
  "Failed to delete account": "Failed to delete account",
  "Failed to update account to": "Failed to update account to {{ status }}",
  "Update Account": "Update Account",
  "You’ll ban the account by clicking the ban button": "You’ll ban the account by clicking the ban button",
  "You’ll delete the account permanently by clicking the delete button": "You’ll delete the account permanently by clicking the delete button",
  "You’ll update the account to by click the update button": "You’ll update the account to {{ status }} by click the update button",
  "Type": "Type",
  "Account Type": "Account Type",
  "Select an account type": "Select an account type",
  "Are you sure want to update this account to": "Are you sure want to update this account to {{ status }}",
  "Apps": "Apps",
  "An email has been sent to your email address, please check your email.": "An email has been sent to your email address, please check your email."
}
