export type FileType = File & {
  preview?: string
  formattedSize?: string
  base64Data?: string | ArrayBuffer
}

export type FileUploaderProps = {
  onFileUpload?: (files?: FileType[]) => void
  showPreview?: boolean
  hint?: string
  isInvalid?: boolean
  name?: string
  multiSelect?: boolean
  value?: FileType[]
  acceptedFile?: string | string[]
}

export type ControlledFileUploaderProps = FileUploaderProps & {
  label?: string
  name: string
  containerClass?: string
  value?: FileType[] | string
}
