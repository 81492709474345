import React from 'react'
import { useTranslation } from 'react-i18next'

import { ControlledInput, ControlledPasswordInput, FormProvider, SubmitButton } from 'components'

import useLogin from './login.hook'

const Login = () => {
  const { t } = useTranslation('auth')

  const { onSubmit, methods } = useLogin()

  return (
    <>
      <div className='m-auto text-center d-flex flex-column align-items-center text-balance'>
        <h4 className='text-black mt-0 fw-semibold display-6'>{t('Welcome Back 👋')}</h4>
        <p className='text-muted mb-3 w-75'>
          {t('Enter your email address to access the admin panel')}
        </p>
      </div>

      <FormProvider methods={methods} onSubmit={onSubmit} name='login'>
        <ControlledInput
          label={t('Email')}
          name='email'
          containerClass='mb-3'
          placeholder={t('Your email')}
        />

        <ControlledPasswordInput
          label={t('Password')}
          name='password'
          containerClass='mb-3'
          placeholder={t('Your password')}
          withForgotPassword
        />

        <div className='d-flex justify-content-center mt-4'>
          <SubmitButton variant='primary' type='submit' loading={methods.formState.isSubmitting}>
            {t('Login')}
          </SubmitButton>
        </div>
      </FormProvider>
    </>
  )
}

export default Login
