import { snapshot } from 'valtio'

import { ASSETS_BASE_URL } from 'config/env'
import { appConfigStore } from 'stores/app-config-store'

import { trimSlash } from './string'

export function remoteImage(src: string) {
  const {
    config: { cloudfrontUrl },
  } = snapshot(appConfigStore.state)

  if (!src) return null

  return trimSlash(`${cloudfrontUrl || ASSETS_BASE_URL}/${src}`)
}
