{
  "Active Users": "Active Users",
  "A total of active users in a certain period": "A total of active users in a certain period",
  "New Users": "New Users",
  "A total of joined users in a certain period": "A total of joined users in a certain period",
  "Total Users": "Total Users",
  "A total of users in a certain period": "A total of users in a certain period",
  "Total Groups": "Total Groups",
  "A total of groups in a certain period": "A total of groups in a certain period",
  "New Groups": "New Groups",
  "A total of created groups in a certain period": "A total of created groups in a certain period",
  "Group Sizes": "Group Sizes",
  "Details of the total distribution of members in the group": "Details of the total distribution of members in the group",
  "Largest Group": "Largest Group",
  "Average Group": "Average Group",
  "Smallest Group": "Smallest Group",
  "Deleted Groups": "Deleted Groups",
  "A total of deleted groups in a certain period": "A total of deleted groups in a certain period",
  "percentage": "{{ percentage }}%",
  "Concurrent Users": "Concurrent Users",
  "A total of current users at the same time": " A total of current users at the same time",
  "Maximum Users": "Maximum Users",
  "Minimum Users": "Minimum Users"
}
