import { derive } from 'valtio/utils'

import { groupPath } from 'config/paths'
import { translate as t } from 'locales/i18n'
import { remoteImage } from 'utils/remote-image'

import { GroupModel } from './group.types'
import { computeMember } from './member.computed'

export const computeGroup = (state: GroupModel) =>
  derive({
    statusIcon: (get) => {
      switch (get(state)?.status?.toLowerCase()) {
        case 'active':
          return 'icon icon-check icon-18px'
        case 'disabled':
          return 'icon icon-close icon-18px'
        default:
          return ''
      }
    },
    groupCreator: (get) => {
      const creator = get(state)?.members?.filter(
        (member) => member.groupRole.toLowerCase() === 'creator'
      )[0]

      if (!creator) return null

      return computeMember(creator)
    },
    path: (get) => (get(state).id ? groupPath(get(state).id) : '#'),
    groupPhoto: (get) => remoteImage(get(state).groupPhotoURL || get(state).groupPhotoUrl),
    members: (get) =>
      get(state)?.members?.map((member) => ({ ...member, ...computeMember(member) })),
    groupTypeFormatted: (get) => {
      switch (get(state).groupType) {
        case 'support':
          return t('Support Group')
        case 'partnership':
          return t('Partnership Group')
        case 'standard':
        default:
          return t('Standard Group')
      }
    },
    statusColor: (get) => {
      switch (get(state)?.status?.toLowerCase()) {
        case 'active':
          return 'primary-light'
        case 'disabled':
          return 'secondary'
        default:
          return ''
      }
    },
  })
