import dayjs from 'dayjs'
import { derive } from 'valtio/utils'

import { AuthStore } from './auth-store.types'

export const computeAuthStore = (state: AuthStore) =>
  derive({
    authorization: (get) => get(state).token,
    isAuthenticated: (get) => {
      const hasToken = !!get(state).token
      const hasRefreshToken = !!get(state).refreshToken
      const tokenExpiredAt = dayjs(get(state).tokenExpiry || 0)
      const refreshTokenExpiredAt = dayjs(get(state).refreshTokenExpiry || 0)
      const tokenHasExpired = tokenExpiredAt.isBefore(dayjs())
      const refreshTokenHasExpired = refreshTokenExpiredAt.isBefore(dayjs())

      return (hasToken && !tokenHasExpired) || (hasRefreshToken && !refreshTokenHasExpired)
    },
    isMapTokenValid: (get) => {
      const hasMapsAccessToken = !!get(state).mapsAccessToken
      const mapsAccessTokenExpiredAt = dayjs(get(state).mapsAccessTokenExpiry)
      const mapsAccessTokenHasExpired = mapsAccessTokenExpiredAt.isBefore(dayjs())

      return hasMapsAccessToken && !mapsAccessTokenHasExpired
    },
    isSuperAdmin: (get) => get(state).user.accountType === 'SuperAdmin',
    displayName: (get) => `${get(state).user.firstName} ${get(state).user.lastName}`,
  })
