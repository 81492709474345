import classNames from 'classnames'
import React from 'react'
import { Toast, ToastContainer } from 'react-bootstrap'
import { useSnapshot } from 'valtio'

import { toastStore } from 'stores/toast-store'
import { hideStackedToast } from 'stores/toast-store.actions'

export const StackedToast = () => {
  const { stacked } = useSnapshot(toastStore)

  const getIcon = (variant: string) => {
    switch (variant) {
      case 'success':
        return 'mdi-checkbox-marked-circle-outline'
      case 'warning':
        return 'mdi-alert-outline'
      case 'danger':
        return 'mdi-alert-circle-outline'
      case 'info':
        return 'mdi-information-outline'
      default:
        return null
    }
  }

  return (
    <ToastContainer containerPosition='fixed' position='top-end' className='p-3'>
      {stacked.map((item, index) => (
        <Toast
          key={index}
          bg={item.variant}
          autohide
          delay={2500}
          show={item.visible}
          onClose={() => hideStackedToast(index)}
        >
          <Toast.Body>
            <div className='d-flex align-items-center'>
              <i className={classNames('mdi mdi-24px icon', getIcon(item.variant))} />

              <strong className='ms-2 me-auto text-capitalize'>{item.displayTitle}</strong>
              <i
                className='mdi mdi-close mdi-24px'
                role='button'
                onClick={() => hideStackedToast(index)}
              />
            </div>
            <div className='ms-4'>{item.body}</div>
          </Toast.Body>
        </Toast>
      ))}
    </ToastContainer>
  )
}
