import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SimpleBar from 'simplebar-react'

import { ModalWithForm } from 'components/forms'

import { ModalListContent } from './modal-list-content'
import { ModalListItemProps } from './select-item-card.types'

export const ModalListItem = <ModelData extends object>(props: ModalListItemProps<ModelData>) => {
  const { t } = useTranslation()

  const {
    onConfirm,
    submitLabel,
    label,
    icon,
    onCancel,
    visible,
    loadMoreFn,
    customForm,
    methods,
    customFormIcon,
    customFormTitle,
    isCustomFormVisible,
    setShowAlert,
    showAlert,
    setIsCustomFormVisible,
    totalPages,
    size,
    isLoading,
    ...otherProps
  } = props

  const [selectedItemToDelete, setSelectedItemToDelete] = useState<ModelData | null>(null)

  const scrollRef = useRef<SimpleBar>(null)

  const handleAdditionalButtonClick = () => {
    if (customForm) {
      setSelectedItemToDelete(null)
      methods?.reset({})
      setIsCustomFormVisible((prev) => !prev)
    }
  }

  useEffect(() => {
    if (!scrollRef.current) return () => null

    const scrollElement = scrollRef.current.getScrollElement()

    const scrollAction = async (e: Event) => {
      const target = e.target as HTMLElement

      const isEndScroll = target.scrollHeight - target.scrollTop <= target.clientHeight + 20

      if (isEndScroll && !isLoading && size < totalPages) {
        e.preventDefault()

        await loadMoreFn()
      }
    }

    scrollElement.addEventListener('scroll', scrollAction)

    return () => scrollElement.removeEventListener('scroll', scrollAction)
  }, [scrollRef.current, visible, loadMoreFn, size, totalPages])

  return (
    <ModalWithForm
      testId='selection-modal'
      visible={visible}
      onCancel={onCancel}
      title={isCustomFormVisible ? customFormTitle : label}
      icon={isCustomFormVisible ? customFormIcon : icon}
      confirmLabel={methods?.getValues('id') ? t('Save Changes') : submitLabel}
      methods={methods}
      onConfirm={onConfirm}
      additionalButtonLabel={customForm && (isCustomFormVisible ? t('Go Back') : t('Add New'))}
      additionalButtonClick={handleAdditionalButtonClick}
    >
      <ModalListContent
        setSelectedItemToDelete={setSelectedItemToDelete}
        scrollRef={scrollRef}
        selectedItemToDelete={selectedItemToDelete}
        setShowAlert={setShowAlert}
        showAlert={showAlert}
        setIsCustomFormVisible={setIsCustomFormVisible}
        isCustomFormVisible={isCustomFormVisible}
        customForm={customForm}
        totalPages={totalPages}
        size={size}
        {...otherProps}
      />
    </ModalWithForm>
  )
}
