import React from 'react'
import { type RouteObject } from 'react-router-dom'

import ForgotPassword from 'pages/auth/forgot-password/forgot-password'
import Login from 'pages/auth/login/login'
import Logout from 'pages/auth/logout/logout'
import ResetPassword from 'pages/auth/reset-password/reset-password'
import VerifyOtpCode from 'pages/auth/verify-otp-code/verify-otp-code'

import RedirectRoute from '../redirect-route'

const AuthRoutes: RouteObject[] = [
  {
    path: 'account',
    element: <RedirectRoute route='account' redirectTo='/account/login' />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'verify-otp-code', element: <VerifyOtpCode /> },
      { path: 'logout', element: <Logout /> },
      { path: 'forgot-password', element: <ForgotPassword /> },
      { path: 'reset-password', element: <ResetPassword /> },
    ],
  },
]

export default AuthRoutes
