import { profilePath } from 'config/paths'
import { translate as t } from 'locales/i18n'

import { ProfileOption } from './types'

export const PROFILE_MENUS: ProfileOption[] = [
  {
    label: t('Setting'),
    icon: 'icon icon-16px icon-setting',
    redirectTo: profilePath(),
  },
  {
    label: t('Logout'),
    icon: 'mdi mdi-logout',
    redirectTo: '/account/logout',
  },
]
