import React from 'react'
import { useTranslation } from 'react-i18next'

import { useToggle, useViewport } from 'hooks'
import { toggleSidebarMode } from 'stores/app-state-store.actions'

export const SidebarToggle = () => {
  const { t } = useTranslation()

  const { width } = useViewport()
  const [isMobileMenuOpened, toggleMobileMenu] = useToggle()

  const openMobileMenu = () => {
    if (!document.body) return

    toggleMobileMenu()

    if (isMobileMenuOpened) {
      document.body.classList.remove('sidebar-enable')
    } else {
      document.body.classList.add('sidebar-enable')
    }
  }

  const toggleSidebarMenu = () => {
    if (width < 768) return openMobileMenu()

    toggleSidebarMode()
  }

  return (
    <div className='button-menu-container'>
      <button
        className='button-menu-mobile open-left'
        onClick={toggleSidebarMenu}
        aria-label={t('Toggle Sidebar')}
      >
        <i className='icon-menu' />
      </button>
    </div>
  )
}
