export function trimSlash(path: string): string {
  return path.replace(/(?<!:)\/{2,}/g, '/')
}

export const generateGoogleMapUrl = (location: Record<string, any>) => {
  if (!location) return ''

  const latLng = `${location.latitude},${location.longitude}`

  return `https://www.google.com/maps/dir//${latLng}/@${latLng},17z`
}

export function findFirstValue(obj: Record<string, any>): string {
  const firstKey = Object.keys(obj || {})[0]
  const firstValue = obj[firstKey]

  if (typeof firstValue === 'object' && firstValue !== null) {
    return findFirstValue(firstValue)
  } else {
    return firstValue
  }
}
