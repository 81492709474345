import { proxy } from 'valtio'

import { computeToastModel } from './toast-model.computed'
import { ToastModel } from './toast-model.types'

export const initialToastModel: ToastModel = proxy({
  variant: 'success',
  body: null,
  title: null,
})

export const toastModel = {
  ...initialToastModel,
  ...computeToastModel(initialToastModel),
}
