import React from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import Dropzone from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import { FileUploaderProps } from './file-uploader.types'
import useFileUploader from './use-file-uploader'

export const FileUploader = ({
  showPreview = true,
  multiSelect = false,
  acceptedFile = 'image/*',
  onFileUpload,
  hint,
  isInvalid,
  name,
  value,
}: FileUploaderProps) => {
  const { handleAcceptedFiles, removeFile } = useFileUploader(multiSelect, value, onFileUpload)
  const { t } = useTranslation()

  return (
    <>
      <Dropzone
        multiple={multiSelect}
        maxFiles={multiSelect ? 0 : 1}
        accept={acceptedFile}
        onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles)}
      >
        {({ getRootProps, getInputProps }) => {
          return (
            <div
              className={`dropzone center ${isInvalid ? 'border-danger' : ''}`}
              data-test-id={name}
              {...getRootProps()}
            >
              <div className='dz-message'>
                <input {...getInputProps()} />
                <i className='h2 icon icon-image text-primary'></i>
                <h5 className='fw-medium'>{t('Drop files here or click to upload.')}</h5>

                {hint && (
                  <Form.Text className='font-14' muted>
                    {hint}
                  </Form.Text>
                )}
              </div>
            </div>
          )
        }}
      </Dropzone>

      {showPreview && Array.isArray(value) && (
        <div className='dropzone-previews mt-3' id='uploadPreviewTemplate'>
          {value.map((file, index) => (
            <Card className='mt-1 mb-0 shadow-none border' key={index + '-file'}>
              <div className='p-2'>
                <Row className='align-items-center'>
                  {file.preview && (
                    <Col className='col-auto pe-0'>
                      <img
                        data-dz-thumbnail=''
                        className='avatar-sm rounded bg-light'
                        alt={file.name}
                        src={file.preview}
                      />
                    </Col>
                  )}
                  {!file.preview && file.type && (
                    <Col className='col-auto'>
                      <div className='avatar-sm'>
                        <span className='avatar-title bg-primary rounded'>
                          {file.type.split('/')[0]}
                        </span>
                      </div>
                    </Col>
                  )}
                  <Col>
                    {(file.name || file.preview) && (
                      <span className='text-raisin-black fw-normal'>
                        {file.name || file.preview.split('/')[file.preview.split('/').length - 1]}
                      </span>
                    )}
                    {file.formattedSize && (
                      <p className='mb-0 text-secondary fw-normal'>{file.formattedSize}</p>
                    )}
                  </Col>
                  <Col className='text-end'>
                    <Button
                      variant='link'
                      className='btn btn-link btn-lg text-muted shadow-none'
                      onClick={() => removeFile(value, index)}
                    >
                      <i className='mdi mdi-close'></i>
                    </Button>
                  </Col>
                </Row>
              </div>
            </Card>
          ))}
        </div>
      )}
    </>
  )
}
