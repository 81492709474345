/* eslint-disable prefer-const */
import * as Sentry from '@sentry/react'
import React from 'react'
import { createRoot, hydrateRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import { IS_APP_SUBDOMAIN, IS_PROD, SENTRY_DSN } from 'config/env'
import AppRoutes from 'routes/app-routes'

import { App } from './app'

if (IS_PROD && SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 0.5,
  })
}

const APP = (
  <BrowserRouter>
    <App />
  </BrowserRouter>
)

const root = document.getElementById('app')

if (IS_APP_SUBDOMAIN) {
  hydrateRoot(
    root,
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  )
} else {
  createRoot(root).render(APP)
}
