import classNames from 'classnames'
import React from 'react'

type TimelineProps = {
  tag?: React.ElementType
  className?: string
  children: React.ReactNode
}

export const Timeline = ({ className, children, tag = 'div' }: TimelineProps) => {
  const Tag: React.ElementType = tag

  return <Tag className={classNames('timeline', className)}>{children}</Tag>
}
