import { StylesConfig } from 'react-select'

const dot = (color?: string) => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    cursor: 'pointer',
    border: '2px solid white',
    boxShadow: '0 0 2px #888',
    backgroundColor: color,
    borderRadius: 20,
    content: '" "',
    display: 'block',
    marginRight: 10,
    marginLeft: 2,
    height: 20,
    width: 20,
  },

  input: {
    cursor: 'default',
  },
})

export const colorStyles: StylesConfig<Record<string, any>> = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data }) => ({ ...styles, ...dot(data.hex) }),
  input: (styles) => ({ ...styles, ...dot() }),
  placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.hex) }),
}
