import classNames from 'classnames'
import dayjs from 'dayjs'
import React, { useMemo, useRef } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { useController, useFormContext } from 'react-hook-form'

import 'react-datepicker/dist/react-datepicker.css'

import { DateRangeInputProps } from './date-range-input.types'

export const DateRangeInput = (props: DateRangeInputProps) => {
  const { label, containerClass, placeholder, startName, endName, dateFormat } = props

  const ref = useRef<DatePicker>(null)

  const { control } = useFormContext()

  const {
    field: { onChange: onStartDateChange, value: startDate },
    fieldState: { invalid: startInvalid, error: startError },
  } = useController({ control, name: startName })

  const {
    field: { onChange: onEndDateChange, value: endDate },
    fieldState: { invalid: endInvalid, error: endError },
  } = useController({ control, name: endName })

  const error = useMemo(() => endError || startError, [endError, startError])
  const invalid = useMemo(() => endInvalid || startInvalid, [endInvalid, startInvalid])

  const onChangeDate = (date: [Date, Date]) => {
    const formatDate = (date: Date) =>
      dateFormat ? dayjs(date).format(dateFormat) : dayjs(date).toISOString()

    const startDate = date[0] ? formatDate(date[0]) : null
    const endDate = date[1] ? formatDate(date[1]) : null

    onStartDateChange(startDate)
    onEndDateChange(endDate)
  }

  return (
    <Form.Group className={containerClass}>
      <Form.Label>{label}</Form.Label>
      <InputGroup
        className={classNames('mb-1 date-picker-input-group', {
          'is-invalid': invalid,
        })}
      >
        <DatePicker
          ref={ref}
          className={classNames('form-control', {
            'is-invalid': invalid,
          })}
          startDate={startDate && new Date(startDate)}
          endDate={endDate && new Date(endDate)}
          placeholderText={placeholder}
          onChange={onChangeDate}
          isClearable
          selectsRange
        />

        <div
          role='button'
          className='input-group-text input-group-password'
          onClick={() => ref.current?.setFocus()}
        >
          <i className='icon-calendar icon icon-20px' />
        </div>
      </InputGroup>

      {error && (
        <Form.Control.Feedback className='d-block m-0' type='invalid'>
          {error.message}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  )
}
