import classNames from 'classnames'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import { toastStore } from 'stores/toast-store'
import { hideBasicToast } from 'stores/toast-store.actions'

export const BasicToast = () => {
  const { t } = useTranslation()
  const { body, displayTitle, icon, variant, visible } = useSnapshot(toastStore.basic)

  return (
    <Modal show={visible} onHide={hideBasicToast} size='sm'>
      <div className={classNames('modal-filled', 'bg-' + variant)}>
        <Modal.Body className='p-4'>
          <div className='text-center'>
            <i className={classNames('h1', icon)}></i>
            <h4 className='mt-2'>{displayTitle}</h4>
            <p className='mt-2'>{body}</p>
            <Button variant='outline-light' className='mt-3 mb-2' onClick={hideBasicToast}>
              {t('Dismiss')}
            </Button>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  )
}
