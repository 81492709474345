import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { parseApiErrors } from 'components'
import { useToast } from 'hooks/use-toast.hook'
import { ApiErrorKind, authApi } from 'services/api'
import { InferType } from 'utils/typescript'

export function useForgotPassword() {
  const [email, setEmail] = useState<string>(null)

  const { showToast, showUnexpectedToast } = useToast()

  const schema = yup.object().shape({
    email: yup.string().ensure().required().email(),
  })

  const methods = useForm<InferType<typeof schema>>({ resolver: yupResolver(schema) })

  const onSubmit = async (data: InferType<typeof schema>) => {
    try {
      const { ok } = await authApi.forgotPassword(data)

      if (ok) setEmail(data.email)
    } catch (error: any) {
      if (error.kind === ApiErrorKind.REJECTED) {
        if (!Array.isArray(error.data)) {
          showToast({ body: error.data, variant: 'danger' })
        } else {
          parseApiErrors({
            error: error.data,
            methods,
          })
        }
      } else {
        showUnexpectedToast()
      }
    }
  }

  return { methods, onSubmit, email }
}

type ForgotPasswordHook = ReturnType<typeof useForgotPassword>
export type ForgotPasswordFormProps = Omit<ForgotPasswordHook, 'email'>
