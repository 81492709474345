import kebabCase from 'lodash/kebabCase'
import React, { Fragment, ReactNode } from 'react'
import { Modal } from 'react-bootstrap'
import { FormProvider, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ActionButton } from 'components/button/action-button'

import { SubmitButton } from '../button/submit-button'

type ModalWithFormProps = {
  title: string
  children: ReactNode
  visible?: boolean
  onCancel: () => void
  onConfirm: () => void
  confirmLabel?: string
  variant?: string
  testId?: string
  icon?: string
  additionalButtonLabel?: string
  additionalButtonClick?: () => void
  methods: UseFormReturn<any, any>
}

export const ModalWithForm = (props: Partial<ModalWithFormProps>) => {
  const { t } = useTranslation()
  const {
    title,
    children,
    visible,
    onCancel,
    onConfirm,
    additionalButtonClick,
    additionalButtonLabel,
    confirmLabel = t('Confirm'),
    testId = 'confirmation-modal',
    icon = 'icon-check',
    methods,
  } = props

  const onConfirmModal = () => {
    if (onConfirm) onConfirm()
  }

  const Container = methods ? FormProvider : Fragment

  return (
    <Container {...methods}>
      <Modal show={visible} onHide={onCancel} centered data-test-id={kebabCase(testId)} size='lg'>
        <Modal.Body className='rounded-3 text-center'>
          <div className='d-flex justify-content-between align-items-center w-100 mb-2'>
            <div className='d-flex gap-2 h-100 align-items-center'>
              <div className='bg-body rounded-circle text-raisin-black background-icon-title d-flex align-items-center justify-content-center'>
                <i className={`icon ${icon}`} />
              </div>

              <h5 className='text-raisin-black fw-semibold'>{title}</h5>
            </div>

            <button
              className='button-menu-mobile'
              onClick={onCancel}
              aria-label={t('Toggle Modal')}
            >
              <i className='icon-close' />
            </button>
          </div>

          <div className='w-100'>{children}</div>

          <div className='d-flex justify-content-between flex-wrap w-100'>
            {additionalButtonLabel && (
              <ActionButton
                className='text-raisin-black'
                variant='outline-gray-light'
                label={additionalButtonLabel}
                onClick={additionalButtonClick}
              />
            )}
            <SubmitButton
              data-test-id='confirm-modal-form'
              className='ms-auto rounded-4 fw-semibold py-2'
              variant='primary'
              loading={methods?.formState?.isSubmitting}
              onClick={onConfirmModal}
            >
              {confirmLabel}
            </SubmitButton>
          </div>
        </Modal.Body>
      </Modal>
    </Container>
  )
}
