import React from 'react'
import { Button, ButtonProps } from 'react-bootstrap'

type ActionButtonProps = {
  label?: string
  icon?: string
  visible?: boolean
} & ButtonProps

export const ActionButton = (props: ActionButtonProps) => {
  const { label, icon, visible = true, className, ...buttonProps } = props

  if (!visible) return null

  return (
    <Button
      className={`d-flex gap-1 align-items-center rounded-4 fw-semibold button-action ${className}`}
      {...buttonProps}
    >
      {label && <span>{label}</span>}
      {icon && <i className={`align-middle icon-20px icon ${icon}`} />}
    </Button>
  )
}
