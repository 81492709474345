/* eslint-disable @typescript-eslint/ban-types */
import { ApiCore } from './api-core'

export class AppConfigApi extends ApiCore {
  protected pathPrefix = 'app-configs'

  async cloudfrontURL(payload = {}) {
    return await this.put({
      path: 'cloudfront',
      payload,
    })
  }

  async supportedVersion(payload = {}) {
    return await this.put({
      path: 'supported-version',
      payload,
    })
  }

  async configType(configType: string, configStatus: string) {
    return await this.put({
      path: `${configType}/${configStatus}`,
    })
  }
}

export const appConfigApi = new AppConfigApi()
