import React from 'react'
import { useTranslation } from 'react-i18next'

import { ControlledInput, FormProvider, SubmitButton } from 'components'

import { ForgotPasswordFormProps } from './forgot-password.hook'

export const ForgotPasswordForm = (props: ForgotPasswordFormProps) => {
  const { t } = useTranslation('auth')

  const { methods, onSubmit } = props

  return (
    <FormProvider methods={methods} onSubmit={onSubmit} name='forgot-password'>
      <div className='m-auto text-center'>
        <h4 className='text-dark-50 mt-0 fw-bold'>{t('Forgot Password')}</h4>
        <p className='text-muted mb-4 text-balance'>
          {t('We will send you an email with a link to create a new password.')}
        </p>
      </div>

      <ControlledInput
        label={t('Email')}
        name='email'
        containerClass='mb-3'
        autoComplete='email'
        autoSave='email'
        placeholder={t('Enter your email')}
      />

      <div className='text-center'>
        <SubmitButton loading={methods.formState.isSubmitting}>{t('Send Email')}</SubmitButton>
      </div>
    </FormProvider>
  )
}
