import React from 'react'
import { Link } from 'react-router-dom'

import { BaseDetailItem } from './base-detail-item'
import { LinkDetailItemProps } from './detail-item.types'

export const LinkDetailItem = (props: LinkDetailItemProps) => {
  const { value, path, externalUrl, disableLink, ...otherProps } = props

  const renderInternalUrl = () => (
    <Link to={value ? path : '#'} className='fw-medium text-raisin-black max-width-100'>
      {value}
    </Link>
  )

  const renderExternalUrl = () => (
    <a
      href={value ? path : '#'}
      className='fw-medium text-raisin-black max-width-100'
      rel='noopener noreferrer'
    >
      {value}
    </a>
  )

  const renderValue = () => {
    if (!value) return null

    if (disableLink) return <div>{value}</div>

    return externalUrl ? renderExternalUrl() : renderInternalUrl()
  }

  return <BaseDetailItem {...otherProps}>{renderValue()}</BaseDetailItem>
}
