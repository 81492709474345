{
  "Set the users as a beta tester to test the mobile app": "Set the users as a beta tester to test the mobile app",
  "Allow users to receive a code via SMS OTP": "Allow users to receive a code via SMS OTP",
  "Cloudfront URL": "Cloudfront URL",
  "AWS Cloudfront": "AWS Cloudfront",
  "Edit URL": "Edit URL",
  "Supported Apps": "Supported Apps",
  "Edit Version": "Edit Version",
  "App Version": "App Version",
  "Are you sure want to change the status?": "Are you sure want to {{ configStatus }} the {{ configType }} feature?",
  "You’ll change the feature status by clicking the save changes button": "You’ll change the feature status by clicking the save changes button",
  "Feature status updated successfully": "Feature status updated successfully",
  "App": "App",
  "Cloudfront URL updated successfully": "Cloudfront URL updated successfully",
  "Supported app version updated successfully": "Supported app version updated successfully"
}
