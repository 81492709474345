import classNames from 'classnames'
import React from 'react'
import { Badge } from 'react-bootstrap'

import { BaseDetailItem } from './base-detail-item'
import { BadgeDetailItemProps } from './detail-item.types'

export const BadgeDetailItem = (props: BadgeDetailItemProps) => {
  const { value, bg, badgeOutline, badgeTextColor, badgeClass, ...otherProps } = props

  return (
    <BaseDetailItem {...otherProps}>
      {value && (
        <Badge
          className={classNames(
            'rounded-pill px-2 py-1 text-capitalize',
            {
              [`border-${badgeOutline} border-1 border`]: badgeOutline,
              [`text-${badgeTextColor}`]: badgeTextColor,
            },
            badgeClass
          )}
          bg={bg}
        >
          {value}
        </Badge>
      )}
    </BaseDetailItem>
  )
}
