import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import * as yup from 'yup'

import { parseApiErrors } from 'components'
import { useToast } from 'hooks/use-toast.hook'
import { ApiErrorKind, authApi } from 'services/api'
import { authStore } from 'stores/auth-store'
import { InferType } from 'utils/typescript'

export function useResetPassword() {
  const navigate = useNavigate()
  const token = useSearchParams()[0].get('token')

  const { t } = useTranslation('auth')

  const { showToast, showUnexpectedToast } = useToast()

  const schema = yup.object().shape({
    newPassword: yup.string().required().password(8),
    confirmPassword: yup
      .string()
      .required()
      .oneOf([yup.ref('newPassword'), null]),
  })

  const methods = useForm<InferType<typeof schema>>({ resolver: yupResolver(schema) })

  const navigateToForgotPassword = () => {
    navigate('/account/forgot-password', { replace: true })
  }

  const navigateToLogin = () => {
    navigate('/account/login', { replace: true })
  }

  const onSubmit = async (data: InferType<typeof schema>) => {
    try {
      const { ok } = await authApi.resetPassword(data)

      if (ok) {
        showToast({
          variant: 'success',
          body: t('Your password is updated, please login with your new password.'),
        })
        navigateToLogin()
      }
    } catch (error: any) {
      if (error.kind === ApiErrorKind.REJECTED) {
        if (!Array.isArray(error.data)) {
          showToast({ body: error.data, variant: 'danger' })
        } else {
          parseApiErrors({
            error: error.data,
            methods,
          })
        }
      } else {
        showUnexpectedToast()
      }
    }
  }

  useEffect(() => {
    authStore.state.token = token

    if (!token) navigateToForgotPassword()
  }, [token])

  return { methods, onSubmit }
}
