import classNames from 'classnames'
import React from 'react'
import { Breadcrumb, Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

type BreadcrumbItems = {
  label: string
  path: string
  active?: boolean
}

type PageTitleProps = {
  breadCrumbItems: Array<BreadcrumbItems>
  title: string
}

export const PageTitle = ({ breadCrumbItems, title }: PageTitleProps) => {
  const { t } = useTranslation()

  return (
    <Row>
      <Col>
        <div className='page-title-box'>
          <div className='page-title-right'>
            <Breadcrumb listProps={{ className: 'm-0' }}>
              <li className='breadcrumb-item'>
                <Link to='/dashboard'>{t('Dashboard')}</Link>
              </li>
              {breadCrumbItems.map((item, index) => (
                <li
                  key={index.toString()}
                  className={classNames('breadcrumb-item', { active: item.active })}
                >
                  <Link to={item.path}>{item.label}</Link>
                </li>
              ))}
            </Breadcrumb>
          </div>
          <h4 className='page-title'>{title}</h4>
        </div>
      </Col>
    </Row>
  )
}
