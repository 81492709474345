{
  "Created At": "Create Date",
  "First Name": "Name",
  "Id": "",
  "Updated At": "Last Update",
  "User Status": "Status",
  "No Data Found": "No Data Found",
  "Sorry, no data were found.": "Sorry, no data were found.",
  "You might want to add one now?": "You might want to add one now?",
  "Type": "Type",
  "Search Name, Email, etc": "Search Name, Email, etc",
  "Creator Name": "Creator",
  "Member Count": "Member",
  "Email Address": "Email",
  "Title": "Name",
  "Apps": "Apps"
}
