{
  "Story Detail": "Story Detail",
  "Group Creator": "Group Creator",
  "Uploader": "Uploader",
  "Media": "Media",
  "Are you sure want to delete this story?": "Are you sure want to delete this story?",
  "You’ll delete the story permanently by clicking the delete button": "You’ll delete the story permanently by clicking the delete button",
  "Delete Story": "Delete Story",
  "Failed to delete story item": "Failed to delete story item",
  "Story item is successfully deleted": "Story item is successfully deleted",
  "Failed to delete story": "Failed to delete story",
  "Story is successfully deleted": "Story is successfully deleted",
  "You’ll delete the story item permanently": "You’ll delete the story item permanently",
  "Want to delete this story?": "Want to delete this story?",
  "Select a media count range": "Select a media count range",
  "Select an uploader count range": "Select an uploader count range",
  "Search Group Name..": "Search Group Name..",
  "Story ID": "Story ID",
  "Story Item ID": "Story Item ID",
  "Story Item Flagged": "Story Item Flagged"
}
