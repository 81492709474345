import React from 'react'
import { Badge } from 'react-bootstrap'

import { RoundedImage } from 'components/rounded-image/rounded-image'

type BadgeSelectedItemProps = {
  avatarImage?: string
  avatarIcon?: string
  name: string
  onDelete: () => void
}
export const BadgeSelectedItem = (props: BadgeSelectedItemProps) => {
  const { avatarIcon, avatarImage, name, onDelete } = props

  return (
    <Badge className='rounded-pill text-raisin-black' bg='body'>
      <div className='d-flex gap-1 align-items-center'>
        {avatarIcon ? (
          <i className={`avatar-icon icon-16px icon rounded-circle bg-gray-light ${avatarIcon}`} />
        ) : (
          <RoundedImage size='sm' image={avatarImage} alt={name} />
        )}
        <span className='fw-semibold'>{name}</span>

        <i className='icon icon-close icon-20px text-secondary' role='button' onClick={onDelete} />
      </div>
    </Badge>
  )
}
