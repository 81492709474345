import { MultiValue } from 'react-select'
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters'

import { OptionProps, OptionRangeProps, OptionsProps } from './select.types'

export const convertValuesToString = (collection: OptionsProps): string | null => {
  if (!collection.length) return null

  const valueArr = collection.map((item) => item.value)

  return valueArr.join(',')
}

export const getValuesFromString = <T extends OptionProps>(
  values: string,
  source: MultiValue<T>
): MultiValue<T> => {
  if (!values) return []

  const valueArr = values.split(',')

  const options = valueArr.reduce((coll, value) => {
    const option = source?.filter((item) => item.value.toLowerCase() === value.toLowerCase())[0]

    if (option) coll.push(option)

    return coll
  }, [])

  return options
}

export const deepSearchSelect = <T extends object>(
  option: Pick<FilterOptionOption<T>, 'data'>,
  inputValue: string
) => {
  const search = inputValue.toLowerCase()

  if (!option.data) return false

  for (const key in option.data) {
    const value = option.data[key]

    if (typeof value === 'object') deepSearchSelect({ data: value }, inputValue)

    if (typeof value === 'string') {
      if (value.toLowerCase().includes(search)) return true
    }
  }

  return false
}

export const getValuesRangeFromString = <T extends OptionRangeProps>(
  startValue: string,
  endValue: string,
  source: MultiValue<T>
): MultiValue<T> => {
  if (!startValue && !endValue) return []

  const option = source?.filter(
    (item) =>
      item.startValue.toLowerCase() === startValue.toLowerCase() ||
      item.endValue?.toLowerCase() === endValue?.toLowerCase()
  )

  return option
}
