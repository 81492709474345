import classNames from 'classnames'
import React, { useEffect, useMemo, useRef } from 'react'

type AvatarProps = {
  fallbackText: string
  containerClassName?: string
  textClassName?: string
  onRender?: () => void
  src?: string
} & React.HTMLAttributes<HTMLDivElement>

export const Avatar = (props: AvatarProps) => {
  const { fallbackText, containerClassName, textClassName, onRender, src, ...rest } = props
  const avatarRef = useRef<HTMLDivElement>(null)

  const fallbackInitial = useMemo(() => {
    if (!fallbackText) return null

    const matches = fallbackText.match(/\b(\w)/g)

    if (!matches) return null

    return matches.slice(0, 2).join('').toUpperCase()
  }, [fallbackText])

  useEffect(() => {
    if (avatarRef.current && onRender && !src) {
      onRender()
    }
  }, [avatarRef.current])

  if (src) {
    return (
      <img
        src={src}
        alt={fallbackText}
        className={containerClassName}
        onLoad={() => onRender?.()}
      />
    )
  }

  return (
    <div ref={avatarRef} className={containerClassName} {...rest}>
      <h1 className={classNames(textClassName, 'default-avatar-text')}>{fallbackInitial}</h1>
    </div>
  )
}
