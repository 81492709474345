import { Dispatch, ReactNode, RefObject, SetStateAction } from 'react'
import { UseFormReturn } from 'react-hook-form'

type ActionButton = {
  buttonComponent?: ReactNode
  deleteButtonComponent?: ReactNode
  editButtonButton?: ReactNode
}

export type ColumnItem<ModelData> = {
  cell: (data: ModelData, button?: ActionButton) => ReactNode
}

export type SelectItemCardProps<ModelData> = {
  name: string
  submitLabel?: string
  totalPages?: number
  size?: number
  data: ModelData[]
  icon?: string
  label?: string
  cardTitle?: string
  cardDescription?: string
  columns?: ColumnItem<ModelData>[]
  setSearch?: (value: string) => void
  search?: string
  isMulti?: boolean
  avatarImage?: keyof ModelData
  avatarName?: keyof ModelData
  customDetailCard?: ColumnItem<ModelData>
  nextFn?: () => void
  backFn?: () => void
  loadMoreFn?: () => Promise<any[]>
  nextLabel?: string
  backLabel?: string
  avatarIcon?: string
  maxSelectedItems?: number
  changeButtonLabel?: string
  bannerImage?: string
  customForm?: ReactNode
  customFormConfirm?: () => boolean | Promise<void>
  customFormIcon?: string
  customFormTitle?: string
  methods?: UseFormReturn<any, any>
  customListComponent?: ColumnItem<ModelData>
  mutate?: () => void
  isLoading?: boolean
  withCountLabel?: boolean
  deleteConfirmLabel?: string
  deleteCancelLabel?: string
  deleteFn?: (item: ModelData) => Promise<void>
  editFn?: (item: ModelData) => void
  deleteTitle?: string
  deleteDescription?: string
  searchPlaceholder?: string
}

export type ModalListItemProps<ModelData> = {
  isCustomFormVisible?: boolean
  onConfirm: () => void
  onCancel: () => void
  visible: boolean
  initialData: ModelData | ModelData[]
  setInitialData: Dispatch<SetStateAction<ModelData | ModelData[]>>
  setIsCustomFormVisible: Dispatch<SetStateAction<boolean>>
  showAlert?: boolean
  setShowAlert?: Dispatch<SetStateAction<boolean>>
} & Partial<SelectItemCardProps<ModelData>>

export type ModalListContentProps<ModelData> = {
  setSelectedItemToDelete: Dispatch<SetStateAction<ModelData>>
  scrollRef: RefObject<any>
  selectedItemToDelete: ModelData
} & Partial<ModalListItemProps<ModelData>> &
  Partial<SelectItemCardProps<ModelData>>
