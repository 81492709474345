import React, { HTMLAttributes, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { OverlayChildren } from 'react-bootstrap/esm/Overlay'
import { useTranslation } from 'react-i18next'

type ClipboardProps = {
  data: string
  iconOnly?: boolean
  label?: string
} & HTMLAttributes<HTMLDivElement>

export const Clipboard = ({ data, iconOnly, label, ...otherProps }: ClipboardProps) => {
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false)

  const { t } = useTranslation()

  const renderTooltip: OverlayChildren = ({ style, ...otherProps }) => {
    const styles = { ...style, position: 'fixed' } as any

    return (
      <Tooltip id='tooltip-copy-clipboard' style={styles} {...otherProps}>
        {t('Copied!')}
      </Tooltip>
    )
  }

  const copyToClipboard = () =>
    navigator.clipboard
      .writeText(data)
      .then(() => setTooltipVisible(true))
      .finally(() => setTimeout(() => setTooltipVisible(false), 1000))

  const icon = (
    <i
      className='icon icon-copy icon-18px'
      role='button'
      onMouseLeave={() => setTooltipVisible(false)}
      onClick={copyToClipboard}
    />
  )

  return (
    <OverlayTrigger placement='bottom' show={tooltipVisible} overlay={renderTooltip}>
      {iconOnly ? (
        icon
      ) : (
        <div onClick={copyToClipboard} {...otherProps}>
          <span className='text-break text-start'>{label}</span> {icon}
        </div>
      )}
    </OverlayTrigger>
  )
}
