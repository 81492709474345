import classNames from 'classnames'
import React from 'react'
import { Form, FormControlProps, InputGroup } from 'react-bootstrap'
import { useController, useFormContext } from 'react-hook-form'

type InputProps = FormControlProps & {
  label?: string
  name: string
  containerClass?: string
  step?: number
  min?: number
  max?: number
  icon?: string
} & React.InputHTMLAttributes<HTMLInputElement>

export const ControlledInput = (props: InputProps) => {
  const { label, name, containerClass, children, step, icon, ...otherProps } = props

  const { register, control } = useFormContext()
  const {
    fieldState: { invalid, error },
  } = useController({ control, name, defaultValue: null })

  return (
    <Form.Group className={containerClass}>
      {label && <Form.Label>{label}</Form.Label>}
      <InputGroup
        className={classNames('align-items-center', {
          'is-invalid': invalid,
          'group-float': icon,
        })}
      >
        <Form.Control
          name={name}
          id={name}
          as='input'
          isInvalid={invalid}
          step={step}
          {...register(name)}
          {...otherProps}
          autoComplete={name}
          data-test-id={name}
        >
          {children}
        </Form.Control>
        {icon && <i className={`me-2 icon ${icon}`} />}
      </InputGroup>

      {error && <Form.Control.Feedback type='invalid'>{error.message}</Form.Control.Feedback>}
    </Form.Group>
  )
}
