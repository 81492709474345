import React from 'react'

type LoaderProps = {
  preset?: 'centered' | 'cover-page'
  size?: 'md' | 'lg' | 'sm'
}

export const Loader = ({ preset = 'centered', size = 'md' }: LoaderProps) => (
  <div className={`preloader ${preset}`}>
    <div className={`bouncing-loader  size-${size}`}>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
)
