import React, { DetailedHTMLProps, ImgHTMLAttributes, useState } from 'react'

import { placeholder } from 'assets/images'

export const ImageLoader = (props: DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, any>) => {
  const [imageLoaded, setImageLoaded] = useState<boolean>(false)

  const handleImageLoad = () => {
    setImageLoaded(true)
  }

  return <img {...props} src={!imageLoaded ? placeholder : props.src} onLoad={handleImageLoad} />
}
