import omit from 'lodash/omit'
import React, { ReactNode, useMemo } from 'react'
import { Card, Col, ColProps } from 'react-bootstrap'

import {
  DetailCardHeader,
  DetailCardHeaderProps,
} from 'components/detail-card-header/detail-card-header'

type DetailCardProps = {
  children: ReactNode
  singleCard?: boolean
} & ColProps &
  DetailCardHeaderProps

export const DetailCard = (props: DetailCardProps) => {
  const { title, children, singleCard, ...detailCardHeaderProps } = props

  const colProps = omit(detailCardHeaderProps, [
    'goBackPath',
    'statusOptions',
    'optionValue',
    'editPath',
    'showDeleteModal',
    'showUpdateModal',
  ])

  const cardBody = useMemo(
    () => (
      <Card.Body className='card-details p-4'>
        <DetailCardHeader {...detailCardHeaderProps} />

        {children}
      </Card.Body>
    ),
    [title, children]
  )

  if (singleCard) return <Card className='card-details'>{cardBody}</Card>

  return (
    <Col {...colProps}>
      <Card className='card-details'>{cardBody}</Card>
    </Col>
  )
}
