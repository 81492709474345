import { FileType } from './file-uploader.types'

export async function exportToBase64(files: FileType[]): Promise<FileType[]> {
  const processedFiles = await Promise.all(
    files.map(async (file) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)

      return new Promise<FileType>((resolve) => {
        reader.onload = () => {
          const base64Data = reader.result

          resolve(Object.assign(file, { base64Data }))
        }
      })
    })
  )

  return processedFiles
}
