import dayjs from 'dayjs'
import { derive } from 'valtio/utils'

import { translate as t } from 'locales/i18n'
import { formatDate } from 'utils/formatter'

import { EventModel } from './event.types'
import { computeGroup } from './group.computed'
import { computeMember } from './member.computed'
import { computeRoute } from './route.computed'

export const computeEvent = (state: EventModel) =>
  derive({
    statusIcon: (get) => {
      switch (get(state).status.toLowerCase()) {
        case 'active':
          if (dayjs(get(state).date).isAfter(dayjs())) return 'icon icon-clock icon-18px'

          return 'icon icon-check icon-18px'
        case 'cancelled':
          return 'icon icon-close icon-18px'
        default:
          return ''
      }
    },
    statusColor: (get) => {
      switch (get(state).status.toLowerCase()) {
        case 'active':
          if (dayjs(get(state).date).isAfter(dayjs())) return 'yellow'

          return 'primary-light'
        case 'cancelled':
          return 'danger-light'
        default:
          return ''
      }
    },
    statusLabel: (get) => {
      switch (get(state).status.toLowerCase()) {
        case 'active':
          if (dayjs(get(state).date).isAfter(dayjs())) return t('Upcoming')

          return get(state).status
        default:
          return get(state).status
      }
    },
    routes: (get) => get(state).routes.map((route) => ({ ...route, ...computeRoute(route) })),
    group: (get) => ({ ...get(state).group, ...computeGroup(get(state).group) }),
    creator: (get) => ({ ...get(state).creator, ...computeMember(get(state).creator) }),
    dateFormatted: (get) => formatDate(get(state).date, 'date-month-abbreviation'),
    maybeCountAttendance: (get) =>
      (get(state).attendees || []).filter(({ attendance }) => attendance === 'Maybe').length,
    outCountAttendance: (get) =>
      (get(state).attendees || []).filter(({ attendance }) => attendance === 'Out').length,
    inCountAttendance: (get) =>
      (get(state).attendees || []).filter(({ attendance }) => attendance === 'In').length,
  })
