import { derive } from 'valtio/utils'

import { formatDate, formatDistance, formatDuration } from 'utils/formatter'
import { remoteImage } from 'utils/remote-image'
import { generateGoogleMapUrl } from 'utils/string'

import { RouteModel } from './route.types'

export const computeRoute = (state: RouteModel) =>
  derive({
    distanceFormatted: (get) => formatDistance(get(state).distance),
    durationFormatted: (get) => formatDuration(get(state).duration),
    startTimeFormatted: (get) => formatDate(get(state).startTime, 'time-ampm'),
    routeMapURL: (get) => remoteImage(get(state).routeMapURL),
    location: (get) => ({
      startLocation: {
        ...get(state).startLocation,
        googleMapUrl: generateGoogleMapUrl(get(state).startLocation),
      },
      finishLocation: {
        ...get(state).finishLocation,
        googleMapUrl: generateGoogleMapUrl(get(state).finishLocation),
      },
      coffeeStop: {
        ...get(state).coffeeStop,
        googleMapUrl: generateGoogleMapUrl(get(state).coffeeStop),
      },
    }),
  })
