import React, { useEffect } from 'react'
import { Alert } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { ActionButton } from 'components/button/action-button'

type ConfirmAlertProps = {
  show: boolean
  onCancel: () => void
  onConfirm: () => void
  confirmLabel: string
  variant?: string
  cancelLabel?: string
  title?: string
  description?: string
}

export const ConfirmAlert = (props: ConfirmAlertProps) => {
  const { t } = useTranslation()

  const {
    show,
    cancelLabel = t('Cancel'),
    confirmLabel,
    onCancel,
    onConfirm,
    variant,
    description,
    title,
  } = props

  const onSubmit = () => {
    try {
      onConfirm()
    } finally {
      onCancel()
    }
  }

  useEffect(() => {
    if (!show) return () => null

    const delay = setTimeout(() => {
      onCancel()
    }, 6000)

    return () => clearTimeout(delay)
  }, [show])

  return (
    <Alert
      show={show}
      variant={variant}
      onClose={onCancel}
      className='delete-story-item-alert w-100 d-flex align-items-center justify-content-between text-start mb-0 position-absolute'
    >
      <div className='d-flex gap-2'>
        <div className='bg-danger rounded-circle text-white background-icon-title d-flex align-items-center justify-content-center'>
          <i className='icon icon-trash fw-semibold' />
        </div>

        <div>
          <h5 className='mt-0 mb-1'>{title}</h5>
          <span className='description'>{description}</span>
        </div>
      </div>

      <div className='d-flex gap-1'>
        <ActionButton
          variant='transparent'
          className={`py-1 px-2 text-${variant} btn-outline-${variant} bg-transparent border-0 fw-medium`}
          label={cancelLabel}
          data-test-id='details-cancel-action'
          onClick={onCancel}
        />

        <ActionButton
          variant={variant}
          className='py-1 px-2 fw-medium'
          label={confirmLabel}
          data-test-id='details-confirm-action'
          onClick={onSubmit}
        />
      </div>
    </Alert>
  )
}
