import { createPath } from './path-utils'
import { PathGenerator } from './path.types'

const PROFILE_PATH = '/me'

export const profilePath: PathGenerator = () => {
  return createPath(PROFILE_PATH)
}

export const profileEditPath: PathGenerator = () => {
  return createPath(PROFILE_PATH, { suffix: 'edit' })
}

export const changePasswordPath: PathGenerator = () => {
  return createPath(PROFILE_PATH, { suffix: 'change-password' })
}
