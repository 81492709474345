import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useSnapshot } from 'valtio'
import * as yup from 'yup'

import { useCountdownOTP } from 'hooks/use-countdown-otp'
import { useToast } from 'hooks/use-toast.hook'
import { ApiErrorKind, authApi } from 'services/api'
import { useProfile } from 'services/swr/use-profile'
import { authStore } from 'stores/auth-store'
import { verifyOTP } from 'stores/auth-store.actions'
import { clear, loadString } from 'utils/session-storage'
import { InferType } from 'utils/typescript'

export default function useVerifyOtpCode() {
  const navigate = useNavigate()
  const { t } = useTranslation('auth')
  const { showUnexpectedToast, showToast } = useToast()
  const { mutate } = useProfile()

  const { isAuthenticated } = useSnapshot(authStore.computed)
  const accountId = loadString('accountId')
  const email = loadString('email')

  const { start, countdown, countdownFormatted } = useCountdownOTP()

  const schema = yup.object().shape({
    code: yup.string().ensure().required(),
  })

  const methods = useForm<InferType<typeof schema>>({ resolver: yupResolver(schema) })

  const navigateToDashboard = () => {
    navigate('/analytics/users', { replace: true })
  }

  const onSubmit = async ({ code }: InferType<typeof schema>) => {
    try {
      const loggedIn = await verifyOTP({ code, email })

      if (loggedIn) clear()
    } catch (error: any) {
      if (error.kind === ApiErrorKind.REJECTED || error.kind === ApiErrorKind.UNAUTHORIZED) {
        methods.setError('code', { message: t(error.data || error.message), type: 'custom' })
        return
      }

      showUnexpectedToast()
    }
  }

  const resendOTPCode = async () => {
    try {
      const response = await authApi.resendOTPCode(accountId)

      if (response.ok) start()
    } catch (error: any) {
      showToast({ variant: 'warning', body: error.message || error.data })
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      mutate()
      navigateToDashboard()
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (!email) navigate('/account/login', { replace: true })
  }, [accountId, email])

  return { methods, onSubmit, resendOTPCode, countdown, countdownFormatted }
}
