import useSWR from 'swr'

import { oneHour } from './helpers/millisecond-intervals'
import { collectionAdaptor } from './middleware/collection-adaptor'
import { CountryCollection } from './models/country.types'

export const useCountryCollection = () => {
  const { data } = useSWR<CountryCollection>(
    { path: 'country-codes', params: { size: 'w20', imageType: 'png' } },
    {
      use: [
        collectionAdaptor({
          collectionName: 'countries',
          keyMapping: { data: 'data.countryCodes' },
        }),
      ],
      dedupingInterval: oneHour,
    }
  )

  return data?.countries || []
}
