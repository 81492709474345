import React from 'react'
import { Link } from 'react-router-dom'

import { TableActionButtonProps } from './table.types'

export const TableActionButton = (props: TableActionButtonProps) => {
  const { label, to, onClick, visible = true, icon, ...linkProps } = props

  if (!visible) return null

  return (
    <Link
      to={to || '#'}
      aria-label={label}
      className='action-item'
      onClick={(e) => {
        if (!onClick) return

        e.preventDefault()
        onClick(e)
      }}
      {...linkProps}
    >
      <i className={`icon icon-18px ${icon}`} />
      {label}
    </Link>
  )
}
