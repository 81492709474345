import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

import { featureFlagPath } from 'config/paths'
import { LoadComponent } from 'routes/load-component'

const featureFlags = lazy(() => import('./feature-flags'))

export const featureFlagRoutes = (): RouteObject[] => [
  {
    index: true,
    path: featureFlagPath(),
    element: <LoadComponent component={featureFlags} />,
  },
]
