import { proxy } from 'valtio'

import { computeAuthStore } from './auth-store.computed'
import { AuthStore } from './auth-store.types'
import { proxyWithPersist } from './helpers/proxy-persist'

export const defaultAuthStore: AuthStore = {
  user: {
    id: null,
    email: null,
    phoneNumber: null,
    firstName: null,
    lastName: null,
    accountId: null,
    countryCode: '+62',
    accountType: 'Admin',
  },
  token: null,
  tokenExpiry: null,
  refreshToken: null,
  refreshTokenExpiry: null,
  isSuperAdmin: false,
  mapsAccessToken: null,
  mapsAuthToken: null,
}

const initialState = proxyWithPersist(defaultAuthStore, 'auth-store')

export const authStore = proxy({
  state: initialState,
  computed: computeAuthStore(initialState),
})
